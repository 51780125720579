import React, { useEffect, useState, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './EtatAchatFournisseurGlobal.json'
import Button from '../../components/button/Button'
import styles from './etatAchatFournisseurGlobal.module.scss'
import bonsAchatFinder from '../../api/bonAchatsFinder'
import bonsAvoirFinder from '../../api/bonAvoirFinder'
import fournisseurFinder from '../../api/fournisseurFinder'
import detteFournisseurFinder from '../../api/detteFournisseurFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'
import Printing from '../../components/printing/Printing'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'

const EtatAchatFournisseurGlobal = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data,setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  
  const tableRef = useRef();
  const tableData = useRef();
  const date1 = useRef();
  const date2 = useRef();
  const reportDate1Change = (val) => {
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!val || itemDate >= new Date(val)) &&
        (!date2.current.getValue() || itemDate <= new Date(date2.current.getValue()))
      );
    }));
  }
  const reportDate2Change = (val) =>{
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!date1.current.getValue() || itemDate >= new Date(date1.current.getValue())) &&
        (!val || itemDate <= new Date(val))
      );
    }))
  }

  const getSelectedFournisseur = useRef();
  const totalAchat = useRef();
  const totalRetour = useRef();
  const totalVersement = useRef();
  const sold = useRef();
  const globalAmount = useRef();

  const displayData = async (fournisseur)=> {
    try {
      const bonsAchatRes = await bonsAchatFinder.get(`/`);
      const bonsAvoirRes = await bonsAvoirFinder.get('/achat');
      const detteFournisseur = await detteFournisseurFinder.get(`/${fournisseur.id}`);

      const {total_verser} = detteFournisseur.data.data;
      const filtredBonsAchat = bonsAchatRes.data.data.bons.filter(bon => bon.id_fournisseur === fournisseur.id);
      const filtredBonsAvoir = bonsAvoirRes.data.data.bons.filter(bon => bon.id_fournisseur === fournisseur.id);
      
      const { max_id } = bonsAchatRes.data.data;
      
      
      let montantAchat = 0.0;
      let montantRetour = 0.0;
      
      const bonsAchat = filtredBonsAchat.map(bon => {

        montantAchat += parseInt(bon.amount);
        
        return{
          id: bon.id,
          achat_av: 'Achat',
          code: bon.code,
          date: dateFormatter(bon.date,3),
          montant:bon.amount.toFixed(2)
        }
      });
      
      const bonsAvoir = filtredBonsAvoir.map(bon =>{
        
        montantRetour += parseInt(bon.amount);
        let id = parseInt(bon.id) + parseInt(max_id);
        
        return{
          id,
          achat_av: 'Avoir',
          code: bon.code,
          date: dateFormatter(bon.date,3),
          montant:bon.amount.toFixed(2)
        }
      });

      totalAchat.current?.setValue(montantAchat.toFixed(2));
      totalRetour.current?.setValue(montantRetour.toFixed(2));
      globalAmount.current?.setValue((montantAchat - montantRetour).toFixed(2));
      totalVersement.current?.setValue(total_verser);
      sold.current?.setValue(((montantAchat - montantRetour) - parseInt(total_verser)).toFixed(2));

      setData([...bonsAvoir,...bonsAchat]);
      tableData.current = [...bonsAvoir,...bonsAchat];
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const getFournisseurs = async () => {
      try {
        const four = await fournisseurFinder.get("/");
        setSearchData(four.data.data.fournisseurs);
      } catch (err) {
        console.log(err);
      }

    }
    
    getFournisseurs();
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);
  const printRef = useRef()
  const achatAvoir = useRef()

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
  
    setIsActive(true);
    achatAvoir.current = "Etat des Achat Par Fournisseur (Global)"
    const articlesAdded = tableRef.current.getSelectedItems().map((etatBon)=>{
      return{
        code: etatBon.code,
        date: etatBon.date,
        achatAvoir: etatBon.achat_av,
        montant: etatBon.montant,
      }
    });
    const ROWS_PER_PAGE = 25; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages,
      globalInfo: {
        totalDesAchat: totalAchat.current.getValue(),
        totalAvoirAchat: totalRetour.current.getValue(),
        total: globalAmount.current.getValue(),
        periodeDu: date1.current.getValue(),
        au: date2.current.getValue(),
        fournisseur: getSelectedFournisseur.current.getSelectedItem().name
      }
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print();
    }, 100);
    
  }
  const PrintingColumns = [
    {name: "N°Bon",width: "5%"},
    {name: "Date",width: "10%"},
    {name: "Achat/Av",width: "10%"},
    {name: "Montant",width:"10%"}
  ]

  return (
    <PageLayout icon={icons.reglementFournisseur.secondary} nameLayout={'Etat des Achats Par Fournisseur ( Global )'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
              icon={icons.search}
              id={'fournisseurs'}
              holderText={"fournisseur"}
              label={"Ajouter fournisseur"}
              width={"50%"}
              labelPosition={true}
              searchData={searchData}
              onSelectItem={displayData}
              ref={getSelectedFournisseur}
              displaySearchOption={true}
              path={"/ajouter_fournisseur"}
              btnName={'Ajouter Nouveau fournisseur'}
            />  
            <div className={styles['row-end']}>
              <Button 
                icon={icons.impr}
                width={"150px"}
                name={'Imprimer'} 
                onclick={handlePrint}
                />
              <Button 
                icon={icons.exporter}
                width={"250px"}
                name={'Exporter Vers Excel'} 
                />
            </div>
          </div>
          <div className={styles['row']}>
            <InputText  
                label={"Periode du"}
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                ref={date1}
                reportChange={reportDate1Change}
              />
              <InputText 
                label={"Au"} 
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                ref={date2}
                reportChange={reportDate2Change}
              />
          </div>
          <div style={{height: "350px"}}>
            <Table 
              thead={metaData.table} 
              ref={tableRef}
              tbody={data} />
          </div>
          <div className={styles['row-between']} style={{width:'100%'}}>
            <InputText  
              holderText={"Montant"} 
              type={"number"}
              label={'Montant des Achats'}
              ref={totalAchat}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              type={"number"} 
              label={'Montant des Retours'}
              ref={totalRetour}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              type={"number"} 
              label={'Montant Global'}
              ref={globalAmount}
              disabled
              />
          </div>
          <div className={styles['row-end']} style={{width:'100%'}}>
            <InputText  
              holderText={"Total Verser"} 
              type={"number"} 
              label={'Montant Verser'}
              ref={totalVersement}
              disabled
              />
            <InputText  
              holderText={"Solde Total"} 
              type={"number"} 
              label={'Solde Total'} 
              ref={sold} 
              disabled
              />
          </div>
        </div>
      </div>
    {isActive && <Printing type={achatAvoir.current} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default EtatAchatFournisseurGlobal;
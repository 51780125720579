import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import style from './printingEtatAchatFournisseur.module.scss';
import { prints } from '../../media/mediaHolder';

const PrintingEtatAchatFournisseur = forwardRef( (props, ref) => {

  const {dataInfo, thead, type, pageNumber, pageNumbers,printingHeader} = props

  const timeoutRef = useRef();
  const articlesProps = useRef([]);

  useEffect(()=>{
    const handlePrinting = ()=>{
      const { articlesAdded } = dataInfo;
  
      articlesProps.current = [];
      for (const key in articlesAdded[0]) {
        articlesProps.current.push(key);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    handlePrinting();
  },[dataInfo.articlesAdded])

  const numberWithSpaces = (num) => {
    return (Math.floor(num * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
  };

  return (
    <>
      <div className={style['Line']}>
        <div className={style['second-line']}></div>
      </div>
      <div className={style['body-container']}>
        <div className={style['main-title-container']}>
          <p className={style['title-number']}>{type}</p>
        </div>
        <div className={style['date-container']}>
          <div className={style['head-lines-container']}>
            <p className={style['head-lines-type1']}>Periode du</p>
            <p className={style['head-lines-type2']}>:</p>
            <p className={style['head-lines-type1']}>{dataInfo.globalInfo.periodeDu}</p>
          </div>
          <div className={style['head-lines-container']}>
            <p className={style['head-lines-type1']}>Au</p>
            <p className={style['head-lines-type2']}>:</p>
            <p className={style['head-lines-type1']}>{dataInfo.globalInfo.au}</p>
          </div>
        </div>
        <div className={style['date-container']}>
          <div className={style['head-lines-container']}>
            <p className={style['head-lines-type1']}>Fournisseur</p>
            <p className={style['head-lines-type2']}>:</p>
            <p className={style['head-lines-type1']}>{dataInfo.globalInfo.fournisseur}</p>
          </div>
        </div>
        <div className={style['Line']}>
          <div className={style['second-line']}></div>
        </div>
        <div className={style['info-container-type2']}>
          <div className={style['bon-info']}>
            <div className={style['head-lines-container']}>
            </div>
            <div className={style['head-lines-container']}>
              <p className={style['head-lines-type1']}>Page</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type1']}>{pageNumber}/{pageNumbers}</p>
            </div>
        </div>
        </div>
        <div className={style['table-container']}>
          <table className={style['table']}>
            <colgroup>
              {thead.map((obj, index)=>{
                  return <col key={index} style={{width: obj.width}} className={style['col1']}/>
              })}
            </colgroup>
            <thead>
              <tr>
                {thead.map((head, index)=>{
                    return(
                        <th key={index} className={style['table-head']}>
                            {head.name} 
                        </th>
                    )
                })}
              </tr>
            </thead>
            <tbody>
              {dataInfo.articlesAdded.map((values, index) => {
                  return(
                    <tr key={index}>
                      {
                        articlesProps.current.map((key, index) => {
                          return(
                              <td className={style['td-cell']} key={index}>
                                {values[key]}
                              </td>
                            )
                        })
                      }
                    </tr>
                  )
                })}
                <tr key={9999} style={{height: "100%"}} ></tr>
            </tbody>
          </table>
        </div>
        <div className={style['amounts-container']}>
          <div className={style['amounts-numbers']}>
            <div className={style['head-lines-container-type2']}>
                <p className={style['head-lines-type1']}>Total des Achat</p>
                <p className={style['head-lines-type2']}>:</p>
                <p className={style['head-lines-type6']}>{numberWithSpaces(dataInfo.globalInfo?.totalDesAchat)}</p>
            </div>
            <div className={style['head-lines-container-type2']}>
              <p className={style['head-lines-type1']}>Total des Avoir Achat</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type6']}>{numberWithSpaces(dataInfo.globalInfo?.totalAvoirAchat)}</p>
            </div>
            <div className={style['head-lines-container-type2']}>
              <p className={style['head-lines-type1']}>Total</p>
              <p className={style['head-lines-type2']}>:</p>
              <p className={style['head-lines-type6']}>{numberWithSpaces(dataInfo.globalInfo?.total)}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default PrintingEtatAchatFournisseur

import React, { useRef, useState, useEffect } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './EtatVentesGlobal.json'
import styles from './etatVentesGlobal.module.scss'
import Button from '../../components/button/Button'
import bonVentesFinder from '../../api/bonVentesFinder'
import bonsAvoirFinder from '../../api/bonAvoirFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'
import Printing from '../../components/printing/Printing'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'

const EtatVentesGlobal = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const tableData = useRef();
  const tableRef = useRef();
  const date1 = useRef();
  const date2 = useRef();
  const reportDate1Change = (val) => {
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!val || itemDate >= new Date(val)) &&
        (!date2.current.getValue() || itemDate <= new Date(date2.current.getValue()))
      );
    }));
  }
  const reportDate2Change = (val) =>{
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!date1.current.getValue() || itemDate >= new Date(date1.current.getValue())) &&
        (!val || itemDate <= new Date(val))
      );
    }))
  }

  const [ inputValue , setInputValue ] = useState('');
  const [data,setData] = useState([]);

  const totalVente = useRef();
  const totalRetour = useRef();
  const totalComptoir = useRef();
  const globalAmount = useRef();
    
  useState(() => {
    const fetchArticlesVenteAvoir = async () => {
      try {
        const bonsVenteRes = await bonVentesFinder.get(`/`);
        const bonsAvoirRes = await bonsAvoirFinder.get('/vente');

        // get the max id from achat receipts
        const { max_id } = bonsVenteRes.data.data;
        let montantVentes = .0;
        let montantRetour = .0;

        const bonsVente = bonsVenteRes.data.data.bons.map(bon => {
          montantVentes += bon.amount;
          return{
            id: bon.id,
            code: bon.code,
            date: dateFormatter(bon.date,3),
            achat_av: 'Vente',
            montant:bon.amount,
            code_client: bon.client_code,
            client : bon.client_name
          }
        });

        const bonsAvoir = bonsAvoirRes.data.data.bons.map(bon => {
          montantRetour += bon.amount;

          let id = parseInt(bon.id) + parseInt(max_id);
          return{
            id,
            code: bon.code,
            date: dateFormatter(bon.date,3),
            achat_av: 'Avoir',
            montant:bon.amount,
            code_client: bon.client_code,
            client : bon.client_name
          }
        });
        setData([...bonsAvoir,...bonsVente]);
        tableData.current = [...bonsAvoir,...bonsVente];

        totalVente.current?.setValue(montantVentes);
        totalRetour.current?.setValue(montantRetour);  
        totalComptoir.current?.setValue('0');
        globalAmount.current?.setValue(montantVentes - montantRetour);
      } catch (err) {
        console.log(err);
      }
    }

    fetchArticlesVenteAvoir();
  },[]);  

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);
  const printRef = useRef()
  const achatAvoir = useRef()

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
  
    setIsActive(true);
    achatAvoir.current = "Etat des Vente (Global)"
    const articlesAdded = tableRef.current.getSelectedItems().map((etatBon)=>{
      return{
        codeClient: etatBon.code_client,
        client: etatBon.client,
        code: etatBon.code,
        venteAvoir: etatBon.achat_av,
        date: etatBon.date,
        montant: etatBon.montant,
      }
    });
    const ROWS_PER_PAGE = 25; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages,
      globalInfo: {
        totalDesVente: totalVente.current.getValue(),
        totalAvoirVente: totalRetour.current.getValue(),
        total: globalAmount.current.getValue(),
        periodeDu: date1.current.getValue(),
        au: date2.current.getValue()
      }
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print();
    }, 100);
    
  }
  const PrintingColumns = [
    {name: "Code Cli.",width: "5%"},
    {name: "Client",width: "25%"},
    {name: "N°Bon",width: "5%"},
    {name: "Vente/Av",width: "10%"},
    {name: "Date",width: "10%"},
    {name: "Montant",width:"10%"}
  ]

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes Global'}>
        <div className={styles['body-container']}>
          <div className={styles['main-body']}>
            <div className={styles['row-between']}>
              <div className={styles['row']}>
                <InputText label={"Periode du"} 
                  labelPosition={true}
                  width={"max-content"} 
                  type={"date"}
                  ref={date1}
                  reportChange={reportDate1Change}
                  />
                  <InputText label={"Au"} 
                  labelPosition={true}
                  width={"max-content"} 
                  type={"date"}
                  ref={date2}
                  reportChange={reportDate2Change}
                  />
              </div>
              <div className={styles['row-end']}>
                <Button 
                icon={icons.impr}
                width={"250px"}
                name={'Imprimer'} 
                onclick={handlePrint}
                  />
              </div>
            </div>
            <div style={{height: "300px"}}>
              <Table 
                thead={metaData.table} 
                tbody={data} 
                ref={tableRef} />
            </div>
            <div className={styles['row-between']} style={{width:'100%'}}>
              <InputText 
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Montant des Ventes'}
                ref={totalVente}
                disabled
                />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Montant des Retours'}
                ref={totalRetour}
                disabled
                />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Montant des Comptoirs'}
                ref={totalComptoir}
                disabled
                />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Montant Global'}
                ref={globalAmount}
                disabled
                />
            </div>
          </div>
        </div>
        {isActive && <Printing type={achatAvoir.current} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
        <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default EtatVentesGlobal;

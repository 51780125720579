import React, { useEffect, useRef, useState } from 'react'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import { icons } from '../../media/mediaHolder'
import styles from './factureProforma.module.scss'
import PageLayout from '../../components/page-layout/PageLayout'
import SubInfo from '../../components/sub-info/SubInfo'
import InputText from '../../components/input-text/InputText' 
import SelectInput from '../../components/select-input/SelectInput'
import PageSideBar from '../../components/page-side-bar/PageSideBar'
import clientsFinder from '../../api/clientsFinder'
import articlesFinder from '../../api/articlesFinder'
import articleFactureFinder from '../../api/articleFactureFinder'
import { useNavigate } from 'react-router-dom'
import Dialog from '../../components/dialog/Dialog'
import AdderTable from '../../components/adder-table/adderTable'
import factureProformaFinder from '../../api/factureProformaFinder'
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import metaData from './metaData.json';
import { useAuth } from '../../contexts/AuthContext'
import Printing from '../../components/printing/Printing'
import permissions from '../../utils/permissions'
import { fixDecimalDigits } from '../../utils/numberFormatter'
import ValidatedMessages from '../../components/validated-messages/ValidatedMessages'

const FactureProforma = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const navigate = useNavigate();

  // Get related clients
  const [ clientsList, setClientsList ] = useState([]);
  const clientsInputRef = useRef([]);

  useEffect(() => {
    const getClients = async ()=>{
      try {
        const response = await clientsFinder.get("/");
        setClientsList(response.data.data.clients);
      }
      catch (err) {
        console.log(err.message);
      }
    }
    
    getClients();
  }, []);

  // Set code after initial render
  const codeInputRef = useRef(null);

  useEffect(() => {
    const setBonCode = async () => {
      try {
        const response = await factureProformaFinder.get('/');
        const code = response.data.data.code.max ? response.data.data.code.max + 1 : 1;
        codeInputRef.current.setValue(code);
      }
      catch (err) {
        console.log(err.message);
      }
    }
    
    setBonCode();
  }, []);

  // Set date input ref and intialize its value after first render
  const dateInputRef = useRef(null);
  useEffect(() => dateInputRef.current.setValue(new Date().toISOString().split('T')[0]), []);

  // The rest of side page elements
  const paymentInputRef = useRef(null);
  const totalSansRM = useRef();
  const tvaRef = useRef();
  const remiseR = useRef();
  const discountTotalRef = useRef();
  const total = useRef();

  // Other elements
  const articlesRef = useRef();
  const adderTableRef = useRef([]);
  const tableRef = useRef([]);

  // Set articles and tables
  useEffect(() => {
    const fetchArticles = async () => {
      const response = await articlesFinder.get("/");

      const filtredArticles = response.data.data.articles.filter((article) => article.type === 'matierefini')
        .map(({ type, ...rest }) => reorderArticleProps(rest));

      articlesRef.current = filtredArticles;
      adderTableRef.current.setAllData(filtredArticles);
    } 
    
    fetchArticles();
  }, [])

  const reorderArticleProps = (article) => {
    return {
      id: article.id,
      codeArticle: article.codeArticle,
      designation: article.designation,
      quantity: article.quantity || 0,
      ug: article.ug,
      puAchat: article.puAchat,
      prixVenteGros: article.prixVenteGros,
      puDetail: article.puDetail,
      prixVenteDemiGros: article.prixVenteDemiGros,
      puSpecial: article.puSpecial,
      unitMes: article.unitMes || '-',
      qtyCart: article.qtyCart,
      nbCart: article.nbCart,
      puFact: article.puFact || 0,
      tva: article.tva,
      stkInit: article.stkInit,
      prixAchatInit: article.prixAchatInit,
      prixVenteInit: article.prixVenteInit,
      stockAlert: article.stockAlert,
      weight: article.weight,
      volume: article.volume,
      blocked: article.blocked ? 'Oui' : 'No',
      observation: article.observation || '-'
    }
  }

  const adderTableDialogRef = useRef([]);

  const showAdderTable = () => adderTableDialogRef.current.display();

  const adjestArticlePropsToTable = (article) => {
    return {
      id: article.id,
      codeArticle: article.codeArticle,
      designation: article.designation,
      quantityart: 0,
      puVente: article.puFact,
      remise: 0,
      tva: article.tva,
      ugOp: '-',
      total: 0,
      observation: article.observation || ''
    }
  }

  const addSelectedArticles = async () => {
    const newData = adderTableRef.current.sendSelectedItems().map((article) => adjestArticlePropsToTable(article));
    tableRef.current.setAllData([...tableRef.current.getAllData(), ...newData]);
  }

  const handleDelete = (itemToDeleteId) => {
    tableRef.current.setAllData(tableRef.current.getAllData().filter(item => item.id !== itemToDeleteId));
    const article = articlesRef.current.find(item => item.id === itemToDeleteId);
    adderTableRef.current.addItem(reorderArticleProps(article));
  }

  const actOnValidation = async (article, prevArticleVals) => {
    const puRemise = fixDecimalDigits(article.puVente - (article.puVente * (article.remise / 100)), 2, true);
    
    
    // If the quantity to be selled changed after validation use this formula
    if (article.quantityart !== prevArticleVals.quantityart || article.quantityart === '-') {
      const { ug } = articlesRef.current.find(item => item.id === article.id);
      article.ugOp = Math.floor(article.quantityart * (ug / 100));
    }
    
    const puTVA = fixDecimalDigits((puRemise * (article.tva / 100)), 2, true);
    const totalArticleTVA = fixDecimalDigits((puTVA * article.quantityart), 2, true);

    article.total = fixDecimalDigits((article.quantityart * article.puVente) * (1 - (article.remise / 100)), 2, true) + totalArticleTVA;

    tableRef.current.updateRow({...article, editable: false});

    calculateTotal();
  }

  const calculateTotal = () => {
    const articles = tableRef.current.getAllData();
    // Calculate the total before discounts
    const amountBeforeDiscounts = articles.reduce((accumulator, article) => accumulator += article.puVente * article.quantityart, 0);
    totalSansRM.current.setValue(amountBeforeDiscounts);

    // Calculate all the discounts
    const articlesDiscountsTotal = articles.reduce((accumulator, article) => {
      const puRemise = fixDecimalDigits(article.puVente - (article.puVente * (article.remise / 100)), 2, true);
      return accumulator += fixDecimalDigits(((article.puVente - puRemise) * article.quantityart), 2, true);
    }, 0);
    
    discountTotalRef.current.setValue(articlesDiscountsTotal);

    // Calculate AVT
    const totalAVT = articles.reduce((accumulator, article) => {
      const puRemise = fixDecimalDigits(article.puVente - (article.puVente * (article.remise / 100)), 2, true);
      const puTVA = fixDecimalDigits((puRemise * (article.tva / 100)), 2, true);
      return accumulator += fixDecimalDigits((article.quantityart * puTVA), 2, true);
    }, 0);

    tvaRef.current.setValue(totalAVT);

    // Display the final amount
    total.current.setValue(fixDecimalDigits(amountBeforeDiscounts + totalAVT - articlesDiscountsTotal), 2, true);
    updateRMInput(articles)
  }

  const updateTaxes = (rm)=>{
    const remise = parseInt(rm) || 0;
    
    const articles = tableRef.current.getAllData().map((article)=>{
      article.remise = remise;
      article.total = (article.puVente * article.quantityart - ((article.puVente * article.quantityart)*article.remise)/100)
    })
    
    tableRef.current.updateRow({...articles});
    
    calculateTotal()
  }

  const updateRMInput = (articles)=>{
    const rm = articles[0]?.remise;
    for(let i=1;i<articles.length;i++){
      if(rm !== articles[i]?.remise) {
        remiseR.current.setValue("0")
      }
    }
  }

  // Check for errors
  const errorsHandlerRef = useRef([]);
  
  const codeExists = async (value) => {
    try {
      const response = await factureProformaFinder.get(`/${value}`)
      return response.data.data[0]?.code === value;
    }
    catch (err) {
      console.error(`Could not fetch facture Proforma's code`)
    }
  }

  const checkErrs = async () => {
    const alerts = [];
    const code = document.getElementById("code").value;
    const client = document.getElementById("Client").value;
    const id_client = client && clientsInputRef.current.getSelectedItem().id;
    
    if(!code) {
      alerts.push({name: 'code', message: 'Assurez que la code is bien saisez'});
      codeInputRef.current.setBorderRed();
    }
    if(!id_client) {
      alerts.push({name: 'client', message: 'Assurez que la client est bien selectionez'});
      clientsInputRef.current.setBorderRed();
    }
    if(await codeExists(code)) {
      alerts.push({name: 'code', message: 'le N° du bon est deja exister'});
      codeInputRef.current.setBorderRed();
    }

    if(tableRef.current.getAllData().length === 0) {
      alerts.push({name: 'articles', message: 'Assurez que les Articles est bien selectionez'});
    }

    if(alerts.length) {
      errorsHandlerRef.current.errorsHandler(alerts);
      return
    }
    
    handelValidate();
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();

    const date = new Date(document.getElementById("date").value)
    
    try {
      
      const articles = tableRef.current.getAllData().map( ( article )=>{
        return {
          idArticle: article.id,
          quantity: parseInt(article.quantityart), 
          prixVente: parseFloat(article.puVente),
          remise: parseInt(article.remise),
          tva: article.tva,
          ug: article.ugOp,
          observation: article.observation
        }
      });

      await factureProformaFinder.post("/",
      {
        clientId: clientsInputRef.current.getSelectedItem().id,
        code: document.getElementById("code").value,
        date,
        typePayment: paymentInputRef.current.getSelectedOption(),
        articles:articles
      });

      validateHandler.current.validateHandler([{name: 'validate' , message: 'Bon est bien Valider'}]);
      setTimeout(()=> {
        navigate(-1)
      }, 1000);
    } catch (err) {
        console.log(err)
    }
  }

  const validateHandler = useRef([]);

  const displayConfirmationDialog = useRef();
  const handelValidate = () => displayConfirmationDialog.current.display();

  // Printing section
  const PrintingColumns = [
    {name: "Code", width: "10%"},
    {name: "Designation", width: "40%"},
    {name: "Qte", width: "5%"},
    {name: "UG", width: "5%"},
    {name: "PU", width: "10%"},
    {name: "RM%", width: "5%"},
    {name: "TVA%", width: "5%"},
    {name: "Total", width:"10%"}
  ]

  const printRef = useRef();

  const [isActive, setIsActive] = useState(false);
  const [info, setInfo] = useState();
  const timeoutRef = useRef();

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getAllData();
    if(!rowInfo[0]?.id) {
      errorsHandlerRef.current.errorsHandler([{name: 'articles' , message: 'Assurez que la Articles est bien selectionez'}]);
      return
    }

    setIsActive(true);
    
    const articlesAdded = rowInfo.map((article) => {
      return({
            code: article.codeArticle,
            designation: article.designation,
            quantity: article.quantityart,
            ug: article.ugOp,
            PUVente: fixDecimalDigits(article.puVente),
            remise: article.remise,
            tva: article.tva,
            total: fixDecimalDigits(article.total)
      })
    });

    const ROWS_PER_PAGE = 20; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );

    // console.log(document.getElementById("code").value)

    const data = {
      rowInfo: {
        code: document.getElementById("code").value,
        date: document.getElementById("date").value,
        client_name: clientsInputRef.current.getSelectedItem().name,
        client_address: clientsInputRef.current.getSelectedItem().address,
        client_activite: clientsInputRef.current.getSelectedItem().activity,
        payment_type: paymentInputRef.current.getSelectedOption(),
        client_n_register: clientsInputRef.current.getSelectedItem().n_register,
        client_n_article: clientsInputRef.current.getSelectedItem().n_article,
        client_nif: clientsInputRef.current.getSelectedItem().nif,
        client_n_fiscal: clientsInputRef.current.getSelectedItem().n_fiscal,
        client_nis: clientsInputRef.current.getSelectedItem().nis,
      },
      articlesAdded: pages,
      saisiPar: user.name,
      globalInfo: {
        totalBeforeDiscounts: totalSansRM.current.getValue(),
        totalDiscounts: discountTotalRef.current.getValue(),
        totalAVT: tvaRef.current.getValue(),
        totalAfterDiscounts: total.current.getValue()
      }
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }

  return (
    <PageLayout icon={icons.facture} nameLayout={'Facture Proforma'}>
      <form onSubmit={handleSubmit}>
        <div className={styles['body-container']}>
          <div className={styles['main-body']}>
            <div className={styles['actions-container']}>
              <div className={styles['row']}>
                <InputText  
                  holderText={"Chercher un Client"} 
                  width={"50%"} 
                  labelPosition={true}
                  type={"text"} 
                  label={'Client'}
                  id={'Client'}
                  path={"/ajouter_client"}
                  btnName={'Ajouter Nouveau Client'} 
                  ref={clientsInputRef}
                  searchData={clientsList}
                />
                <Button  name='Imprimer' width={"150px"} icon={icons.impr} onclick={handlePrint} />
              </div>
              <div className={styles['row']}>
                <Button name='Ajouter Article' 
                        onclick={showAdderTable} 
                        width={"250px"}
                        />
                        <div></div>
              </div>
              <div style={{height: "400px"}}>
                <Table thead={metaData.table} 
                        tbody={articlesRef.current ? tableRef.current?.getAllData() : []} 
                        confirmBeforeDeletion={false}
                        ref={tableRef} 
                        edit={user?.permissions.includes(permissions.canEditInTable)} 
                        remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                        handleDelete={handleDelete}
                        actOnValidation={actOnValidation}
                        />
              </div>
            </div>
          
          </div>
          <div className={styles['side-body']}>
            <PageSideBar validateFunc={checkErrs}>
              <div className={styles['col-around']}>
                <div className={styles['col-evenly']}>
                  <InputText  label={"N° Facture impr"} 
                              holderText={"0001"} 
                              id={'code'}
                              width={"100%"} 
                              type={"number"}
                              ref={codeInputRef}
                              disabled
                          />
                  <InputText  label={"Date de bon"} 
                              id={'date'} 
                              width={"100%"} 
                              type={"date"}
                              ref={dateInputRef}/>
                  <SelectInput  options={["Espece", "Cheque", "Verement", "Autre"]} 
                                label={"Type de paiement"} 
                                width={"100%"} 
                                ref={paymentInputRef}
                                />
                </div>
                <div className={styles['col-evenly']}>
                
                  <SubInfo  
                    label={"Total sans remise"}
                    unit={"DZD"}
                    ref={totalSansRM}
                  />

                  <div className={styles['row-between']}>
                    <InputText  
                      id={"remise"}
                      label={"Remise"} 
                      labelPosition={true}
                      currency={true} 
                      width={"100%"} 
                      type={"number"} 
                      defaultValue={'0'}
                      holderText={"0.00"}
                      currencyValue={"%"}
                      ref={remiseR}
                      reportChange={updateTaxes}
                    />
                  </div>

                  <SubInfo
                    label={"Total de remise"} 
                    unit={"DZD"}
                    ref={discountTotalRef}
                  />

                  <SubInfo
                      label={"Total de TVA"} 
                      unit={"DZD"}
                      ref={tvaRef}
                  />

                  <SubInfo
                    label={"Total"} 
                    unit={"DZD"}
                    ref={total}
                  />

              </div>
            </div>
          </PageSideBar>
          </div>
          <Dialog width={"80%"} table={true} ref={adderTableDialogRef} validateFunc={addSelectedArticles}>
            <AdderTable head={metaData.adderTable} ref={adderTableRef} />
          </Dialog>
      </div>
    <ConfirmationButton ref={displayConfirmationDialog}/>
    </form>
    <ErrorContainer ref={errorsHandlerRef}/>
    {isActive && <Printing type={'Facture Proforma'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ValidatedMessages ref={validateHandler}/>
    </PageLayout>
  )
}

export default FactureProforma

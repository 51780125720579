import React, { useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import Button from '../../components/button/Button'
import styles from './etatVentesDetailleParClient.module.scss'
import metaData from './EtatVenteDetailleParClient.json'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import articleLivreFinder from '../../api/articleLivreFinder'
import clientsFinder from '../../api/clientsFinder'
import creditClientFinder from '../../api/creditClientFinder'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'

const EtatVentesDetailleParClient = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const [ inputValue , setInputValue ] = useState('');
  const [searchData, setSearchData] = useState([]);
    
  const [checked , setChecked ] = useState(false);
  const handelCheckbox = ()=>{
      setChecked(!checked);
  }

  const [data,setData] = useState([]);

  const totalVente = useRef();
  const totalRetour = useRef();
  const globalAmount = useRef();
  const getSelectedClient = useRef();
  const credit = useRef();
  const totalReglement = useRef();

  useEffect(() => {
    const getClient = async () =>{
      try {
        const client = await clientsFinder.get("/");
        setSearchData(client.data.data.clients);
      } catch (err) {
        console.log(err);
      }
    }
  
  getClient();
  }, []); 

  const displayData = async (client) =>{
    try {
      let montantVentes = .0;
      let montantRetour = .0;
      let montantAchat = .0;
      const articlesLivreRes = await articleLivreFinder.get('/');
      const articlesAvoir = await articlesAvoirFinder.get('/vente');
      const creditClient = await creditClientFinder.get(`/${client.id}`)
      const bonsAchat = await bonAchatsFinder.get('/');
      const { total_verser } = creditClient.data.data;
      
      // FILTER THE TABLE BY THE CLIENT ID
      const filtredVentes = articlesLivreRes.data.data.articles.filter( article => article.id_client === client.id);
      const filtredAvoirs = articlesAvoir.data.data.articles.filter( article => article.id_client === client.id);

      // get the total amount of purchases
      bonsAchat.data.data.bons.map(bon =>{
        montantAchat += bon.amount * ( 1 - (bon.remise/100));
      });
      
      // GET THE MAX ARTICLE VENDUE ID 
      const { max_id } = articlesLivreRes.data.data;
      

      const articlesVendue = filtredVentes.map((article)=>{

        let montant = (article.prix * article.quantity) * (1 - (article.remise / 100)) ;

        montantVentes += montant;

        return{
            id:article.id,
            Vente_av: 'Vente',
            code: article.bl_code,
            lot: article.lotNbr,
            designation:article.designation,
            date: dateFormatter(article.bon_vente_date,3),
            qty:article.quantity,
            pu_vente:article.prix,
            montant,
            pu_achat:article.pu_achat,
            benifice:montant - article.pu_achat,
            codeArt:article.code
            }
        }
      );

      const articlesAvoirs = filtredAvoirs.map((article)=>{
        // Calculate the article price with the remise
        let quantity = article.quantity;
        let pu_vente = article.prix_achat;
        let montant = (quantity * pu_vente) * (1 - (article.remise/100));
        // 
        montantRetour += montant;
        //
        let id= parseInt(article.id) + parseInt(max_id);

        return{
          id,
          Vente_av: 'Avoir',
          code: article.bon_avoir_code,
          lot: article.lotNbr,
          designation:article.designation,
          date: dateFormatter(article.bon_avoir_date,3),
          quantity,
          pu_vente,
          montant,
          pu_achat:article.pu_achat,
          benifice:montant-article.pu_achat,
          codeArt:article.code
        }
      });

      let amountTotal = (montantVentes - montantRetour);

      totalVente.current?.setValue(montantVentes.toFixed(3));

      totalRetour.current?.setValue(montantAchat.toFixed(3));  

      globalAmount.current?.setValue((amountTotal - montantAchat).toFixed(3));

      totalReglement.current?.setValue(total_verser?.toFixed(3));

      credit.current?.setValue((amountTotal - total_verser).toFixed(3));

      setData([...articlesAvoirs,...articlesVendue]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes Détaillé Par Client'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
              <InputText 
                icon={icons.search}
                id={'client'}
                label={"Code Client"}
                holderText={"Client"}
                type={"text"}
                labelPosition={true}
                width={"50%"}
                searchData={searchData}
                onSelectItem={displayData}
                ref={getSelectedClient}
                displaySearchOption={true}
                path={"/ajouter_client"}
                btnName={'Ajouter Nouveau client'}
                />
            <div className={styles['row-end']}>
              <Button 
                icon={icons.exporter}
                width={"250px"}
                name={'Exporter Vers Excel'} 
                />
              <Button 
                icon={icons.impr}
                width={"150px"}
                name={'Imprimer'} 
                />
            </div>
          </div>
          <div className={styles['row-between']}>
            <div className={styles['row']}>
              <InputText
                label={"Jour 1"}
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                reportChange={reportDate1Change}
                />
              <InputText label={"Jour 2"} 
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                reportChange={reportDate2Change}
                />
              <div className={styles['check-input-container']}>
                  <p className={styles['checkbox-name']}>Avec Bénéfice</p>
                  <input type='checkbox' className={styles['check-input']} onClick={handelCheckbox} 
                  style={checked ? {backgroundImage: `url(${icons.checkboxActive})`}:
                  {backgroundImage: `url(${icons.checkboxInactive})`}} 
                  />
              </div>
            </div>
            <InputText 
              icon={icons.search}
              type={"text"}
              holderText={"Chercher"}
              label={"Chercher"}
              labelPosition={true}
              width={"50%"}
              />
          </div>
          <div style={{height: "300px"}}>
            <Table 
              thead={metaData.table} 
              inputValue={inputValue} 
              tbody={data} 
              date1={date1} 
              date2={date2}
            />
          </div>
          <div className={styles['row-between']} style={{width:'100%'}}>
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Total des Ventes'}
              ref={totalVente}
              />
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Total des Achats'}
              ref={totalRetour}
              />
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Total du Bénéfice'}
              ref={globalAmount}
              />
          </div>
          <div className={styles['row-end']} style={{width:'100%'}}>
            <InputText 
              holderText={"Total Reglement"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Total de Reglement'}
              ref={totalReglement}
              disabled
              />
            <InputText 
              holderText={"Credit"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Total de Achat'}
              ref={credit}
              disabled
              />
          </div>
        </div>
      
      </div>
    </PageLayout>
  );
}

export default EtatVentesDetailleParClient;
import React, { useEffect, useRef, useState } from 'react'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import { icons } from '../../media/mediaHolder'
import styles from './facture.module.scss'
import PageLayout from '../../components/page-layout/PageLayout'
import SubInfo from '../../components/sub-info/SubInfo'
import InputText from '../../components/input-text/InputText' 
import SelectInput from '../../components/select-input/SelectInput'
import PageSideBar from '../../components/page-side-bar/PageSideBar'
import { useNavigate } from 'react-router-dom'
import clientsFinder from '../../api/clientsFinder'
import articlesFinder from '../../api/articlesFinder'
import Dialog from '../../components/dialog/Dialog'
import AdderTable from '../../components/adder-table/adderTable'
import factureFinder from '../../api/factureFinder'
import articleFactureFinder from '../../api/articleFactureFinder'
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import metaData from './metaData.json'
import depotFinder from '../../api/depotFinder'
import depotsStockFinder from '../../api/depotsStockFinder'
import Printing from '../../components/printing/Printing'
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions';
import { fixDecimalDigits, spacedNumFormat } from '../../utils/numberFormatter'
import ValidatedMessages from '../../components/validated-messages/ValidatedMessages'

const Facture = () => {
  const navigate = useNavigate();
  const { state: authState } = useAuth();
  const { user } = authState;


  const currency = "DZD"
  const options =[
    "Espece",
    "Cheque",
    "Verement",
    "A Terme",
    "Autre"
  ]

  const [searchData, setSearchData] = useState([]);
  const [selectedArticlesData, setSelectedArticlesData] = useState([]);
  const [depots, setDepots] = useState([]);
  const [articlesInfo, setArticlesInfo] = useState([]);

  const displayDialog = useRef([]);
  const selectedClient = useRef([]);
  const articleTable = useRef([]);
  const codeInput = useRef(null);
  const dateInput = useRef(null);
  const depot = useRef(null);
  const tableRef = useRef(null);
  const typePayment = useRef();

  const articlesRef = useRef();
  const [date, setDate] = useState(new Date());
  const [displayTimbre, setDisplayTimbre] = useState(true);

  const addSelectedArticles = async () => {
    const articles = [];
    const data = [...tableRef.current.getAllData(),...articleTable.current.sendSelectedItems()];
    // console.log(articleTable.current.sendSelectedItems())
    for(let i=0;i<data.length;i++){
      let article = data[i];
      
      articles.push({
        id: article.id,
        codeArticle: article.codeArticle,
        designation: article.designation,
        lot_nbr: article.lot_nbr || "",
        quantity: article.quantity,
        quantityart: article.quantityart || 0,
        puVente: article.prixVenteGros || article.puVente || 0,
        ug: '-',
        remise: article.remise || 0,
        tva: article.tva,
        total:(article.puVente * article.quantityart) - ((article.puVente * article.quantityart) * article.remise)/100 || 0,
      })
    }
    setSelectedArticlesData(articles)
  }

  const actOnValidation = async (obj, prevArticleVals) => {
    if (obj.quantityart !== prevArticleVals.quantityart || obj.quantityart === '-') {
      const articleUg = articlesRef.current.find(item => item.id === obj.id).ug;
      obj.ug = Math.floor(obj.quantityart * (articleUg / 100));
    }

    tableRef.current.updateRow({...obj, editable: false});
    calculateTotal()
  }
  const checkDepot = () =>{
    const depots = document.getElementById('depot').value
    if(!depots){
      errorsHandler.current.errorsHandler([{name:'depot' ,message:'Assurez que le Depot est bien selectionez'}]);
      depot.current.setBorderRed();
      return
    }
    displayDialog.current.display()
  }

  const handleDepot =(selectedDepot)=>{
    articleTable.current.setAllData(articlesInfo.filter((article)=>{
      return (selectedDepot.name === article.depotName)
    }))
    if(selectedDepot.id !== depot.current.getSelectedItem().id)
      tableRef.current.setAllData([]);
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    const date = new Date(document.getElementById("date").value)
    const depotId = parseInt(depot.current.getSelectedItem().id);
    try {
      const articles = tableRef.current.getAllData().map( ( data ) => {
      let ug = Math.floor(parseInt(data.quantityart) * parseInt(data.ug/100));
      
      return {
        id_depot_stock: data.id,
        ug,
        quantity: parseInt(data.quantityart), 
        prixVente: parseInt(data.puVente),
        remise: parseInt(data.remise),
        tva: data.tva
        }
      });
      
      await factureFinder.post("/",
        {
          clientId: selectedClient.current.getSelectedItem().id,
          id_depot: depotId,
          code: document.getElementById("code").value,
          date,
          typePayment: typePayment.current.getSelectedOption(),
          remise: parseFloat(document.getElementById("remise").value) || 0,
          timbre: displayTimbre ? parseFloat(document.getElementById("timbre").value) : 0,
          nbr_bon_commande: parseInt(document.getElementById('nbrBonCommande').value),
          articles:articles
        });
        
        validateHandler.current.validateHandler([{name: 'validate' , message: 'Bon est bien Valider'}]);
        setTimeout(()=> {
          navigate(-1)
        }, 1000);
    } catch (error) {
        console.log(error)
    }
  } 
  const validateHandler = useRef([]);

  const totalSansRM = useRef();
  const tvaRef = useRef();
  const discountTotalRef = useRef();
  const total = useRef();

  const calculateTotal = (data) => {
    const articles = tableRef.current.getAllData();
    // Calculate the total before discounts
    const amountBeforeDiscounts = articles.reduce((accumulator, article) => accumulator += article.puVente * article.quantityart, 0);
    totalSansRM.current.setValue((amountBeforeDiscounts));

    // Calculate all the discounts
    const articlesDiscountsTotal = articles.reduce((accumulator, article) => {
      const puRemise = (article.puVente - (article.puVente * (article.remise / 100)));
      return accumulator += (((article.puVente - puRemise) * article.quantityart));
    }, 0);

    discountTotalRef.current.setValue((articlesDiscountsTotal));

    // Calculate AVT
    const totalAVT = articles.reduce((accumulator, article) => {
      const puRemise = (article.puVente - (article.puVente * (article.remise / 100)));
      const puTVA = ((puRemise * (article.tva / 100)));
      return accumulator += ((article.quantityart * puTVA));
    }, 0);

    tvaRef.current.setValue((totalAVT));

    const timbre = parseInt(document.getElementById('timbre').value) || 0

    // Display the final amount
    total.current.setValue((amountBeforeDiscounts + timbre + totalAVT - articlesDiscountsTotal));

    updateRMInput(articles)
  }

  const updateTaxes = (rm)=>{
    const remise = parseInt(rm) || 0;
    
    const articles = tableRef.current.getAllData().map((article)=>{
      article.remise = remise;
      article.total = (article.puVente * article.quantityart - ((article.puVente * article.quantityart)*article.remise)/100)
    })
    
    tableRef.current.updateRow({...articles});
    
    calculateTotal()
  }

  const updateRMInput = (articles)=>{
    const rm = articles[0]?.remise;
    for(let i=1;i<articles.length;i++){
      if(rm !== articles[i]?.remise) {
        remiseR.current.setValue("0")
      }
    }
  }

  useEffect(()=>{
    dateInput.current.setValue(date.toISOString().substring(0, 10));
    const getClients = async ()=>{
      const response = await clientsFinder.get("/");
      setSearchData(response.data.data.clients)
    }

    const fetchDepots = async ()=>{
      try {
        const response = await depotFinder.get('/');
        setDepots(response.data.data.depots); 
      }
      catch (err) {
        console.log(err);
      }
    }

    const fetchfactures = async () => {
      const response = await factureFinder.get('/');
       let code=1;
      if(response.data.data.code.max){
        code = parseInt(response.data.data.code.max) + 1
      }
      codeInput.current.setValue(code);
    }
    const getArticles = async ()=>{
      const response = await depotsStockFinder.get("/")
      const filtredArticles = response.data.data.articles.filter((article)=>{
        return article.type === 'matierefini'
      }).map(({ type, ...rest }) => rest);
      articlesRef.current = filtredArticles;
      setArticlesInfo(filtredArticles.map((article)=>{
       return {
         id: article.id,
         codeArticle: article.code,
         codeFamille: article.famille_code || "",
         designation: article.designation,
         depotName: article.depot_name,
         lot_nbr: article.ds_lot_nbr,
         quantity: article.ds_quantity || 0,
         ug: article.ug,
         puAchat: article.pu_achat,
         prixVenteGros: article.prix_vente_gros,
         puDetail: article.pu_detailer,
         prixVenteDemiGros: article.prix_vente_semi_gros,
         puSpecial: article.pu_special,
         unitMes: article.unit_mes,
         qtyCart: article.qte_cart,
         nbCart: article.nb_cart,
         puFact: article.pu_fournisseur,
         tva: article.tva,
         stkInit: article.stq_init,
         prixAchatInit: article.prix_achat_init,
         prixVenteInit: article.prix_vente_init,
         stockAlert: article.stock_alert,
         weight: article.weight,
         volume: article.volume,
         blocked: article.blocked ? 'Oui' : 'No',
         observation: article.observation
     }
      }));
      articleTable.current.setAllData(filtredArticles.map((article)=>{
       return {
         id: article.id,
         codeArticle: article.code,
         codeFamille: article.famille_code || "",
         designation: article.designation,
         depotName: article.depot_name,
         lot_nbr: article.ds_lot_nbr,
         quantity: article.ds_quantity || 0,
         ug: article.ug,
         puAchat: article.pu_achat,
         prixVenteGros: article.prix_vente_gros,
         puDetail: article.pu_detailer,
         prixVenteDemiGros: article.prix_vente_semi_gros,
         puSpecial: article.pu_special,
         unitMes: article.unit_mes,
         qtyCart: article.qte_cart,
         nbCart: article.nb_cart,
         puFact: article.pu_fournisseur,
         tva: article.tva,
         stkInit: article.stq_init,
         prixAchatInit: article.prix_achat_init,
         prixVenteInit: article.prix_vente_init,
         stockAlert: article.stock_alert,
         weight: article.weight,
         volume: article.volume,
         blocked: article.blocked ? 'Oui' : 'No',
         observation: article.observation
     }
      }))
   } 

    fetchDepots();
    getClients();
    fetchfactures();
    getArticles();
  },[])

  const handleDelete = (itemToDeleteId) => {
    tableRef.current.setAllData(tableRef.current.getAllData().filter(item => item.id !== itemToDeleteId));
    const article = articlesRef.current.find(item => item.id === itemToDeleteId)
    articleTable.current.addItem({
        id: article.id,
        codeArticle: article.code,
        codeFamille: article.famille_code || "",
        lotNbr: article.ds_lot_nbr,
        designation: article.designation,
        depotName: article.depot_name,
        quantity: article.ds_quantity || 0,
        ug: article.ug,
        puAchat: article.pu_achat,
        prixVenteGros: article.prix_vente_gros,
        puDetail: article.pu_detailer,
        prixVenteDemiGros: article.prix_vente_semi_gros,
        puSpecial: article.pu_special,
        unitMes: article.unit_mes,
        qtyCart: article.qte_cart,
        nbCart: article.nb_cart,
        puFact: article.pu_fournisseur,
        tva: article.tva,
        stkInit: article.stq_init,
        prixAchatInit: article.prix_achat_init,
        prixVenteInit: article.prix_vente_init,
        stockAlert: article.stock_alert,
        weight: article.weight,
        volume: article.volume,
        blocked: article.blocked ? 'Oui' : 'No',
        observation: article.observation
    });
  }

  const errorsHandler= useRef([]);
  const displayConfirmationDialog = useRef();
  const remiseR = useRef();
  const timbreInput = useRef();
  const nbrBonCommandeR = useRef();

  const checkCode = async (value)=>{
    const response = await factureFinder.get(`/${value}`)
    if(response.data.data[0]?.code == value){
      return true
    }else
      return false
  }

  const checkQuantityStq = (articles)=>{
    return articles.filter((article)=>{
      return parseInt(article.quantityart) > parseInt(article.quantity)
    }).map((article)=>{
      return {name:'remise',message:`la quantité de code d'article ${article.codeArticle} que vous voulez facture est supérieure à la quantité que vous avez`};
    })
  }

  const checkErrs = async ()=>{
    let alerts = [];
    const code = document.getElementById("code").value;
    const client = document.getElementById("Client").value;
    const id_client = client && selectedClient.current.getSelectedItem().id;
    const remise = document.getElementById("remise").value;
    const depotInput = document.getElementById("depot").value;
    
    if(!remise){
      alerts.push({name:'remise',message:'Assurez que la remise is bien saisez'});
      remiseR.current.setBorderRed();
    }
    if(!code){
      alerts.push({name:'code',message:'Assurez que la code is bien saisez'});
      codeInput.current.setBorderRed();
    }
    if(!depotInput){
      alerts.push({name:'depot',message:'Assurez que le Depot is bien saisez'});
      depot.current.setBorderRed();
    }
    if(!id_client){
      alerts.push({name:'client' ,message:'Assurez que la client est bien selectionez'});
      selectedClient.current.setBorderRed();
    }
    if( await checkCode(code) === true){
      alerts.push({name:'code',message:'le N° du bon est deja exister'});
      codeInput.current.setBorderRed();
    }
    let checkQuantity = checkQuantityStq(tableRef.current.getAllData());
    if( checkQuantity.length > 0){
      alerts.push(...checkQuantity)
    }
    if(selectedArticlesData.length === 0)
      alerts.push({name:'articles' ,message:'Assurez que la Articles est bien selectionez'});
    
    if(alerts.length > 0) {
      errorsHandler.current.errorsHandler(alerts);
      return
    }
    handelValidate();
  }

  const handelValidate= () => {
    displayConfirmationDialog.current.display();
  }

  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "35%"},
    {name: "Lot",width: "5%"},
    {name: "Exp",width: "5%"},
    {name: "TVA",width: "5%"},
    {name: "Qte",width: "5%"},
    {name: "UG",width: "5%"},
    {name: "PU",width: "10%"},
    {name: "RM%",width: "5%"},
    {name: "Total",width:"10%"}
  ]
  const printRef = useRef();

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getAllData();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'articles' , message: 'Assurez que la Articles est bien selectionez'}]);
      return
    }
    setIsActive(true);
    const articlesAdded = rowInfo.map((article)=>{
      const allArticleInfo = articlesRef.current.find((item)=> article.id === item.id)
      return({
            code: article.codeArticle,
            designation: article.designation,
            lotNbr: article.lot_nbr,
            exp: String(allArticleInfo.ds_expire_date.substring(5,7)+'/'+allArticleInfo.ds_expire_date.substring(0,4)),
            TVA: article.tva,
            quantity: article.quantityart,
            ug: article.ug,
            PUVente: article.puVente,
            remise: article.remise,
            total: article.total
        })
    });
    // console.log(selectedClient.current.getSelectedItem());
    const ROWS_PER_PAGE = 20; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      rowInfo: {
        code: document.getElementById("code").value,
        client_name: selectedClient.current.getSelectedItem().name,
        date: document.getElementById("date").value,
        client_address: selectedClient.current.getSelectedItem().address,
        nbr_bon_commande: document.getElementById('nbrBonCommande').value,
        client_activite: selectedClient.current.getSelectedItem().activity,
        payment_type: typePayment.current.getSelectedOption(),
        client_n_register: selectedClient.current.getSelectedItem().n_register,
        client_n_article: selectedClient.current.getSelectedItem().n_article,
        client_nif: selectedClient.current.getSelectedItem().nif,
        client_n_fiscal: selectedClient.current.getSelectedItem().n_fiscal,
        client_nis: selectedClient.current.getSelectedItem().nis,
        timbre: document.getElementById("timbre").value || 0
      },
      articlesAdded: pages,
      saisiPar: authState.user.name,
      globalInfo: {
        totalBeforeDiscounts: totalSansRM.current.getValue(),
        totalDiscounts: discountTotalRef.current.getValue(),
        totalAVT: tvaRef.current.getValue(),
        totalAfterDiscounts: total.current.getValue()
      }
    }
    console.log(data.globalInfo.totalBeforeDiscounts)
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }
  const handlePaymentTypeChange = () => setDisplayTimbre(typePayment.current.getSelectedOption() === "Espece")

  return (
    <PageLayout icon={icons.facture} nameLayout={'Facture'}>
      <form onSubmit={handleSubmit}>
        <div className={styles['body-container']}>
          <div className={styles['main-body']}>
            <div className={styles['actions-container']}>
              <div className={styles['row']}>
                <InputText  holderText={"Chercher un Client"} 
                            width={"50%"} 
                            labelPosition={true}
                            type={"text"} 
                            label={'Client'}
                            id={'Client'}
                            searchData={searchData}
                            path={"/ajouter_client"}
                            btnName={'Ajouter Nouveau Client'} 
                            ref={selectedClient}
                            />
                <Button  name='Imprimer' width={"250px"} icon={icons.impr} onclick={handlePrint} />
              </div>
              <div className={styles['row']}>
                <Button name='Ajouter Article' 
                        onclick={checkDepot} 
                        width={"250px"}
                        />
                <InputText  holderText={"001"} 
                            labelPosition={true} 
                            width={"250px"} 
                            type={"text"} 
                            label={'N° Bon Commande'}
                            id={'nbrBonCommande'}
                            ref={nbrBonCommandeR}
                            />
              </div>
              <div style={{height: "400px"}}>
                <Table thead={metaData.table} 
                        tbody={selectedArticlesData} 
                        ref={tableRef} 
                        confirmBeforeDeletion={false}
                        edit={user?.permissions.includes(permissions.canEditInTable)} 
                        remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                        handleDelete={handleDelete}
                        actOnValidation={actOnValidation}
                        />
              </div>
            
            </div>
          
          </div>
          <div className={styles['side-body']}>
            <PageSideBar validateFunc={checkErrs}>
              <InputText  label={"N° Facture impr"} 
                          id={'code'} 
                          holderText={"code"} 
                          width={"100%"} 
                          type={"number"}
                          ref={codeInput}
                          disabled
                          />
              <InputText  label={"Date de bon"} 
                          id={'date'} 
                          width={"100%"} 
                          type={"date"}
                          ref={dateInput}
                          />
              <SelectInput  options={options}
                            label={"Type de paiement"} 
                            width={"100%"} 
                            ref={typePayment}
                            reportChange={handlePaymentTypeChange}
                            />
              <InputText label={"Depot"} 
                width={"100%"}
                holderText={'Depot'}
                id={'depot'}
                onSelectItem={handleDepot}
                searchData={depots}
                ref={depot}/>

              <SubInfo label={"Total sans remise"} unit={currency} ref={totalSansRM}/>
              <InputText  label={"Remise"} 
                          id={'remise'} 
                          currency={true} 
                          currencyValue={'%'}  
                          width={"100%"} 
                          type={"number"} 
                          defaultValue={'0'}
                          holderText={"0.00"}
                          ref={remiseR}
                          reportChange={updateTaxes}
                          />
              <SubInfo label={"Total remise"} ref={discountTotalRef} />
              <SubInfo label={"Total TVA"} ref={tvaRef}/>
              {displayTimbre &&<InputText  label={"Timbre"} 
                          id={'timbre'} 
                          currency={true} 
                          currencyValue={'DZD'}  
                          width={"100%"} 
                          type={"number"} 
                          holderText={"0.00"}
                          ref={timbreInput}
                          reportChange={calculateTotal}
                          />}
              <SubInfo label={"Total"} ref={total}/>
            </PageSideBar>
          </div>
          <Dialog width={"80%"} table={true} ref={displayDialog} validateFunc={addSelectedArticles}>
            <AdderTable head={metaData.adderTable} ref={articleTable} />
          </Dialog>
      </div>
    <ConfirmationButton ref={displayConfirmationDialog}/>
    </form>
    <ErrorContainer ref={errorsHandler}/>
    {isActive && <Printing type={'Facture'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ValidatedMessages ref={validateHandler}/>
    </PageLayout>
  )
}

export default Facture

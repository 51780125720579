import React,{ useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import metaData from './metaData.json'
import DetailsSectionContainer from '../../components/details-section-container/DetailsSectionContainer'
import styles from './etatClient.module.scss'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'
import articleLivreFinder from '../../api/articleLivreFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import reglementFournisseurFinder from '../../api/reglementFournisseurFinder'
import creditClientFinder from '../../api/creditClientFinder'
import clientsFinder from '../../api/clientsFinder'

const EtatClient = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const clientName = useRef();
  const credit = useRef();
  const address = useRef();
  
  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const [search, setSearch] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const displayData = async (client)=>{
    try {
      
      const articlesLivreRes = await articleLivreFinder.get(`/`);
      const articlesAvoirRes = await articlesAvoirFinder.get('/vente');

      const filtredArticlesLivre = articlesLivreRes.data.data.articles.filter(article => article.id_client === client.id);
      const filtredArticlesAvoir = articlesAvoirRes.data.data.articles.filter(article => article.id_client === client.id);
      
      const { max_id } = articlesLivreRes.data.data;
      
      const articlesLivre = filtredArticlesLivre?.map(article => {
        return {
          id: article.id,
          operation: 'Vente',
          lot: article.lotNbr,
          designation: article.designation,
          Stock : article.ds_quantity,
          prix:article.prix,
          prixGrossiste: article.prix_vente_gros,
          ppa: article.prix_vente_init
        }
      });
      
      const articlesAvoir = filtredArticlesAvoir?.map(article =>{
        let id = article.id + max_id;
        return  {
          id,
          operation: 'Avoir',
          lot: article.lotNbr,
          designation: article.designation,
          Stock : article.ds_quantity,
          prix:article.prix_achat,
          prixGrossiste: article.prix_vente_gros,
          ppa: article.prix_vente_init
        }
      });

      setSearch([...articlesLivre,...articlesAvoir]);

      const response = await creditClientFinder(`/${client.id}`);
      const {total_credit, total_verser} = response.data.data;
      
      credit.current?.setValue(String(Math.abs(total_credit - total_verser)));
      address.current?.setValue(client.address || '-');
      clientName.current?.setValue(client.name.split('/')[1]);

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(()=>{

    const getClients = async ()=>{
      try {
        const four = await clientsFinder.get("/");
        setSearchData(four.data.data.clients.map(client => {
          return{
            id: client.id,
            address: client.address,
            name: `${client.code}/${client.name}`
            }
        }));
      } catch (err) {
        console.log(err);
      }
    }
    getClients();
    },[]);
  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={`Etat d'un Client`}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <div className={styles['row']}>
              <InputText label={"Period du"} 
                labelPosition={true} 
                width={"max-content"} 
                type={"date"}
                reportChange={reportDate1Change}
                />
              <InputText label={"Au"} 
                labelPosition={true} 
                width={"max-content"} 
                type={"date"}
                reportChange={reportDate2Change}
                />  
            </div>
            <div className={styles['row-end']}>
              <Button 
              icon={icons.impr}
              width={"250px"}
              name={'Imprimer'} 
              />
            </div>
          </div>
          <div>
            <DetailsSectionContainer name={"Détail de Client"}>
              <InputText  
                  holderText={"001"} 
                  type={"text"}
                  label={'Code'}
                  searchData={searchData}
                  onSelectItem={displayData}
                  displaySearchOption={true}
                  path={"/ajouter_client"}
                  btnName={'Ajouter Nouveau client'}
                  />

                <InputText  
                  holderText={"client"} 
                  type={"text"} 
                  label={'client'}
                  ref={clientName}
                  disabled
                  />

                <InputText  
                  holderText={"Adresse"} 
                  type={"text"} 
                  label={'Adresse'}
                  ref={address}
                  disabled
                  />

            </DetailsSectionContainer>
          </div>

          <div style={{height: '300px'}}>
            <Table 
                  thead={metaData.thead} 
                  tbody={search}
                  />
          </div>

          <div className={styles['row-end']} style={{width:'100%'}}>
            <InputText  
              holderText={"Credit"}
              type={"text"}
              label={'Credit'}
              ref={credit}
              disabled
              />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default EtatClient
import React, { useEffect, useState,useRef } from 'react';
import InputText from '../../components/input-text/InputText';
import Button from '../../components/button/Button';
import Table from '../../components/table/Table';
import styles from './articlesAcheter.module.scss';
import { icons } from '../../media/mediaHolder.js';
import PageLayout from '../../components/page-layout/PageLayout';
import metaData from './articlesAcheter.json';
import articlesAcheteFinder from '../../api/articlesAcheteFinder.js';
import articlesAvoirFinder from '../../api/articlesAvoirFinder.js';
import { dateFormatter } from '../../utils/dateFormatter'

const ArticleAcheter = () => {
  const [inputValue, setInputValue] = useState('');
  const [data,setData] = useState([])
  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const totalAchat = useRef();
  const totalRetour = useRef();
  const amountGlobal = useRef();

  useEffect( () => {
    let montantAchat = .0;
    let montantRetour = .0;

    const fetchArticlesAcheteAvoir = async () => {
      const articlesAcheteRes = await articlesAcheteFinder.get('/');
      const articlesAvoirRes = await articlesAvoirFinder.get('/achat');
      
      const { max_id } = articlesAcheteRes.data.data;
      
      const articlesAchete = articlesAcheteRes.data.data.articles.map((element)=>{

        // article montant
        let montant = (element.quantity * element.prix) * (1 - (element.remise/100));
        // montant total
        montantAchat += montant;
        // 

        return{
              id:element.id,
              achat_avoir: 'Achat',
              code_bon: element.bon_achat_code,
              date:dateFormatter(element.bon_achat_date,3),
              code_article:element.code,
              disgnation: element.designation || '',
              quantity:element.quantity,
              montant: montant
            }
      });
      
      const articlesAvoir = articlesAvoirRes.data.data.articles.map((element)=>{

        // montant article avoir
        let montant = (element.quantity * element.prix_achat) * (1 - (element.remise/100));
        // total montant avoir
        montantRetour += montant;
        // 

        let id = parseInt(element.id) + parseInt(max_id);
          return{
              id,
              achat_avoir: 'Avoir',
              code_bon: element.bon_avoir_code,
              date: dateFormatter(element.bon_avoir_date,3),
              code_article: element.code,
              disgnation: element.designation,
              quantity: element.quantity,
              montant,
          }
      });
      
      totalAchat.current?.setValue(montantAchat.toFixed(3));
      totalRetour.current?.setValue(montantRetour.toFixed(3));
      amountGlobal.current?.setValue((parseFloat(montantAchat) - parseFloat(montantRetour)).toFixed(3));
      
      setData([...articlesAvoir,...articlesAchete].sort((a,b) => b.date - a.date));

    }
    fetchArticlesAcheteAvoir();
  }, []);

  return (
        <PageLayout icon={icons.article} nameLayout={'Les Articles Acheter '}>
        <div style={{width :"100%" , height: "100%" , padding: "1% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <div className={styles['periode']}>
              <InputText 
                width={'15%'} 
                type={"date"}  
                label={'Periode du'}
                margin={'0px 0px 0px 15px'}
                reportChange={reportDate1Change}
              />
              <InputText 
                width={'15%'} 
                type={"date"}  
                label={'Au'}        
                margin={'0px 0px 0px 15px'}
                reportChange={reportDate2Change}
                />
            </div>
            <div className={styles['operations-buttons']}>
              <Button 
                name={'Imprimer'} 
                icon={icons.impr} 
                width={"150px"}
                />
            </div>
          </div>
          
          <div className={styles['table']}>
            <Table 
              thead={metaData.table} 
              inputValue={inputValue} 
              tbody={data}
              date1={date1} 
              date2={date2}
              />
          </div>
          <div className={styles['mantant-chargers']}>
            <InputText 
              width={'25%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Des Achats'}
              margin={'0px 15px 0px 15px'}
              ref={totalAchat}
              disabled
              />
            <InputText 
              width={'25%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Des Retours'}
              margin={'0px 15px 0px 0px'}
              ref={totalRetour}
              disabled
              />
            <InputText 
              width={'25%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Global'}
              margin={'0px 15px 0px 0px'}
              ref={amountGlobal}
              disabled
              />
          </div>
        </div>
        </PageLayout>
  );
}

export default ArticleAcheter;

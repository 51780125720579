import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import InputText from '../../components/input-text/InputText';
import Table from '../../components/table/Table';
import metaData from './metaData.json'
import Button from '../../components/button/Button';
import styles from './etatBenifice.module.scss';
import bonVentesFinder from '../../api/bonVentesFinder';
import bonAvoirFinder from '../../api/bonAvoirFinder';
import bonAchatFinder from '../../api/bonAchatsFinder';
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter';

const EtatBenifice = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ inputValue , setInputValue ] = useState('');

  const [data,setData] = useState([])
  useEffect( () => {
    const fetchBonsVenteAvoir = async () =>{
      try {
        let montantVente = .0;
        const bonsVenteRes = await bonVentesFinder.get('/');
        const bonsAvoirRes = await bonAvoirFinder.get('/vente');

        const { max_id } = bonsVenteRes.data.data; 
        
        const bonsVents = bonsVenteRes.data.data.bons.map((bon)=>{
          montantVente += bon.amount;

          return{
            id: bon.id,
            Vente_av: 'Vente',
            date: dateFormatter(bon.date,3),
            client_code: bon.client_code,
            client_name: bon.client_name,
            montant_des_vente: bon.amount,
            montant_des_benifice: bon.benifice || 0,
            NTel:bon.client_phone,
            wilaya:bon.wilaya,
            address:bon.client_address
            }
          }
        );

        const bonsAvoir = bonsAvoirRes.data.data.bons.map((bon)=>{
          let id = parseInt(bon.id) + parseInt(max_id)
          return{
            id,
            Vente_av: 'Avoir',
            date: dateFormatter(bon.date,3),
            client_code: bon.client_code,
            client_name: bon.client_name,
            montant_des_vente: bon.amount,
            montant_des_benifice: bon.benifice || 0,
            NTel:bon.client_phone || '-',
            wilaya:bon.wilaya|| '-',
            address:bon.client_address
            }
          }
        );

        setData([...bonsVents,...bonsAvoir].sort((a,b) => a.date - b.date));
      } catch (err) {
        console.log(err);
      }
      
    }

    fetchBonsVenteAvoir();
  }, []);

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat du bénéfice par Client'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
              icon={icons.search}
              type={"text"}
              holderText={"Chercher"}
              label={"Chercher"}
              labelPosition={true}
              width={"50%"}
              />  
              <div className={styles['row-end']}>
                <Button 
                  icon={icons.exporter}
                  width={"max-content"}
                  name={'Exporter Vers Excel'} 
                  />
              </div>
          </div>
          <div className={styles['row']}>
            <InputText
              label={"Periode De"}
              labelPosition={true}
              width={"max-content"}
              type={"date"}
              />
            <InputText label={"Au"} 
              labelPosition={true}
              width={"max-content"}
              type={"date"}
              />
          </div>
          <div style={{height: '300px'}}>
            <Table 
              thead={metaData.thead} 
              inputValue={inputValue} 
              tbody={data} />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default EtatBenifice
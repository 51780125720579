import React, { useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import SelectInput from '../../components/select-input/SelectInput'
import Dialog from '../../components/dialog/Dialog'
import styles from './reglementfournisseur.module.scss'
import reglementFournisseurFinder from '../../api/reglementFournisseurFinder'
import fournisseurFinder from '../../api/fournisseurFinder'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import metaData from './metaData.json'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import bonAvoirFinder from '../../api/bonAvoirFinder'
import detteFournisseurFinder from '../../api/detteFournisseurFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'

const ReglementFournisseur = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const optionsTable =[
    "Reglement",
    "Consultation BL",
  ]

  // payment type options
  const options =[
    "Espece",
    "cheque"
  ];

  const [selectedOption,setSelectedOption] = useState(options[0]);
  const reportChange = (val) => setSelectedOption(val);

  const [selectedTabOption,setSelectedTabOption] = useState(optionsTable[0]);
  const reportTabChange = (val) =>{
    setSelectedTabOption(val)
    val === optionsTable[0] ? setSearch(regData) : setSearch(blData);
  };

  const [blData, setBlData] = useState([]);
  const [regData, setRegData] = useState([]);

  const [searchData, setSearchData] = useState([]);
  const [search, setSearch] = useState([]);
  
  const [value, setValue] = useState(0);
  
  const handleDelete =  (key)=> {
    reglementFournisseurFinder.delete(`/${key}`);
    const updatedData = search.filter((item) => item['id'] !== key);
    setSearch(updatedData);
  }
  
  const displayDialog = useRef();
  const getSelectedFournisseur = useRef();
  const currentdate =useRef();
  const periodParDate = useRef();
  const totalBonAchat = useRef();
  const totalmonatantVerse = useRef();
  const soldeFournisseur = useRef();
  const select_option = useRef();

  //get reglements
  useEffect(()=>{
    const date = new Date();
    currentdate.current.setValue(date.toISOString().substring(0, 10));
    periodParDate.current.setValue(date.toISOString().substring(0, 10));

    const getFournisseurs = async ()=>{
      try {
        const four = await fournisseurFinder.get("/");
        setSearchData(four.data.data.fournisseurs);
      } catch (err) {
        console.log(err);
      }
    }
    getFournisseurs();
    },[]);

  const displayData = async (fournisseur)=>{
    try {
      const reglement = await reglementFournisseurFinder.get(`/${fournisseur.id}`);

      const data1 = reglement.data.data.reglementsFournisseur.map(reg =>{
        return ({
          id:reg.id,
          Date : reg.date?.substring(0, 10),
          Heure : reg.creation_ts?.substring(11,16),
          Montant : reg.montant,
          Type_verser : reg.type_ver,
          Agent : reg.agent,
          N_chaque : reg.num_cheque,
          N_Compte : reg.num_Compte,
          Etat_de_chaque : reg.etat_cheque,
          Observation : reg.observation,
        });
      });

    const bonAchat = await bonAchatsFinder.get(`/`);
    const bonAvoir = await bonAvoirFinder.get('/achat');

    const filtredBonsAchat = bonAchat.data.data.bons.filter(bon => bon.id_fournisseur === fournisseur.id);
    const filtredBonAvoir = bonAvoir.data.data.bons.filter(bon => bon.id_fournisseur === fournisseur.id);
    
    const { max_id } = bonAchat.data.data;
    
    const bonsAchat = filtredBonsAchat?.map(bon => {
      return {
        id: bon.id,
        operation: 'Achat',
        code: bon.code,
        date: dateFormatter(bon.date,3),
        Heure : bon.creation_date?.substring(11,16),
        montant:bon.amount,
        user: bon.user_name
      }
    });
    
    const bonsAvoir = filtredBonAvoir?.map(bon =>{
      let id = bon.id + max_id;
      return  {
        id,
        operation: 'Avoir',
        code: bon.code,
        date: dateFormatter(bon.date,3),
        Heure : bon.creation_date?.substring(11,16),
        montant:bon.amount,
        user: bon.user_name
      }
    });

    setRegData(data1);
    setBlData([...bonsAchat,...bonsAvoir]);

    // setData([...articlesAvoir,...articlesAchete]);
    selectedTabOption === optionsTable[0] ? setSearch(data1) : setSearch([...bonsAchat,...bonsAvoir]);

    // get the fournisseur dette informations
    const response = await detteFournisseurFinder(`/${fournisseur.id}`);
    const {total_dette , total_verser} = response.data.data;
    // total dett
    totalBonAchat.current.setValue(String(total_dette));
    // total verser
    totalmonatantVerse.current.setValue(String(total_verser));
    // rest dette
    soldeFournisseur.current.setValue(String(Math.abs(total_dette - total_verser)));
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    const date = new Date(currentdate.current.getValue());
    try {
        const response = await reglementFournisseurFinder.post("/",
        {
          id_fournisseur: getSelectedFournisseur.current.getSelectedItem().id,
          amount: document.getElementById("reglement_fournisseur_montant").value,
          type_verser: select_option.current?.getSelectedOption(),
          agent: "agent",
          n_cheque: select_option.current?.getSelectedOption() === "cheque" && document.getElementById("reglement_fournisseur_cheque_number").value || null,
          n_compte: select_option.current?.getSelectedOption() === "cheque" && document.getElementById("reglement_fournisseur_bank_account").value || null,
          etat_cheque:select_option.current?.getSelectedOption() === "cheque" && "Etat Cheque",
          date_payment_cheq: date,
          observation: document.getElementById("reglement_fournisseur_observation").value,
        }
        );
        displayData(getSelectedFournisseur.current.getSelectedItem());
    } catch (err) {
        console.log(err);
    }
  }
  const errorsHandler= useRef([]);

  const checkFournisseur = ()=> {
    let alerts = [];
    let fournisseur = document.getElementById("fournisseurs").value

    if(!fournisseur){
      alerts.push({name:'fournisseur',message:'Assurez que le fournisseur is bien saisez'});
      getSelectedFournisseur.current.setBorderRed();
    }
    if(alerts.length > 0) {
      errorsHandler.current.errorsHandler(alerts);
      return
    }
    displayDialog.current.display();
  }

  return (
    <PageLayout icon={icons.reglementFournisseur.secondary} nameLayout={'Règlement Fournisseur'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
                <div className={styles['row-between']}>
                    <InputText  
                      id={'fournisseurs'} 
                      holderText={"fournisseur"} 
                      labelPosition={true} 
                      type={"text"} 
                      label={'Ajouter fournisseur'}
                      width={"40%"}
                      searchData={searchData}
                      onSelectItem={displayData}
                      ref={getSelectedFournisseur}
                      displaySearchOption={true}
                      path={"/ajouter_fournisseur"}
                      btnName={'Ajouter Nouveau fournisseur'}
                    />
                    <div className={styles['row-end']}>
                        <Button 
                        width={"250px"}
                        name='Insérer Règlement' 
                        onclick={()=>checkFournisseur()}
                        icon={icons.insere}
                        height={"100%"}
                        />
                        <Button 
                        link={true} 
                        path={"../etat_fournisseur"} 
                        width={"150px"}
                        height={"100%"}
                        icon={icons.insere}
                        name='Etat' 
                        />
                    </div>
                    
                </div>
            <div className={styles['row-between']}>
              <div className={styles['row-between']}>
                  <div className={value == 1 ? styles["noDisplay"] : styles["row"]}>
                      <InputText 
                        label={"Period par date"} 
                        labelPosition={true} 
                        type={"date"}
                        ref={periodParDate}
                      />
                      <InputText  
                        holderText={"Text"} 
                        labelPosition={true} 
                        type={"text"}
                        label={'Recharche des règlement'}
                        />
                  </div>
                  <SelectInput 
                    height={"100%"} 
                    options={optionsTable}
                    width={"33.3%"}
                    reportChange={reportTabChange}/>
              </div>
            </div>
            <div style={{height: '44vh'}}>
              <Table thead={optionsTable[0] === selectedTabOption ? metaData.thead[0] : metaData.thead[1] }
                    tbody={search} 
                    handleDelete={handleDelete} 
                    edit={( optionsTable[0] === selectedTabOption ) ? user?.permissions.includes(permissions.canEditInTable): false} 
                    remove={( optionsTable[0] === selectedTabOption ) ? user?.permissions.includes(permissions.canDeleteFromTable): false} 
                  />
            </div>
            
            <div className={styles['row-between']}>
              <InputText  
                holderText={"Montant"} 
                labelPosition={true} 
                type={"number"} 
                label={'Total des bons d\'achats'}
                ref={totalBonAchat}
                currency={true} 
                currencyValue={"DZD"} 
                width={"23%"}
                />
              <InputText  
                holderText={"Montant"} 
                labelPosition={true} 
                type={"number"} 
                currency={true} 
                ref={totalmonatantVerse}
                currencyValue={"DZD"} 
                label={'Total des versement'}
                width={"23%"}
                />
              <InputText  
                holderText={"Montant"} 
                id={'soldeFouenisseur'}
                labelPosition={true} 
                type={"number"} 
                currency={true} 
                currencyValue={"DZD"} 
                ref={soldeFournisseur}
                label={'Solde fournisseur'}
                width={"23%"}
                />
            </div>
            <form onSubmit={handleSubmit}>
              <Dialog ref={displayDialog} type={true}>
                <div className={styles['row-between']} >
                  <InputText 
                    id={"reglement_fournisseur_date"}
                    width={'40%'}
                    height={'40px'}
                    label={'Date Veresment'} 
                    type={'date'}
                    ref={currentdate}
                  />
                  {/*<div style={{width: "60%" , position: 'relative' }}>*/}
                    <SelectInput 
                    id={"reglement_fournisseur_type_payment"}
                    label={"Type de Paiment"}
                    options={options}
                    ref={select_option}
                    width={"60%"}
                    height={'40px'}
                    reportChange={reportChange}
                  />
                  {/*</div>*/}
                </div>
                <div style={{marginTop: "20px"}} className={styles['col-start']}>
                  <InputText 
                      id={"reglement_fournisseur_montant"}
                      width={'100%'} 
                      height={'100%'}
                      label={'Montant'} 
                      holderText={'Montant'}
                      type={'number'}
                      
                    />
                    {selectedOption === options[1] && <InputText 
                      id={"reglement_fournisseur_cheque_number"}
                      width={'100%'} 
                      height={'100%'}
                      label={'N° Chèque'} 
                      holderText={'N° Chèque'}
                      type={'text'}
                      
                    />}
                    {selectedOption === options[1] && <SelectInput 
                    id={"reglement_fournisseur_bank_account"}
                      options={options}
                      width={"100%"}
                      height={'100%'}
                      label={"Compte bancaire"}
                    />}
                    <InputText 
                      id={"reglement_fournisseur_observation"}
                      width={'100%'} 
                      height={'100%'}
                      label={'Observation'} 
                      type={'text'}
                      holderText={'Observation'}
                    />
                </div>
            </Dialog>
          </form>
        </div>
      </div>
      <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  );
}

export default ReglementFournisseur
import React, { useEffect, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './consultationfacture.module.scss'
import { useState } from 'react'
import factureFinder from '../../api/factureFinder'
import articleFactureFinder from '../../api/articleFactureFinder'
import Printing from '../../components/printing/Printing'
import bonAvoirFinder from '../../api/bonAvoirFinder'
import metaData from './metaData.json'
import clientsFinder from '../../api/clientsFinder'
import articleLivreFinder from '../../api/articleLivreFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import printingFinder from '../../api/printingFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'

const ConsultationFacture = () => {
  const { state: authState } = useAuth();
  const { user } = authState;
  
  const [data, setData] = useState([]);
  const [dataSuiv, setDataSuiv] = useState([]);
  const [bonsInfo,setBonsInfo] = useState([]);
  const [refreshTable,setRefreshTable] = useState(false);

  const handleDelete =  (key,obj)=> {
    if(obj.factureAvoir === 'facture'){
      factureFinder.delete(`/${key}`);
      const updatedData = data.filter((item) => item['id'] !== key);
      tableRef.current.setAllData(updatedData)
    }else if(obj.factureAvoir === 'avoir'){
      bonAvoirFinder.delete(`/${parseInt(key)-parseInt(maxId)}`);
      const updatedData = data.filter((item) => item['id'] !== key);
      tableRef.current.setAllData(updatedData)
    }
  }

  const actOnValidation = async (obj) => {
    
    console.log(obj);

    const date = new Date(obj.date);
    const now = Date.now();
    const currentHours = new Date(now).getHours();
    const currentMinutes = new Date(now).getMinutes();
    const currentSeconds = new Date(now).getSeconds();
    date.setHours(currentHours+1);
    date.setMinutes(currentMinutes);
    date.setSeconds(currentSeconds);

    let client = obj.client.split('/');
    
    
    const response = await clientsFinder.get(`/${client[0]}`);
    const clientDetail = response.data.data.client;
    await clientsFinder.put(`/${clientDetail.id}`,{
      id_wilaya:clientDetail.id_wilaya,
      code: clientDetail.code,
      name: clientDetail.name,
      address: clientDetail.address,
      activity: clientDetail.activity,
      fax: clientDetail.fax,
      phone: clientDetail.phone,
      email: clientDetail.email,
      n_register: clientDetail.n_register,
      n_article: clientDetail.n_article,
      n_fiscal: clientDetail.n_fiscal,
      nis: clientDetail.nis,
      nif: clientDetail.nif,
      type_client: obj.typeClient,
      blocked: clientDetail.blocked,
      credit_max: clientDetail.credit_max,
      delais_vers_max: clientDetail.delais_vers_max,
      fournisseur:clientDetail.fournisseur,
      code_fournisseur:clientDetail.code_fournisseur
    });

    if(obj.factureAvoir === 'facture'){
      const facture = await factureFinder.get(`/${obj.code}`);
      const bon = facture.data.data.facture;
      const factureUpdated = await factureFinder.put(`/${parseInt(obj.id)}`,{
        typePayment: obj.payment_type || bon.payment_type,
        remise: obj.remise || bon.remise,
        typeRemise: obj.type_remise || bon.remise_type,
        date:date,
        timbre:obj.timbre,
        nbrBonCommande:obj.nbr_bon_commande,
        idClient:clientDetail.id
      });
  }else if(obj.factureAvoir === 'avoir'){
    const bonAvoir = await bonAvoirFinder.get(`/${obj.code}/facture`);
    const bon = bonAvoir.data.data.bons;
    console.log(bon);
    const bonUpdated = await bonAvoirFinder.put(`/${parseInt(bon[0].id)}`,{
      typePayment: obj.type_payment || bon.payment_type,
      remise: obj.remise || bon.remise,
      typeRemise: obj.type_remise || bon.type_remise,
      date:date,
      idClient:clientDetail.id
    });
  }
  setRefreshTable(!refreshTable);
  }

  const actOnValidationArticle = async (obj) => {
    
    const date = new Date(obj.date_peremtion);
    const now = Date.now();
    const currentHours = new Date(now).getHours();
    const currentMinutes = new Date(now).getMinutes();
    const currentSeconds = new Date(now).getSeconds();
    date.setHours(currentHours);
    date.setMinutes(currentMinutes);
    date.setSeconds(currentSeconds);

    if(obj.factureAvoir === 'facture'){
      // console.log(obj)
      await articleFactureFinder.put(`${parseInt(obj.id)}`,{
        quantity: obj.quantity,
        prixVente: obj.PUVente,
        remise: obj.remise,
        remiseType: obj.remiseType || 'parcentage',
        ug: obj.ug,
        delivered: obj.delivered === 'Oui' ? true : false,
        expire_date: date,
        tva:obj.tva,
        observation: obj.observation,
      });
  }else if(obj.factureAvoir === 'avoir'){
    console.log(obj)
    await articlesAvoirFinder.put(`/${parseInt(obj.id)}`,{
      quantity:obj.quantity,
      prixAchat:obj.PUVente,
      remise: parseInt(obj.remise)
    });
  }

  }

  const handleDeleteArticle =  (key,obj)=> {
    if(obj.factureAvoir === 'facture'){
      console.log(key);
      articleFactureFinder.delete(`/${key}`);
      const updatedData = tableRefSuiv.current.getAllData().filter((item) => item['id'] !== key);
      tableRefSuiv.current.setAllData(updatedData)
    }else if(obj.factureAvoir === 'avoir'){
      articlesAvoirFinder.delete(`/${key}`);
      const updatedData = tableRefSuiv.current.getAllData().filter((item) => item['id'] !== key);
      tableRefSuiv.current.setAllData(updatedData)
    }
  }

  useEffect(()=>{
    
    const getClients = async ()=>{
      const response = await clientsFinder.get("/");
      const clients = response.data.data.clients.map(client =>{
        return({
          name:`${client.code}/${client.name}`
        });
      });
      metaData.thead_facture.find(el => el.name === "Code/Client").children = clients;
      const typeClient = [{name:'Gros'},{name:'Demi Gros'},{name:'Special'},{name:'Detail'}];
      metaData.thead_facture.find(el => el.name === "Type Client").children = typeClient;
    }
    getClients();
  },[]);

  const [maxId,setMaxId] = useState(0)
  useEffect(() => {
    const fetchBonsAchat = async () => {
      const response = await factureFinder.get('/');
      const response2 = await bonAvoirFinder.get('/facture');

      setMaxId(parseInt(response.data.data.maxId)+1)
      let data = response2.data.data.bons.map((bon)=>{
        return {...bon, id : String(parseInt(bon.id)+parseInt(response.data.data.maxId)+1),type: 'avoir'}
      });
      for(let i=0;i<response.data.data.factures.length;i++)
        data.push(response.data.data.factures[i])
  
      data = data.sort((a, b) => {
        return new Date(b.date) - new Date(a.date); // Sort based on date 
      });
      setBonsInfo(data);
      let empty = '';
      setData(data.map((facture)=>{
        return(
          {
            id: facture.id,
            factureAvoir: facture?.type === 'avoir' ? 'avoir': "facture",
            code: facture.code,
            codeImpr: facture.codeImp || empty,
            date: facture.date.substring(0, 10),
            client: `${facture.client_code || empty}/${facture.client_name || empty}`,
            observation: facture.observation || empty,
            timbre: facture.timbre || empty,
            saisiPar: facture.user_name || empty,
            nbrBonCommande: facture.codeBon || empty,
            typeClient: facture.client_type_client || empty
          }
        );
      }));
    }
    fetchBonsAchat();
  },[]);

  const handleClick = async (rowInfo)=>{
    if(!rowInfo){
      setDataSuiv([])
      return
    }
    
    bonsInfo.map((element)=>{
      if(element.id === rowInfo.id){
        setDataSuiv(
         element.articlesAdded.map((article,index)=>{
          return {
            id: article.id,
            factureAvoir:element?.type || 'facture',
            code: article.code,
            designation: article.designation,
            lotNbr: article.lotNbr,
            exp: String(article.expire_date.substring(5,7)+'/'+article.expire_date.substring(0,4)),
            quantity: article.quantity,
            PUVente: article.prix,
            TVA: article.articleTva,
            remise: article.remise,
            ug: article.ug ||0,
            total: (parseFloat((article.prix * article.quantity)*(1 -(article.remise / 100)))).toFixed(2)
          }
        })
        )
      }
    })
  }
  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "35%"},
    {name: "Lot",width: "5%"},
    {name: "Exp",width: "5%"},
    {name: "TVA",width: "5%"},
    {name: "Qte",width: "5%"},
    {name: "UG",width: "5%"},
    {name: "PU",width: "10%"},
    {name: "RM%",width: "5%"},
    {name: "Total",width:"10%"}
  ]

  const tableRef = useRef();
  const tableRefSuiv = useRef();
  const [inputValue, setInputValue] = useState('');
  const printRef = useRef();
  const achatAvoir= useRef();
  const filterByArr = ['code'];
  const handleSearch = (val) => {
      setInputValue(val);
  }

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);

  const handlePrint = async ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
    setIsActive(true);
    const selectedBon = bonsInfo.find((element)=> element.id === rowInfo[0].id)
    console.log(selectedBon)
    selectedBon?.type === "avoir" ? achatAvoir.current = "Avoir": achatAvoir.current = "facture"
    const articlesAdded = tableRefSuiv.current.getAllData().map((article)=>{
      return({
            code: article.code,
            designation: article.designation,
            lotNbr: article.lotNbr,
            exp: article.exp,
            TVA: article.TVA,
            quantity: article.quantity,
            ug: article.ug,
            PUVente: article.PUVente,
            remise: article.remise,
            total: article.total
        })
    });
    const ROWS_PER_PAGE = 20; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      rowInfo: selectedBon,
      articlesAdded: pages,
      validerPar: rowInfo[0].saisiPar
    }
    // console.log(data)
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation des Facture'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher Par N° Facture"}
                label={"Chercher"}
                labelPosition={true}
                width={"50%"}
                reportChange={(val) => handleSearch(val)}
                />  
                <div className={styles['row-end']}>
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                  onclick={handlePrint}
                  />
                </div>
              </div>
              <div style={{height: '300px'}}>
                <Table 
                  tableTitle={"Liste des Facture"} 
                  sendSelectedRowInfo={handleClick} 
                  handleDelete={handleDelete}
                  actOnValidation={actOnValidation}
                  thead={metaData.thead_facture} 
                  singular={true}
                  ref={tableRef}
                  filterInput={inputValue}
                  filterByArr={filterByArr}
                  tbody={data} 
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                  />
              </div>
              <div className={styles['row-end']} style={{width:'100%'}}><br/><br/></div>
              <div style={{height: '300px'}}>
                <Table 
                  tableTitle={"Détail du Facture"} 
                  handleDelete={handleDeleteArticle}
                  actOnValidation={actOnValidationArticle}
                  ref={tableRefSuiv} 
                  thead={metaData.thead_detailleBon} 
                  tbody={dataSuiv}
                  edit={user?.permissions.includes(permissions.canEditInTable)}
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                  />
              </div>

            </div>
        
    </div>
    {isActive && <Printing type={achatAvoir.current} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default ConsultationFacture;

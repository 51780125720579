import React,{ useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import metaData from './metaData.json'
import DetailsSectionContainer from '../../components/details-section-container/DetailsSectionContainer'
import styles from './etatFournisseur.module.scss'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import reglementFournisseurFinder from '../../api/reglementFournisseurFinder'
import detteFournisseurFinder from '../../api/detteFournisseurFinder'
import fournisseurFinder from '../../api/fournisseurFinder'

const EtatFournisseur = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const fournisseurName = useRef();
  const sold = useRef();
  const address = useRef();
  const getSelectedFournisseur = useRef();
  
  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const [search, setSearch] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const displayData = async (fournisseur)=>{
    try {
      
      const articlesAcheteRes = await articlesAcheteFinder.get(`/`);
      const articlesAvoirRes = await articlesAvoirFinder.get('/achat');

      const filtredArticlesAchat = articlesAcheteRes.data.data.articles.filter(article => article.id_fournisseur === fournisseur.id);
      const filtredArticlesAvoir = articlesAvoirRes.data.data.articles.filter(article => article.id_fournisseur === fournisseur.id);
      
      const { max_id } = articlesAcheteRes.data.data;
      
      const articlesAchete = filtredArticlesAchat?.map(article => {
        return {
          id: article.id,
          operation: 'Achat',
          lot: article.lotNbr,
          designation: article.designation,
          Stock : article.ds_quantity,
          prix:article.prix,
          prixGrossiste: article.prix_vente_gros,
          ppa: article.prix_vente_init
        }
      });
      
      const articlesAvoir = filtredArticlesAvoir?.map(article =>{
        let id = article.id + max_id;
        return  {
          id,
          operation: 'Avoir',
          lot: article.lotNbr,
          designation: article.designation,
          Stock : article.ds_quantity,
          prix:article.prix_achat,
          prixGrossiste: article.prix_vente_gros,
          ppa: article.prix_vente_init
        }
      });

      setSearch([...articlesAchete,...articlesAvoir]);

      const response = await detteFournisseurFinder(`/${fournisseur.id}`);
      const {total_dette , total_verser} = response.data.data;
      sold.current.setValue(String(Math.abs(total_dette - total_verser)));

      address.current?.setValue(fournisseur.address);
      fournisseurName.current?.setValue(fournisseur.name.split('/')[1]);

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(()=>{

    const getFournisseurs = async ()=>{
      try {
        const four = await fournisseurFinder.get("/");
        setSearchData(four.data.data.fournisseurs.map(four => {
          return{
            id: four.id,
            address: four.address,
            name: `${four.code}/${four.name}`
            }
        }));
      } catch (err) {
        console.log(err);
      }
    }
    getFournisseurs();
    },[]);

  return (
    <PageLayout icon={icons.reglementFournisseur.secondary} nameLayout={`Etat d'un Fournisseur`}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <div className={styles['row']}>
              <InputText label={"Period du"} 
                labelPosition={true} 
                width={"max-content"} 
                type={"date"}
                reportChange={reportDate1Change}
                />
              <InputText label={"Au"} 
                labelPosition={true} 
                width={"max-content"} 
                type={"date"}
                reportChange={reportDate2Change}
                />  
            </div>
            <div className={styles['row-end']}>
              <Button 
              icon={icons.impr}
              width={"250px"}
              name={'Imprimer'} 
              />
            </div>
          </div>
          <div>
            <DetailsSectionContainer name={"Détail de fournisseur"}>
              <InputText  
                  holderText={"001"} 
                  type={"text"}
                  label={'Code'}
                  searchData={searchData}
                  onSelectItem={displayData}
                  ref={getSelectedFournisseur}
                  displaySearchOption={true}
                  path={"/ajouter_fournisseur"}
                  btnName={'Ajouter Nouveau fournisseur'}
                  />

                <InputText  
                  holderText={"fournisseur"} 
                  type={"text"} 
                  label={'fournisseur'}
                  ref={fournisseurName}
                  disabled
                  />

                <InputText  
                  holderText={"Adresse"} 
                  type={"text"} 
                  label={'Adresse'}
                  ref={address}
                  disabled
                  />

            </DetailsSectionContainer>
          </div>

          <div style={{height: '300px'}}>
            <Table 
                  thead={metaData.thead} 
                  tbody={search}
                  />
          </div>

          <div className={styles['row-end']} style={{width:'100%'}}>
            <InputText  
              holderText={"Solde"}
              type={"text"}
              label={'Solde'}
              ref={sold}
              disabled
              />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default EtatFournisseur
import React, { useEffect, useState, useRef } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import metaData from './livreAchat.json'
import styles from './livreAchats.module.scss'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import SelectInput from '../../components/select-input/SelectInput'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import { useAuth } from '../../contexts/AuthContext.js'
import permissions from '../../utils/permissions.js'
import { dateFormatter } from '../../utils/dateFormatter.js'
import Printing from '../../components/printing/Printing.js'
import ErrorContainer from '../../components/errorContainer/ErrorContainer.js'


const LivreAchats = () => {
  const { state: authState } = useAuth();
  const { user } = authState;
    
  const options =["livre des achat global","cheque"];

  let [data, setData] = useState([]);
  const tableRef = useRef();
  const tableData = useRef();
  const date1 = useRef();
  const date2 = useRef();
  const reportDate1Change = (val) => {
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!val || itemDate >= new Date(val)) &&
        (!date2.current.getValue() || itemDate <= new Date(date2.current.getValue()))
      );
    }));
  }
  const reportDate2Change = (val) =>{
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!date1.current.getValue() || itemDate >= new Date(date1.current.getValue())) &&
        (!val || itemDate <= new Date(val))
      );
    }))
  }

  const totalAchat = useRef();
  const totalRetour = useRef();
  const amountGlobal = useRef();

  useEffect(() => {
    let montantAchat = .0;
    let montantRetour = .0;

    const fetchArticlesAcheteAvoir = async () =>{
      try {
        const articlesAcheteRes = await articlesAcheteFinder.get('/');
        const articlesAvoirRes = await articlesAvoirFinder.get('/achat');

        const {max_id} = articlesAcheteRes.data.data;
        const articlesAchete = articlesAcheteRes.data.data.articles.map((article)=>{
          // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING IT PROMOTION
          let montant = (article.quantity * article.prix) * (1 - (article.remise/100));
          // 
          montantAchat += montant;
          // 

          return{
                  id:article.id,
                  achatAvoir: 'achat',
                  date: dateFormatter(article.bon_achat_date,3),
                  montant: montant,
                  disgnation: article.designation || ''
                }
          }
        );

        const articlesAvoir = articlesAvoirRes.data.data.articles.map((article)=>{
          // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING IT PROMOTION
          let montant = (article.quantity * article.prix_achat) * (1 - (article.remise/100));
          // 
          montantRetour += montant;
          // 

          let id = parseInt(article.id) + parseInt(max_id);
            return{
                    id,
                    achatAvoir: 'avoir',
                    date: dateFormatter(article.bon_avoir_date,3),
                    montant,
                    disgnation: article.designation || ''
            }
        });
        
        setData([...articlesAvoir,...articlesAchete]);
        tableData.current = [...articlesAvoir,...articlesAchete];

        totalAchat.current?.setValue(montantAchat);
        totalRetour.current?.setValue(montantRetour);
        amountGlobal.current?.setValue(parseFloat(montantAchat) - parseFloat(montantRetour));
      } catch (err) {
        
      }
      

    }
    fetchArticlesAcheteAvoir();
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);
  const printRef = useRef()
  const achatAvoir = useRef()

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
  
    setIsActive(true);
    achatAvoir.current = "Livre des Achat"
    const articlesAdded = tableRef.current.getSelectedItems().map((etatBon)=>{
      return{
        date: etatBon.date,
        achatAvoir: etatBon.achatAvoir,
        montant: etatBon.montant,
      }
    });
    const ROWS_PER_PAGE = 25; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages,
      globalInfo: {
        totalDesAchat: totalAchat.current.getValue(),
        totalAvoirAchat: totalRetour.current.getValue(),
        total: amountGlobal.current.getValue(),
        periodeDu: date1.current.getValue(),
        au: date2.current.getValue()
      }
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print();
    }, 100);
    
  }
  const PrintingColumns = [
    {name: "Date",width: "10%"},
    {name: "Achat/Av",width: "10%"},
    {name: "Montant",width:"10%"}
  ]

  return (
    <PageLayout icon={icons.article} nameLayout={'Livre Des Achats'}>
      <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
        <div className={styles['main-search-bar']}>
          <div className={styles['periode']}>
              <InputText 
                width={'20%'} 
                type={"date"}  
                label={'Periode du'}
                margin={'0px 0px 0px 15px'}
                labelPosition={true} 
                ref={date1}
                reportChange={reportDate1Change}
                />
              <InputText 
                width={'20%'} 
                type={"date"}  
                label={'Au'}
                margin={'0px 0px 0px 15px'} 
                labelPosition={true}
                ref={date2}
                reportChange={reportDate2Change}
                />
          </div>
          <div className={styles['operations-buttons']}>
            <Button name={'Imprimer'} icon={icons.impr} width={"250px"} onclick={handlePrint} />
          </div>
        </div>
        <div className={styles['select-input']}>
          <SelectInput options={options} width={"35%"}/>
        </div>
        <div className={styles['table']}>
          <Table thead={metaData.table}
                  ref={tableRef}
                  tbody={data} 
          />
        </div>
        <div className={styles['mantant-chargers']}>
          <div>
              <InputText 
                width={'100%'} 
                type={"number"} 
                holderText={"Montant"} 
                label={'Montant Des Achat'}
                margin={'0px 15px 0px 15px'}
                ref={totalAchat}
                disabled

                />
          </div>
          <div>
              <InputText 
                width={'100%'}
                type={"number"} 
                holderText={"Montant"} 
                label={'Montant Des Retours'}
                margin={'0px 15px 0px 0px'}
                ref={totalRetour}
                disabled
                />
          </div>
          <div style={{display: "flex"}}>
            <InputText 
              width={'100%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Global'}
              margin={'0px 15px 0px 0px'}
              ref={amountGlobal}
              disabled
              />
          </div>
        </div>  
      </div>
      {isActive && <Printing type={achatAvoir.current} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
      <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default LivreAchats;

import React, { useEffect, useRef, useState } from 'react';
import styles from './uploadPrints.module.scss';
import InputText from '../../../components/input-text/InputText';
import Button from '../../../components/button/Button';
import ErrorContainer from '../../../components/errorContainer/ErrorContainer';
import printingFinder from '../../../api/printingFinder'
import { useNavigate } from 'react-router-dom';
import depotFinder from '../../../api/depotFinder';
import SettingsPage from '../SettingsPage';

const UploadPrints = () => {
    const navigate = useNavigate();
    const [depots, setDepots] = useState([]);
    const [modifier, setModifier] = useState(false);

    useEffect(()=>{
        const fetchDepots = async ()=>{
            try {
              const response = await depotFinder.get('/');
              setDepots(response.data.data.depots); 
            }
            catch (err) {
              console.log(err);
            }
          }
          fetchDepots()
    },[])

    const errorsHandler= useRef([]);
    const depotRef = useRef();
    const line1Ref = useRef();
    const line2Ref = useRef();
    const line3Ref = useRef();
    const line4Ref = useRef();
    const line5Ref = useRef();

    const checkFields = async ()=>{
        let bool =false;
        const alerts = [];
        const line1 = (document.getElementById("line1").value).trim()
        const line2 = (document.getElementById("line2").value).trim()
        const line3 = (document.getElementById("line3").value).trim()
        const line4 = (document.getElementById("line4").value).trim()
        const line5 = (document.getElementById("line5").value).trim()
        const depot = (document.getElementById("depot").value).trim()

        if(!depot){
            alerts.push({name:'depot',message:'Assurez que le depot est bien saisez'});
            depotRef.current.setBorderRed();
        }else{
            try{
                // const checkDepot = await depotFinder.get(`/exist/${depotRef.current.getSelectedItem().id}/all`);
                // if(checkDepot.data.data.exist){
                //     alerts.push({name:'depot',message:'Ca existe deja une entete pour ce depot'})
                // }
                if(!line1){
                    bool = true;
                    line1Ref.current.setBorderRed();
                }
                if(!line2){
                    bool = true;
                    line2Ref.current.setBorderRed();
                }
                if(!line3){
                    bool = true;
                    line3Ref.current.setBorderRed();
                }
                if(!line4){
                    bool = true;
                    line4Ref.current.setBorderRed();
                }
                if(!line5){
                    bool = true;
                    line5Ref.current.setBorderRed();
                }
                bool && alerts.push({name:'lines',message:'Assurez que les Lines est bien saisez'});
            }catch(err){
                console.log(err)
            }
            
        }
        

        if(alerts.length > 0) {
            errorsHandler.current.errorsHandler(alerts);
            return
        }

        handleSubmit()
    }
    const handleSubmit = async ()=>{
        try {
            console.log(modifier)
          if(!modifier){
              await printingFinder.post("/", {
                line1 : line1Ref.current.getValue(),
                line2: line2Ref.current.getValue(),
                line3 : line3Ref.current.getValue(),
                line4 : line4Ref.current.getValue(),
                line5: line5Ref.current.getValue(),
                depotId: depotRef.current.getSelectedItem().id,
              });
          }else{
            await printingFinder.put(`/${depotRef.current.getSelectedItem().id}`, {
                line1 : line1Ref.current.getValue(),
                line2: line2Ref.current.getValue(),
                line3 : line3Ref.current.getValue(),
                line4 : line4Ref.current.getValue(),
                line5: line5Ref.current.getValue()
              });
          }
    
          navigate(-1)
    
        } catch (error) {
            console.log(error)
        }
    }
    
    const handleEntet = async (depot) => {
        try{
            const header = await printingFinder.get(`/${depot.id}`);
            if(header.data.data.printingHead){
                line1Ref.current.setValue(header.data.data.printingHead.line_1)
                line2Ref.current.setValue(header.data.data.printingHead.line_2)
                line3Ref.current.setValue(header.data.data.printingHead.line_3)
                line4Ref.current.setValue(header.data.data.printingHead.line_4)
                line5Ref.current.setValue(header.data.data.printingHead.line_5)
                setModifier(true)
            }else{
                setModifier(false)
            }
            
        }catch(err){
            console.log(err)
        } 
    }

    return (
        <SettingsPage>
            <div className={styles['body-container']}> 
                <p className={styles['page-title']}>L'entete de L'impression</p>
                <div className={styles['lines-container']}>
                    <div className={styles['line-container']}>
                        <p className={styles['text']}>Depot</p>
                        <InputText  holderText={"Depot"} 
                                    width={"25%"} 
                                    id={'depot'}
                                    searchData={depots}
                                    path={"/depots"}
                                    btnName={'Ajouter Nouveau Depot'} 
                                    ref={depotRef}
                                    onSelectItem={handleEntet}
                                />
                    </div>
                    <div className={styles['line-container']}>
                        <p className={styles['text']}>Line 1</p>
                        <InputText  holderText={"Nom d'Entreprise"} 
                                    width={"50%"} 
                                    id={'line1'}
                                    ref={line1Ref}
                                />
                    </div>
                    <div className={styles['line-container']}>
                        <p className={styles['text']}>Line 2</p>
                        <InputText  holderText={"Activite"} 
                                    width={"50%"} 
                                    id={'line2'}
                                    ref={line2Ref}
                                />
                    </div>
                    <div className={styles['line-container']}>
                        <p className={styles['text']}>Line 3</p>
                        <InputText  holderText={"Addresse"} 
                                    width={"50%"} 
                                    id={'line3'}
                                    ref={line3Ref}
                                />
                    </div>
                    <div className={styles['line-container']}>
                        <p className={styles['text']}>Line 4</p>
                        <InputText  holderText={"Contact"} 
                                    width={"50%"} 
                                    id={'line4'}
                                    ref={line4Ref}
                                />
                    </div>
                    <div className={styles['line-container']}>
                        <p className={styles['text']}>Line 5</p>
                        <InputText  holderText={"Docs"} 
                                    width={"50%"} 
                                    id={'line5'}
                                    ref={line5Ref}
                                />
                    </div>
                </div>
                <div className={styles['button-container']}>
                    <div></div>
                    <Button name={'Confirmer'} width={'150px'} onclick={checkFields}/>
                </div>
            <ErrorContainer ref={errorsHandler}/>
            </div>
        </SettingsPage>
    );
}

export default UploadPrints;

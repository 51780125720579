import React, { useEffect, useRef, useState } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import metaData from './listArticlesReceptionneParTransfere.json'
import { icons } from '../../media/mediaHolder.js'
import { dateFormatter } from '../../utils/dateFormatter.js';
import PageLayout from '../../components/page-layout/PageLayout'
import styles from './listArticleReceptionneeParTransfert.module.scss'
import articlesReceptionTransfereFinder from '../../api/articlesReceptionTransfereFinder'

const ListArticleReceptionneeParTransfert = () => {  
  
  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  let [data, setData] = useState([]);


  useEffect(()=>{
    const getArticlesTransfere = async ()=>{
      try{
        const response = await articlesReceptionTransfereFinder.get("/");
        
        let articles = []
        for(let i=0;i<response.data.data.articles.length;i++){
            articles.push(response.data.data.articles[i]);
            
          }

        articles = articles.sort((a, b) => {
          return new Date(b.date) - new Date(a.date); // Sort based on date 
        });
        
        setData(articles.map((article)=>{
          return{
            id: article.id,
            code: article.code,
            date: dateFormatter(article.date,3),
            designation: article.designation,
            quantity: article.quantity,
            prixAchat: article.prix_achat,
            total: article.quantity * article.prix_achat
          }
        }))
      }catch(err){
        console.log(err)
      }
      
    }
  getArticlesTransfere();
},[])


  const tableRef = useRef();
  const filterByArr = ['designation'];
  const handleSearch = (val) => tableRef.current.setFilterInput(val)
                
  return (
        <PageLayout icon={icons.article} nameLayout={'List Des Article Rèceptionnèe par Transfert'}>
        <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
          <div className={styles['main-search-bar']}>
            <InputText width={'50%'}
                        labelPosition={true} holderText={"Chercher Par Designation"}  label={'Chercher'}
                        margin={'0px 0px 0px 15px'}
                        icon={icons.search}
                        reportChange={(val) => handleSearch(val)}
            />
            <div className={styles['operations-buttons']}>
                <Button name={'Imprimer'} icon={icons.impr} width={"250px"}/>
            </div>
          </div>
          <div className={styles['periode-input-container']}>
            <div className={styles['periode']}>
                <InputText 
                  width={'max-content'} 
                  type={"date"}  
                  label={'Periode du'}
                  margin={'0px 0px 0px 15px'}
                  reportChange={reportDate1Change}/>
                <InputText 
                  width={'max-content'} 
                  type={"date"}  
                  label={'Au'}
                  margin={'0px 0px 0px 15px'}
                  reportChange={reportDate2Change}/>
            </div>
            <div className={styles['input']}>
                <div></div>
            <InputText 
              width={'40%'} 
              holderText={"chercher"}  
              label={'Chercher'} 
              margin={"0px 15px 0px 0px"}/>
            </div>
          </div>
          <div className={styles['table']}>
            <Table thead={metaData.table} 
                    tbody={data} 
                    defaultSearchBy={filterByArr}
                    ref={tableRef}
                    edit={true} remove={true} />
          </div>
        </div>
        </PageLayout>
  )
}

export default ListArticleReceptionneeParTransfert
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import styles from './bonAvoirVente.module.scss'
import InputText from '../../components/input-text/InputText'
import SubInfo from '../../components/sub-info/SubInfo'
import Button from '../../components/button/Button'
import PageSideBar from '../../components/page-side-bar/PageSideBar'
import SelectInput from '../../components/select-input/SelectInput'
import Table from '../../components/table/Table'
import Dialog from '../../components/dialog/Dialog';
import AdderTable from '../../components/adder-table/adderTable';
import clientsFinder from '../../api/clientsFinder';
import articlesFinder from '../../api/articlesFinder';
import bonAvoirFinder from '../../api/bonAvoirFinder';
import articlesAvoirFinder from '../../api/articlesAvoirFinder';
import ErrorContainer from '../../components/errorContainer/ErrorContainer';
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton';
import reglementsClientFinder from '../../api/reglementsClientFinder';
import articleLivreFinder from '../../api/articleLivreFinder';
import depotFinder from '../../api/depotFinder';
import metaData from './metaData.json';
import depotsStockFinder from '../../api/depotsStockFinder';
import ValidatedMessages from '../../components/validated-messages/ValidatedMessages';

const BonAvoirVente = () => {
  const navigate = useNavigate();

  const [date, setDate] = useState(new Date());
  const option = useRef();
  const articlesRef = useRef();
  const [ clientList, setClientList ] = useState([]);
  const [selectedArticlesData, setSelectedArticlesData] = useState([]);
  
  
  const total = useRef();
  const totalSansRM = useRef();
  const displayDialog = useRef([]);
  const articleTable = useRef([]);
  const getSelectedClients = useRef();
  const codeInput = useRef(null);
  const dateInput = useRef(null);
  const tableRef = useRef(null);

  const handelSubmit = async (e) =>{
    e.preventDefault()
    setDate(document.getElementById("date").value);
    try {
        const depotId = parseInt(depot.current.getSelectedItem().id)

        const articles = tableRef.current.getAllData().map( article => {
          return {
            // id_bonAvoir: response.data.data.bon.id,  
            id_article: article.id,
            id_depot: depotId,
            quantity: parseInt(article.quantityart),
            prix_achat: parseInt(article.puDetail),
            remise: parseInt(article.remise),
            lot_nbr: article.lot_nbr,
            observation:article.observation,
            operation: 'switch'
          }
        });
        
        await bonAvoirFinder.post("/",{
          id_cf: getSelectedClients.current.getSelectedItem().id,
          id_depot: depotId,
          code: document.getElementById("code").value,
          code_bon: parseInt(document.getElementById("bon").value),
          type: "vente",
          payment_type: option.current.getSelectedOption(),
          remise: parseInt(document.getElementById("remise").value),
          date: date,
          articles:articles
        });

        validateHandler.current.validateHandler([{name: 'validate' , message: 'Bon est bien Valider'}]);
        setTimeout(()=> {
          navigate(-1)
        }, 1000);

    } catch (err) {
        console.log(err)
    }
  } 
  const validateHandler = useRef([]);

  const options =[
    "Espece",
    "Cheque",
    "Verement",
    "Autre"
  ];

  useEffect(()=>{
    dateInput.current.setValue(date.toISOString().substring(0, 10));

    const fetchClients = async () => {
      const response = await clientsFinder.get('/');
      setClientList(response.data.data.clients);
    }
    const getArticles = async ()=>{
       const response = await articlesFinder.get("/");
       const filtredArticles = response.data.data.articles.filter((article)=>{
        return article.type === 'matierefini'
      }).map(({ type, ...rest }) => rest);
       articlesRef.current = filtredArticles;
       articleTable.current.setAllData(filtredArticles);
    }
    const fetchBonAvoir = async () => {
      const response = await bonAvoirFinder.get('/vente');
      let code=1;
      if(response.data.data.code.max){
        code = parseInt(response.data.data.code.max) + 1
      }
      codeInput.current.setValue(code);
    }
    const fetchDepots = async ()=>{
      try {
        const response = await depotFinder.get('/');
        setDepots(response.data.data.depots); 
      }
      catch (err) {
        console.log(err);
      }
    }

    fetchDepots();
    fetchBonAvoir();
    fetchClients();
    getArticles();
  },[]);

  const handleDelete = (itemToDeleteId) => {
    tableRef.current.setAllData(tableRef.current.getAllData().filter(item => item.id !== itemToDeleteId));
    articleTable.current.addItem(articlesRef.current.find(item => item.id === itemToDeleteId));
  }
  
  const addSelectedArticles = async () => {
    const depotId = parseInt(depot.current.getSelectedItem().id)
    const articles = [];
    let quantityDepot;
    const data = [...tableRef.current.getAllData(),...articleTable.current.sendSelectedItems()];
    for(let i=0;i<data.length;i++){
      let article = data[i];
      depotId && (quantityDepot = await depotsStockFinder.get(`/${parseInt(depotId)}/${parseInt(article.id)}`))
      const quantityD = depotId && quantityDepot.data.data?.quantity
      articles.push({
        id: article.id,
        codeArticle: article.codeArticle, 
        designation: article.designation,
        quantityart: article.quantityart || 0,
        puDetail: article.puDetail,
        remise: article.remise || 0,
        lot_nbr: article.lot_nbr || "",
        total: (article.puDetail * article.quantityart - ((article.puDetail * article.quantityart)*article.remise)/100) || 0,
        quantity: article.quantity,
        quantiteLocal: quantityD || 0,
        observation: article.observation || ''
      })
    }
    setSelectedArticlesData(articles)
  }

  const checkCode = async (value)=>{
    const response = await bonAvoirFinder.get(`/${value}/vente`)
    if(response.data.data[0]?.code == value){
      return true
    }else
      return false
  }

  const [depots, setDepots] = useState([]);

  const depot = useRef(null);
  const errorsHandler= useRef([]);
  const displayConfirmationDialog = useRef();
  const remiseR = useRef();
  const checkErrs = async ()=>{
    let alerts = [];
    let code = document.getElementById("code").value;
    let client = document.getElementById("Client").value;
    let id_client = client && getSelectedClients.current.getSelectedItem().id;
    let remise = document.getElementById("remise").value
    const depots = document.getElementById("depot").value
    
    if(!remise){
      alerts.push({name:'remise',message:'Assurez que la remise est bien saisez'});
      remiseR.current.setBorderRed();
    }
    if(!code){
      alerts.push({name:'code',message:'Assurez que la code is bien saisez'});
      codeInput.current.setBorderRed();
    }
    if( await checkCode(code) === true){
      alerts.push({name:'code',message:'le N° du bon est deja exister'});
      codeInput.current.setBorderRed();
    }
    if(!id_client){
      alerts.push({name:'client' ,message:'Assurez que la client est bien selectionez'});
      getSelectedClients.current.setBorderRed();
    }
    if(!depots){
      alerts.push({name:'depot' ,message:'Assurez que le Depot est bien selectionez'});
      depot.current.setBorderRed();
    }
    if(selectedArticlesData.length === 0){
      alerts.push({name:'articles' ,message:'Assurez que la Articles est bien selectionez'});
    }
    if(alerts.length > 0) {
      errorsHandler.current.errorsHandler(alerts);
      return
    }
    handelValidate();
  }

  const handelValidate= () => {
    displayConfirmationDialog.current.display();
  }

  const soldeClient = useRef();
  const handleClient = async (client)=>{
      // const response = await reglementsClientFinder.get(`/${client.id}`);
      // const bonsVentesF = await articleLivreFinder.get(`/${client.id}/reglementClient`)

      // let Montant = 0;
      // response.data.data.reglementsClient.map((versement)=>{
      //   Montant += versement.montant;
      // })
      // soldeClient.current.setValue(String(Math.abs(bonsVentesF.data.data.amount - Montant)))
  }

  const handleDepot = async (depotId)=>{
    const articles = [];
    let quantityDepot;
    const data = tableRef.current.getAllData()
    for(let i=0;i<data.length;i++){
      let article = data[i];
      quantityDepot = await depotsStockFinder.get(`/${parseInt(depotId.id)}/${parseInt(article.id)}`)
      const quantityD = quantityDepot.data.data?.quantity 
      articles.push({
        id: article.id,
        codeArticle: article.codeArticle,
        designation: article.designation,
        quantityart: article.quantityart,
        puDetail: article.puDetail,
        remise: article.remise,
        lot_nbr: article.lot_nbr,
        total: article.total,
        quantity: article.quantity,
        quantiteLocal: quantityD || 0,
        observation: article.observation
      })
    }
      tableRef.current.setAllData(articles);
  }

  const actOnValidation = async (article) => {

    tableRef.current.updateRow({
      id: article.id,
      codeArticle: article.codeArticle,
      designation: article.designation,
      quantityart: article.quantityart || 0,
      puDetail: article.puDetail || 0,
      remise: article.remise || 0,
      lot_nbr: article.lot_nbr || "",
      total: (article.puDetail * article.quantityart - ((article.puDetail * article.quantityart) * article.remise)/100) || 0,
      quantity: article.quantity,
      quantiteLocal: article.quantiteLocal,
      observation: article.observation
    });
    calculateTotal()
  }

  const calculateTotal = (data) => {
    const articles = tableRef.current.getAllData();
    // Calculate the total before discounts
    const amountBeforeDiscounts = articles.reduce((accumulator, article) => accumulator += article.puDetail * article.quantityart, 0);
    totalSansRM.current.setValue((amountBeforeDiscounts));

    // Calculate all the discounts
    const articlesDiscountsTotal = articles.reduce((accumulator, article) => {
      const puRemise = (article.puDetail - (article.puDetail * (article.remise / 100)));
      return accumulator += (((article.puDetail - puRemise) * article.quantityart));
    }, 0);


    // Display the final amount
    total.current.setValue((amountBeforeDiscounts - articlesDiscountsTotal));

    updateRMInput(articles)
  }

  const updateTaxes = (rm)=>{
    const remise = parseInt(rm) || 0;
    
    const articles = tableRef.current.getAllData().map((article)=>{
      article.remise = remise;
      article.total = (article.puDetail * article.quantityart - ((article.puDetail * article.quantityart)*article.remise)/100)
    })
    
    tableRef.current.updateRow({...articles});
    
    calculateTotal()
  }

  const updateRMInput = (articles)=>{
    const rm = articles[0]?.remise;
    for(let i=1;i<articles.length;i++){
      if(rm !== articles[i]?.remise) {
        remiseR.current.setValue("0")
      }
    }
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={"Bon de Retour"}>
    <form onSubmit={handelSubmit}>
    <div className={styles['body-container']}>
     <div className={styles['main-body']}>
      <div className={styles['actions-container']}>
        <div className={styles['row-evenly']}>
          <InputText
            holderText={"Ajouter client"} 
            width={"50%"} 
            labelPosition={true}
            type={"text"}
            label={'Client'}
            id={'Client'}
            searchData={clientList}
            onSelectItem={handleClient}
            path={"/ajouter_client"}
            btnName={'Ajouter Nouveau client'} 
            ref={getSelectedClients}
          />
          <SubInfo
            lined={true}
            label={"Sold Client :"}
            unit={"DZD"}
            ref={soldeClient}
          />
          <Button 
            name={'Reglement Client'} 
            path={"../reglement_client"} 
            width={"300px"} 
            icon={icons.reglementClient}
          />
        </div>

        <div className={styles['row-evenly']}>
            <Button 
              name={'Ajouter Article'} 
              width={"200px"}
              onclick={()=>displayDialog.current.display()} 
            />
            <InputText
              holderText={"Code Bon Vente"} 
              width={"295px"}
              type={"text"}
              id={'bon'}
            />
        </div>

        <div style={{height: "437px"}}>
          <Table 
            thead={metaData.table} 
            tbody={selectedArticlesData}
            actOnValidation={actOnValidation}
            edit={true} 
            remove={true}
            confirmBeforeDeletion={false}
            handleDelete={handleDelete}
            ref={tableRef}
          />
        </div>
      </div>
    </div>

    <div className={styles['side-body']}>
    <PageSideBar validateFunc={checkErrs}>
            <div className={styles['col-around']}>
              <div className={styles['col-evenly']}>
                <InputText 
                  id={"code"}
                  label={"N° du bon"} 
                  holderText={"0001"} 
                  width={"100%"} 
                  type={"number"}
                  ref={codeInput}
                  disabled={true}
                />
                <InputText 
                  id={"date"}
                  label={"Date de bon"}  
                  width={"100%"} 
                  type={"date"}
                  ref={dateInput}
                />
                <SelectInput 
                  options={options} 
                  label={"Type de paiement"} 
                  width={"100%"}
                  ref={option}
                />
                <InputText label={"Depot"} 
                  width={"100%"}
                  holderText={'Depot'}
                  id={'depot'}
                  onSelectItem={handleDepot}
                  searchData={depots}
                  ref={depot}/>
              </div>

              <div className={styles['col-evenly']} >
                <SubInfo 
                  label={"Total sans remise"} 
                  unit={"DZD"}
                  ref={totalSansRM}
                />
                <InputText
                  id={"remise"} 
                  label={"Remise"} 
                  currency={true} 
                  currencyValue={"%"} 
                  width={"100%"} 
                  defaultValue={'0'}
                  type={"number"} 
                  holderText={"0.00"}
                  ref={remiseR}
                  reportChange={updateTaxes}
                />
                <SubInfo 
                  label={"Total"} 
                  unit={"DZD"}
                  ref={total}
                />
              </div>
            </div>
          </PageSideBar>  
    </div>
    <Dialog width={"80%"} table={true} ref={displayDialog} validateFunc={addSelectedArticles}>
      <AdderTable head={metaData.adderTable} ref={articleTable} />
    </Dialog>

    </div>
    <ConfirmationButton ref={displayConfirmationDialog}/>
    </form>
    <ErrorContainer ref={errorsHandler}/>
    <ValidatedMessages ref={validateHandler}/>
   </PageLayout>
  )
}

export default BonAvoirVente

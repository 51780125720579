import React, { useEffect, useState, useRef } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import styles from './livreDesVente.module.scss'
import { icons } from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import SelectInput from '../../components/select-input/SelectInput'
import bonVentesFinder from '../../api/bonVentesFinder'
import bonsAvoirFinder from '../../api/bonAvoirFinder'
import metaData from './LivreDeVente.json'
import { useAuth } from '../../contexts/AuthContext.js'
import permissions from '../../utils/permissions.js'
import { dateFormatter } from '../../utils/dateFormatter.js'
import ErrorContainer from '../../components/errorContainer/ErrorContainer.js'
import Printing from '../../components/printing/Printing.js'

const LivreDesVentes = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const options =["Livre Des Vente Global","Cheque"];

  const [inputValue, setInputValue] = useState('');
  const [data,setData] = useState([]);

  const tableData = useRef();
  const tableRef = useRef();
  const date1 = useRef();
  const date2 = useRef();
  const reportDate1Change = (val) => {
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!val || itemDate >= new Date(val)) &&
        (!date2.current.getValue() || itemDate <= new Date(date2.current.getValue()))
      );
    }));
  }
  const reportDate2Change = (val) =>{
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!date1.current.getValue() || itemDate >= new Date(date1.current.getValue())) &&
        (!val || itemDate <= new Date(val))
      );
    }))
  }

  const totalVente = useRef();
  const totalRetour = useRef();
  const totalComptoir = useRef();
  const globalAmount = useRef();


  useEffect(() => {
    let montantVentes = 0.0;
    let montantRetour = 0.0;
    const fetchBonsVenteAvoir = async () => {
      const bonsVentesRes = await bonVentesFinder.get('/');
      const bonsAvoirRes = await bonsAvoirFinder.get('/vente');

      const { max_id } = bonsVentesRes.data.data;

      const bonsVente = bonsVentesRes.data.data.bons.map((bon)=>{
        
        montantVentes += bon.amount; 

        return{
              id : bon.id,
              bon: bon.code,
              date: dateFormatter(bon.date,3),
              Vente_av: 'Vente',
              montant:bon.amount
            }
      });

      const bonsAvoir = bonsAvoirRes.data.data.bons.map((bon)=>{
        
        let id = parseInt(bon.id) + parseInt(max_id);
        montantRetour += bon.amount;

        return{
          id,
          bon: bon.code,
          date: bon.date.substring(0,10),
          Vente_av: 'Avoir',
          montant:bon.amount
        }
      });

      totalVente.current?.setValue(montantVentes);
      totalRetour.current?.setValue(montantRetour);  
      totalComptoir.current?.setValue('0');
      globalAmount.current?.setValue(montantVentes - montantRetour);
      setData([...bonsVente,...bonsAvoir]);
      tableData.current = [...bonsVente,...bonsAvoir];
    }
    fetchBonsVenteAvoir();
    }, []);

    const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);
  const printRef = useRef()
  const achatAvoir = useRef()

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
  
    setIsActive(true);
    achatAvoir.current = "Livre des Vente"
    const articlesAdded = tableRef.current.getSelectedItems().map((etatBon)=>{
      return{
        date: etatBon.date,
        venteAvoir: etatBon.Vente_av,
        montant: etatBon.montant,
      }
    });
    const ROWS_PER_PAGE = 25; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages,
      globalInfo: {
        totalDesAchat: totalVente.current.getValue(),
        totalAvoirAchat: totalRetour.current.getValue(),
        total: globalAmount.current.getValue(),
        periodeDu: date1.current.getValue(),
        au: date2.current.getValue()
      }
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print();
    }, 100);
    
  }
  const PrintingColumns = [
    {name: "Date",width: "10%"},
    {name: "Vente/Av",width: "10%"},
    {name: "Montant",width:"10%"}
  ]
            
  return (
    <PageLayout icon={icons.article} nameLayout={'Livre Des Ventes'}>
      <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
        <div className={styles['main-search-bar']}>
          <div className={styles['periode']}>
            <InputText 
              width={'20%'} 
              type={"date"}  
              label={'Periode du'}
              margin={'0px 0px 0px 15px'}
              labelPosition={true}
              ref={date1}
              reportChange={reportDate1Change}
              />
            <InputText 
              width={'20%'} 
              type={"date"}  
              label={'Au'}
              margin={'0px 0px 0px 15px'} 
              labelPosition={true}
              ref={date2}
              reportChange={reportDate2Change} 
              />
          </div>
          <div className={styles['operations-buttons']}>
            <Button name={'Imprimer'} icon={icons.impr} width={"250px"} onclick={handlePrint} />
          </div>
        </div>
        <div className={styles['select-input']}>
          <SelectInput options={options} width={"35%"}/>
        </div>
        <div className={styles['table']}>
          <Table 
            thead={metaData.table} 
            tbody={data} 
            ref={tableRef}
          />
        </div>
        <div className={styles['mantant-chargers']}>
          <div>
            <InputText 
              width={'100%'} 
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Des Vente'}
              margin={'0px 15px 0px 15px'}
              ref={totalVente}
              />
          </div>
          <div>
            <InputText 
              width={'100%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Des Retours'}
              margin={'0px 15px 0px 0px'}
              ref={totalRetour}
              />
          </div>
          <div>
            <InputText 
              width={'100%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Des Comptoirs'}
              margin={'0px 15px 0px 0px'}
              ref={totalComptoir}
              />
          </div>
          <div style={{display: "flex"}}>
            <InputText 
              width={'100%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Global'}
              margin={'0px 15px 0px 0px'}
              ref={globalAmount}
              />
          </div>
        </div>  
      </div>
      {isActive && <Printing type={achatAvoir.current} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
      <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default LivreDesVentes

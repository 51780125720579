import React, { useEffect, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './etatAchatGlobal.module.scss'
import Button from '../../components/button/Button'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import metaData from './etatAchatGlobal.json';
import permissions from '../../utils/permissions'
import { useAuth } from '../../contexts/AuthContext'
import Printing from '../../components/printing/Printing'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import { dateFormatter } from '../../utils/dateFormatter'

const EtatAchatGlobal = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data,setData] = useState([]);
  
 
  const tableRef = useRef();
  const tableData = useRef();
  const date1 = useRef();
  const date2 = useRef();
  const reportDate1Change = (val) => {
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!val || itemDate >= new Date(val)) &&
        (!date2.current.getValue() || itemDate <= new Date(date2.current.getValue()))
      );
    }));
  }
  const reportDate2Change = (val) =>{
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!date1.current.getValue() || itemDate >= new Date(date1.current.getValue())) &&
        (!val || itemDate <= new Date(val))
      );
    }))
  }

  const totalAchat = useRef();
  const totalRetour = useRef();
  const globalAmount = useRef();
  
  useEffect(() => {
    let montantAchat = .0;
    let montantRetour = .0;

    const fetchArticlesAcheteAvoir = async () =>{
      try {
        const articlesAcheteRes = await articlesAcheteFinder.get('/');
        const articlesAvoirRes = await articlesAvoirFinder.get('/achat');
        const { max_id } = articlesAcheteRes.data.data;
        
        const articlesAchete = articlesAcheteRes.data.data.articles.map((article)=>{
          console.log(article)
          // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING THE ARTICLE PROMOTION
          let montant = (article.quantity * article.prix) * (1 - (article.remise/100));
          // 
          montantAchat += montant;
          // 
          return{
                id: article.id,
                achat_avoir: 'Achat',
                lot:article.lotNbr,
                codeArt: article.code,
                code: article.bon_achat_code,
                date: dateFormatter(article.bon_achat_date,3),
                disgnation: article.designation,
                qtyEnStk: article.ds_quantity,
                qtyAchete: article.quantity,
                prix: article.prix,
                montant,
                codeFournisseur: article.fournisseur_code,
                Fournisseur: article.fournisseur_name,
                observation: article.observation
              }
          }
        );

        const articlesAvoir = articlesAvoirRes.data.data.articles?.map((article)=>{
          // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING THE ARTICLE PROMOTION
          let montant = (article.quantity * article.prix_achat) * (1 - (article.remise/100));
          // 
          montantRetour += montant;
          // 
          let id = parseInt(article.id) + parseInt(max_id);
          return{
            id,
            achat_avoir: 'Avoir',
            lot:article.lotNbr,
            codeArt: article.code,
            code: article.bon_avoir_code,
            date: dateFormatter(article.bon_avoir_date,3),
            disgnation: article.designation,
            qtyEnStk: article.ds_quantity,
            qtyAchetee: article.quantity,
            prix: article.prix_achat,
            montant,
            codeFournisseur: article.fournisseur_code,
            Fournisseur: article.fournisseur_name,
            observation: article.observation
          }
        });
        setData([...articlesAvoir,...articlesAchete]);

        tableData.current = [...articlesAvoir,...articlesAchete];

        totalAchat.current?.setValue(montantAchat.toFixed(3));
        totalRetour.current?.setValue(montantRetour.toFixed(3));
        globalAmount.current?.setValue((montantAchat - montantRetour).toFixed(3));
      } catch (err) {
        console.log(err);
      }
    }
    fetchArticlesAcheteAvoir();
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);
  const printRef = useRef()
  const achatAvoir = useRef()

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
  
    setIsActive(true);
    achatAvoir.current = "Etat des Achat (Global)"
    const articlesAdded = tableRef.current.getSelectedItems().map((etatBon)=>{
      return{
        codeFournisseur: etatBon.codeFournisseur,
        Fournisseur: etatBon.Fournisseur,
        code: etatBon.code,
        achatAvoir: etatBon.achat_avoir,
        date: etatBon.date,
        montant: etatBon.montant,
      }
    });
    const ROWS_PER_PAGE = 25; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages,
      globalInfo: {
        totalDesAchat: totalAchat.current.getValue(),
        totalAvoirAchat: totalRetour.current.getValue(),
        total: globalAmount.current.getValue(),
        periodeDu: date1.current.getValue(),
        au: date2.current.getValue()
      }
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print();
    }, 100);
    
  }
  const PrintingColumns = [
    {name: "Code F",width: "5%"},
    {name: "Fournisseur",width: "25%"},
    {name: "N°Bon",width: "5%"},
    {name: "Achat/Av",width: "10%"},
    {name: "Date",width: "10%"},
    {name: "Montant",width:"10%"}
  ]

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Achats Global'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <div className={styles['row']}>
              <InputText label={"Periode du"} 
                labelPosition={true}
                width={"max-content"} 
                type={"date"}
                ref={date1}
                reportChange={reportDate1Change}
                />
              <InputText label={"Au"} 
                labelPosition={true}
                width={"max-content"} 
                type={"date"}
                ref={date2}
                reportChange={reportDate2Change}
                />
            </div>
            <div className={styles['row-end']}>
              <Button 
              icon={icons.impr}
              width={"250px"}
              name={'Imprimer'} 
              onclick={handlePrint}
                />
            </div>
          </div>
          <div style={{height: "350px"}}>
            <Table 
              thead={metaData.table} 
              tbody={data}
              ref={tableRef}
              />
          </div>
          <div className={styles['row-between']} style={{width:'100%'}}>
            <InputText  
              holderText={"Montant"}
              type={"number"} 
              label={'Montant des Achats'}
              ref={totalAchat}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              type={"number"} 
              label={'Montant des Retours'}
              ref={totalRetour}
              disabled
              />
            <InputText  
              holderText={"Montant"}
              type={"number"} 
              label={'Montant Global'}
              ref={globalAmount}
              disabled
              />
          </div>
        </div>
      </div>
    {isActive && <Printing type={achatAvoir.current} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default EtatAchatGlobal;
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import style from './printing.module.scss';
import { useReactToPrint } from 'react-to-print';
import NumberConverter from '../number-converter/NumberConverter';
import PrintingFacture from '../printing-facture/PrintingFacture';
import PrintingProduction from '../printing-production/PrintingProduction';
import PrintingVentes from '../printing-ventes/PrintingVentes';
import PrintingFactureProforma from '../printing-facture-proforma/PrintingFactureProforma';
import PrintingCommande from '../printing-commande/PrintingCommande';
import PrintingAchat from '../printing-achat/PrintingAchat';
import PrintingTransfer from '../printing-transfer/PrintingTransfer';
import PrintingReceptionTransfer from '../printing-reception-transfer/PrintingReceptionTransfer';
import PrintingArticles from '../printing-articles/PrintingArticles';
import PrintingFournisseur from '../printing-fournisseur/PrintingFournisseur';
import PrintingClient from '../printing-client/PrintingClient';
import printingFinder from '../../api/printingFinder';
import PrintingEtatAchat from '../printing-etat-achat/PrintingEtatAchat';
import PrintingEtatAchatFournisseur from '../printing-etat-achat-fournisseur/PrintingEtatAchatFournisseur';
import PrintingLivreAchat from '../printing-livre-achat/PrintingLivreAchat';
import PrintingEtatVente from '../printing-etat-vente/PrintingEtatVente';
import PrintingEtatAchatClient from '../printing-etat-achat-client/PrintingEtatAchatClient';

const Printing = forwardRef( (props, ref) => {

  const { dataInfo, thead, type } = props;

  const [ showPrintableTable, setShowPrintableTable ] = useState('printing-container-hide');

  const header = useRef();
  const componentRef = useRef();
  const timeoutRef = useRef();

  useEffect(() => {}, [dataInfo.articlesAdded])

  const print = useReactToPrint({
    content: ()=> componentRef.current
  });

  const handlePrinting = () => {
    show();
    
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      print();
      hide();
    }, 100);
  }

  const show = () => setShowPrintableTable('printing-container');
  const hide = () => setShowPrintableTable('printing-container-hide');

  const onBeforePrint = async () => {
    try {
      const printingHead = await printingFinder.get(`/mainDepot/all`);
      header.current = printingHead.data.data.printingHead;
      handlePrinting();
    }
    catch (err) {
      console.error('Could not fetch printing head on Printing component');
    }
  }

  useImperativeHandle(ref, () => ({
    print: () => onBeforePrint(),
  }));

  return (
    <div ref={componentRef} className={style[`${showPrintableTable}`]}>
      {
        dataInfo.articlesAdded.map((item, index) => {
          const updatedItem = dataInfo?.saisiPar ? {
            rowInfo: dataInfo.rowInfo,
            articlesAdded: item,
            saisiPar: dataInfo?.saisiPar,
            globalInfo:  dataInfo?.globalInfo
          }
          : {
            rowInfo: dataInfo.rowInfo,
            articlesAdded: item,
            validerPar: dataInfo?.validerPar,
            globalInfo:  dataInfo?.globalInfo
          }

          return (
            <div key={index}>
              {type === "List des Articles" && <PrintingArticles pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={item} printingHeader={header.current}/>}
              {type === 'Bon De Livraison' && <PrintingVentes pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem} globalInfo={dataInfo.globalInfo} printingHeader={header.current}/>}
              {(type === 'Matiere Premiere' ||type === 'Produit Fini'||type === 'Produit Semi Fini')&& <PrintingProduction pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem} printingHeader={header.current}/>}
              {type === 'Facture Proforma' && <PrintingFactureProforma pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem} globalInfo={dataInfo.globalInfo} printingHeader={header.current}/>}
              {type === 'Bon De Commande' && <PrintingCommande pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem} printingHeader={header.current}/>}
              {(type === "Bon D'Achat" ||type === "Bon D'Retour") && <PrintingAchat pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem} printingHeader={header.current}/>}
              {type === "Bon De Transfer" && <PrintingTransfer pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem} printingHeader={header.current}/>}
              {type === "Bon Reception De Transfer" && <PrintingReceptionTransfer pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem} printingHeader={header.current}/>}
              {type === "List des Fournisseur" && <PrintingFournisseur pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={item} printingHeader={header.current}/>}
              {(type === 'Facture' || type === 'Avoir') &&<PrintingFacture pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem} printingHeader={header.current}/>}
              {type === "List des Clients" && <PrintingClient pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={item} printingHeader={header.current}/>}
              {type === "Etat des Achat (Global)" && <PrintingEtatAchat pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem}/>}
              {type === "Etat des Vente (Global)" && <PrintingEtatVente pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem}/>}
              {(type === "Livre des Achat" || type === "Livre des Vente") && <PrintingLivreAchat pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem}/>}
              {type === "Etat des Achat Par Fournisseur (Global)" && <PrintingEtatAchatFournisseur pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem}/>}
              {type === "Etat des Vente Par Client (Global)" && <PrintingEtatAchatClient pageNumbers={dataInfo.articlesAdded.length} pageNumber={index+1} type={type} thead={thead} dataInfo={updatedItem}/>}
              <div className={style['page-break']} />
            </div>
          );
        })
      }
    </div>
  )
})

export default Printing;

import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import styles from './bonAvoirFacture.module.scss';
import InputText from '../../components/input-text/InputText';
import SubInfo from '../../components/sub-info/SubInfo';
import Button from '../../components/button/Button';
import PageSideBar from '../../components/page-side-bar/PageSideBar';
import SelectInput from '../../components/select-input/SelectInput';
import Table from '../../components/table/Table';
import bonAvoirFinder from '../../api/bonAvoirFinder';
import Dialog from '../../components/dialog/Dialog';
import AdderTable from '../../components/adder-table/adderTable';
import clientsFinder from '../../api/clientsFinder';
import articlesAvoirFinder from '../../api/articlesAvoirFinder';
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton';
import ErrorContainer from '../../components/errorContainer/ErrorContainer';
import metaData from './bonAvoirFacture.json';
import depotFinder from '../../api/depotFinder';
import depotsStockFinder from '../../api/depotsStockFinder';
import articlesFinder from '../../api/articlesFinder';
import { useAuth } from '../../contexts/AuthContext';
import Printing from '../../components/printing/Printing';
import ValidatedMessages from '../../components/validated-messages/ValidatedMessages';

const BonAvoirFacture = () => {
  const navigate = useNavigate();
  const { state: authState } = useAuth();

  const [date, setDate] = useState(new Date());
  const [articlesData, setArticlesData] = useState([]);
  const [ clientList, setClientsList ] = useState([]);
  const [selectedArticlesData, setSelectedArticlesData] = useState([]);

  const displayDialog = useRef([]);
  const articleTable = useRef([]);
  const getSelectedClient = useRef();
  const codeInput = useRef(null);
  const dateInput = useRef(null);
  const tableRef = useRef(null);
  const paymentTypeInput = useRef(null);
  const totalSansRM = useRef();
  const articlesRef = useRef();
  const total = useRef();

  const handleSubmit = async (e) =>{
    e.preventDefault();
    const depotId = parseInt(depot.current.getSelectedItem().id)
    setDate(document.getElementById("date").value);
    try {
      const articles = tableRef.current.getAllData().map(article => {
        return {
          lot_nbr: article.lot_nbr,
          id_article: article.id, 
          id_depot: depotId,
          quantity: parseInt(article.quantityart), 
          prix_achat: parseInt(article.puDetail), 
          remise: parseInt(article.remise),
          observation:article.observation,
          operation: 'switch'
          }
      });

      await bonAvoirFinder.post("/",
      {
        id_cf: getSelectedClient.current.getSelectedItem().id,
        id_depot: depotId,
        code: document.getElementById("code").value,
        type: "facture",
        code_bon: parseInt(document.getElementById("bon").value),
        payment_type:  paymentTypeInput.current.getSelectedOption(),
        remise: parseInt(document.getElementById("remise").value),
        date: date,
        articles:articles
      });
      validateHandler.current.validateHandler([{name: 'validate' , message: 'Bon est bien Valider'}]);
      setTimeout(()=> {
        navigate(-1)
      }, 1000);
    } catch (err) {
        console.log(err)
    }
  } 
  const validateHandler = useRef([]);

  
  useEffect(()=>{
    dateInput.current.setValue(date.toISOString().substring(0, 10));
    
    const fetchClients = async () => {
      const response = await clientsFinder.get('/');
      setClientsList(response.data.data.clients);
    }

    const fetchDepots = async ()=>{
      try {
        const response = await depotFinder.get('/');
        setDepots(response.data.data.depots); 
      }
      catch (err) {
        console.log(err);
      }
    }
    
    const getArticles = async ()=>{
      const response = await articlesFinder.get("/");
      const filtredArticles = response.data.data.articles.filter((article)=>{
        return article.type === 'matierefini'
      }).map(({ type, ...rest }) => rest);
      articlesRef.current = filtredArticles;
      articleTable.current.setAllData(filtredArticles);
    }
    const fetchBonAvoir = async () => {

      const response = await bonAvoirFinder.get('/facture');
       let code=1;
      if(response.data.data.code.max){
        code = parseInt(response.data.data.code.max) + 1

      }
      codeInput.current.setValue(code);
    }
    
    fetchDepots()
    fetchBonAvoir();
    fetchClients();
    getArticles();
  },[]);

  const addSelectedArticles = async () => {
    const depotId = parseInt(depot.current.getSelectedItem().id)
    const articles = [];
    let quantityDepot;
    const data = [...tableRef.current.getAllData(),...articleTable.current.sendSelectedItems()];
    for(let i=0;i<data.length;i++){
      let article = data[i];
      depotId && (quantityDepot = await depotsStockFinder.get(`/${parseInt(depotId)}/${parseInt(article.id)}`))
      const quantityD = depotId && quantityDepot.data.data?.quantity
      articles.push({
        id: article.id,
        codeArticle: article.codeArticle, 
        designation: article.designation,
        quantityart: article.quantityart || 0,
        puDetail: article.puDetail || 0,
        remise: article.remise || 0,
        lot_nbr: article.lot_nbr || "",
        total: (article.puDetail * article.quantityart - ((article.puDetail * article.quantityart)*article.remise)/100) || 0,
        quantity: article.quantity,
        quantiteLocal: quantityD || 0,
        observation: article.observation || ''
      })
    }
    setSelectedArticlesData(articles)
  }

  const actOnValidation = async (obj) => {

    tableRef.current.updateRow({
      id: obj.id,
      codeArticle: obj.codeArticle,
      designation: obj.designation,
      quantityart: obj.quantityart || 0,
      puDetail: obj.puDetail || 0,
      remise: obj.remise || 0,
      lot_nbr: obj.lot_nbr,
      total: (obj.puDetail * obj.quantityart - ((obj.puDetail * obj.quantityart)*obj.remise)/100),
      quantity: obj.quantity,
      quantiteLocal: obj.quantiteLocal,
      observation: obj.observation
    });

    calculateTotal()
  }

  const calculateTotal = (data) => {
    const articles = tableRef.current.getAllData();
    // Calculate the total before discounts
    const amountBeforeDiscounts = articles.reduce((accumulator, article) => accumulator += article.puDetail * article.quantityart, 0);
    totalSansRM.current.setValue((amountBeforeDiscounts));

    // Calculate all the discounts
    const articlesDiscountsTotal = articles.reduce((accumulator, article) => {
      const puRemise = (article.puDetail - (article.puDetail * (article.remise / 100)));
      return accumulator += (((article.puDetail - puRemise) * article.quantityart));
    }, 0);


    // Display the final amount
    total.current.setValue((amountBeforeDiscounts - articlesDiscountsTotal));

    updateRMInput(articles)
  }

  const updateTaxes = (rm)=>{
    const remise = parseInt(rm) || 0;
    
    const articles = tableRef.current.getAllData().map((article)=>{
      article.remise = remise;
      article.total = (article.puDetail * article.quantityart - ((article.puDetail * article.quantityart)*article.remise)/100)
    })
    
    tableRef.current.updateRow({...articles});
    
    calculateTotal()
  }

  const updateRMInput = (articles)=>{
    const rm = articles[0]?.remise;
    for(let i=1;i<articles.length;i++){
      if(rm !== articles[i]?.remise) {
        remiseR.current.setValue("0")
      }
    }
  }

  const errorsHandler= useRef([]);
  const displayConfirmationDialog = useRef();
  const remiseR = useRef();
  const [depots, setDepots] = useState([]);
  const depot = useRef(null);
  
  const handelValidate= () => {
    displayConfirmationDialog.current.display();
  }

  const checkCode = async (value)=>{
    try{
      const response = await bonAvoirFinder.get(`/${value}/achat`)
      if(response.data.data[0]?.code === value){
        return true
      }else
        return false
    }catch(err){
      console.log(err)
    }
  }

  const checkQuantityStq = (articles)=>{
    return articles.filter((article)=>{
      return parseInt(article.quantiteLocal) > parseInt(article.quantity)
    }).map((article)=>{
      return {name:'remise',message:`la quantité de code d'article ${article.codeArticle} que vous voulez avoir est supérieure à la quantité que vous avez`};
    })
  }

  const checkErrs = async ()=>{
    let alerts = [];
    setDate(document.getElementById("date").value);
    let code = document.getElementById("code").value;
    let fournisseur = document.getElementById("client").value;
    let id_fournisseur = fournisseur && getSelectedClient.current.getSelectedItem().id;
    let remise = document.getElementById("remise").value
    
    if(!remise){
      alerts.push({name:'remise',message:'Assurez que la remise is bien saisez'});
      remiseR.current.setBorderRed();
    }
    if(!code){
      alerts.push({name:'code',message:'Assurez que la code is bien saisez'});
      codeInput.current.setBorderRed();
    }
    if(!id_fournisseur){
      alerts.push({name:'fournisseur' ,message:'Assurez que la fournisseur est bien selectionez'});
      getSelectedClient.current.setBorderRed();
    }
    if( await checkCode(code) === true){
      alerts.push({name:'code',message:'le N° du bon est deja exister'});
      codeInput.current.setBorderRed();
    }
    if(selectedArticlesData.length === 0){
      alerts.push({name:'articles' ,message:'Assurez que la Articles est bien selectionez'});
    }
    let checkQuantity = checkQuantityStq(tableRef.current.getAllData());
    if( checkQuantity.length > 0){
      alerts.push(...checkQuantity)
    }
    
    if(alerts.length > 0) {
      errorsHandler.current.errorsHandler(alerts);
      return
    }
    handelValidate();
  }

  const totalTax = useRef()
  const handleClient = async (client) => {
    // const response = await reglementFournisseurFinder.get(`/${fournisseur.id}`);
    // const bonsAchatsF = await articlesAcheteFinder.get(`/${fournisseur.id}/reglementFournisseur`)

    // const amount = response.data.data.reglementsFournisseur.reduce((accumulator, currentItem) => {
    //   return accumulator + currentItem.montant;
    // }, 0);

    // totalTax.current.setValue(String(Math.abs(amount - bonsAchatsF.data.data.amount)))
}

const handleDelete = (itemToDeleteId) => {
  tableRef.current.setAllData(tableRef.current.getAllData().filter(item => item.id !== itemToDeleteId));
  articleTable.current.addItem(articlesRef.current.find(item => item.id === itemToDeleteId));
}

  // const updateTaxes = ()=>{
  //   let amount=0
  //   tableRef.current.getAllData().map((article)=>{
  //     amount += article.total;
  //   })
  //   totalSansRM.current.setValue(amount);
  //   const remise = document.getElementById('remise').value || 0;
  //   total.current.setValue(amount-(amount*remise)/100)
  // }
  const handleDepot = async (depotId)=>{
    const articles = [];
    let quantityDepot;
    const data = tableRef.current.getAllData()
    for(let i=0;i<data.length;i++){
      let article = data[i];
      quantityDepot = await depotsStockFinder.get(`/${parseInt(depotId.id)}/${parseInt(article.id)}`)
      const quantityD = quantityDepot.data.data?.quantity 
      articles.push({
        id: article.id,
        codeArticle: article.codeArticle,
        designation: article.designation,
        quantityart: article.quantityart,
        puDetail: article.puDetail,
        remise: article.remise,
        lot_nbr: article.lot_nbr,
        total: article.total,
        quantity: article.quantity,
        quantiteLocal: quantityD || 0,
        observation: article.observation
      })
    }
      tableRef.current.setAllData(articles);
  }
  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "35%"},
    {name: "Lot",width: "5%"},
    {name: "Exp",width: "5%"},
    {name: "TVA",width: "5%"},
    {name: "Qte",width: "5%"},
    {name: "UG",width: "5%"},
    {name: "PU",width: "10%"},
    {name: "RM%",width: "5%"},
    {name: "Total",width:"10%"}
  ]
  const printRef = useRef();

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getAllData();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'articles' , message: 'Assurez que la Articles est bien selectionez'}]);
      return
    }
    setIsActive(true);
    const articlesAdded = rowInfo.map((article)=>{
      
      return({
        code: article.codeArticle,
        designation: article.designation,
        lotNbr: article.lot_nbr,
        exp: '',
        TVA: 0,
        quantity: article.quantityart,
        ug: 0,
        PUVente: article.puDetail,
        remise: article.remise,
        total: article.total,
        })
    });

    const ROWS_PER_PAGE = 20; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      rowInfo: {
        code: document.getElementById("code").value,
        client_name: getSelectedClient.current.getSelectedItem().name,
        date: document.getElementById("date").value,
        client_address: getSelectedClient.current.getSelectedItem().address,
        nbr_bon_commande: document.getElementById('bon').value,
        client_activite: getSelectedClient.current.getSelectedItem().activity,
        payment_type: paymentTypeInput.current.getSelectedOption(),
        client_n_register: getSelectedClient.current.getSelectedItem().n_register,
        client_n_article: getSelectedClient.current.getSelectedItem().n_article,
        client_nif: getSelectedClient.current.getSelectedItem().nif,
        client_n_fiscal: getSelectedClient.current.getSelectedItem().n_fiscal,
        client_nis: getSelectedClient.current.getSelectedItem().nis
      },
      articlesAdded: pages,
      saisiPar: authState.user.name
    }
    
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={"Avoir Facture"}>
    <form onSubmit={handleSubmit}>
    <div className={styles['body-container']}>
     <div className={styles['main-body']}>
      <div className={styles['actions-container']}>
        <div className={styles['row-evenly']}>
          <InputText 
            holderText={"Ajouter client"} 
            width={"50%"} 
            labelPosition={true}
            type={"text"} 
            label={'Client'}
            id={'client'}
            searchData={clientList}
            onSelectItem={handleClient}
            path={"/ajouter_fournisseur"}
            btnName={'Ajouter Nouveau fournisseur'} 
            ref={getSelectedClient}
          />
          <SubInfo 
            lined={true}
            label={"Sold Client"}
            unit={"DZD"}
            ref={totalTax}
          />
          <Button 
            name={'Reglement Client'} 
            path={"../reglement_client"} 
            width={"300px"} 
            icon={icons.reglementClient}
          />
        </div>
        <div className={styles['row-evenly']}>
          <div className={styles['row-evenly']}>
          <Button 
            width={"200px"} 
            name={"Ajouter Article"} 
            onclick={()=>displayDialog.current.display()} 
          />
          <InputText
              holderText={"Code Facture"} 
              width={"295px"}
              type={"text"}
              id={'bon'}
            />
            </div>
          <Button 
            icon={icons.impr}
            width={"24%"}
            name={'Imprimer'}
            onclick={handlePrint}
          />
        </div>
        <div style={{height: "435px"}}>
          <Table 
            thead={metaData.table} 
            tbody={selectedArticlesData} 
            edit={true} 
            remove={true} 
            ref={tableRef}
            confirmBeforeDeletion={false}
            actOnValidation={actOnValidation}
            handleDelete={handleDelete} 
          />
        </div>
        
      </div>
      
    </div>
    <div className={styles['side-body']}>
    <PageSideBar validateFunc={checkErrs}>
            <div className={styles['col-around']}>
              <div className={styles['col-evenly']}>
                <InputText 
                  id={"code"}
                  label={"N° du bon"} 
                  holderText={"0001"} 
                  width={"100%"} 
                  type={"number"}
                  ref={codeInput}
                  disabled={true}
                />

                <InputText 
                  id={'date'}
                  label={"Date de bon"} 
                  width={"100%"} 
                  type={"date"}
                  ref={dateInput}
                />

                <SelectInput 
                  options={["Espece", "Cheque", "Verement", "Autre"]} 
                  label={"Type de paiement"} 
                  width={"100%"}
                  ref={paymentTypeInput}
                />
                <InputText label={"Depot"} 
                             width={"100%"}
                             holderText={'Depot'}
                             id={'depot'}
                             onSelectItem={handleDepot}
                             searchData={depots}
                             ref={depot}/>

              </div>

              <div className={styles['col-evenly']} >
                <SubInfo label={"Total sans remise"} unit={"DZD"} ref={totalSansRM} />

                <InputText 
                  id={"remise"}
                  label={"Remise"} 
                  currency={true}  
                  currencyValue={"%"} 
                  width={"100%"} 
                  defaultValue={'0'}
                  type={"number"} 
                  holderText={"0.00"}
                  ref={remiseR}
                  reportChange={updateTaxes}
                />

                <SubInfo label={"Total"} unit={"DZD"} ref={total}/>
              </div>
            </div>
          </PageSideBar>  
    </div>
    <Dialog width={"80%"} table={true} ref={displayDialog} validateFunc={addSelectedArticles}>
      <AdderTable head={metaData.adderTable} body={articlesData} ref={articleTable} />
    </Dialog>
    </div>
    <ConfirmationButton ref={displayConfirmationDialog}/>
   </form>
   <ErrorContainer ref={errorsHandler}/>
    {isActive && <Printing type={'Avoir'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ValidatedMessages ref={validateHandler}/>
   </PageLayout>
  )
}

export default BonAvoirFacture

import React, { useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import styles from './bonDeCommande.module.scss'
import InputText from '../../components/input-text/InputText'
import SubInfo from '../../components/sub-info/SubInfo'
import Button from '../../components/button/Button'
import PageSideBar from '../../components/page-side-bar/PageSideBar'
import Table from '../../components/table/Table'
import fournisseurFinder from '../../api/fournisseurFinder'
import articlesFinder from '../../api/articlesFinder'
import bonCommandeFinder from '../../api/bonCommandeFinder'
import articlesCommanderFinder from '../../api/articlesCommanderFinder'
import Dialog from '../../components/dialog/Dialog'
import AdderTable from '../../components/adder-table/adderTable'
import { useNavigate } from 'react-router-dom'
import metaData from './bonCommande.json'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton'
import Printing from '../../components/printing/Printing'
import { useAuth } from '../../contexts/AuthContext'
import ValidatedMessages from '../../components/validated-messages/ValidatedMessages'
const BonAvoirVente = () => {
  const navigate = useNavigate();
  const { state: authState } = useAuth();
  const [date, setDate] = useState(new Date());

  useEffect(()=>{
    dateInput.current.setValue(date.toISOString().substring(0, 10));
    const getFournisseurs = async ()=>{
      const fournisseurs = await fournisseurFinder.get("/");
      setSearchData(fournisseurs.data.data.fournisseurs)
      
    }
    const getArticles = async ()=>{
      const response = await articlesFinder.get("/");
      const filtredArticles = response.data.data.articles.filter((article)=>{
        return article.type === 'matierepremiere'
      }).map(({ type, ...rest }) => rest);
      articlesRef.current = filtredArticles;
      articleTable.current.setAllData(filtredArticles)
    } 
    const fetchBonCmd = async () => {
      const response = await bonCommandeFinder.get('/');
       let code=1;
      if(response.data.data.code.max){
        code = parseInt(response.data.data.code.max) + 1
      }
      codeInput.current.setValue(code);
    }

    fetchBonCmd();
    getArticles();
    getFournisseurs();
  },[])

  const codeInput = useRef(null);
  const dateInput = useRef(null);
  const displayDialog = useRef([]);

  const getSelectedFournisseur = useRef();
  const articleTable = useRef([]);
  const articlesRef = useRef();
  const tableRef = useRef(null);

  const [searchData, setSearchData] = useState([]);
  const [selectedArticlesData, setSelectedArticlesData] = useState([]);


  const addSelectedArticles = async () => {
    const articles = [];
    const data = [...tableRef.current.getAllData(),...articleTable.current.sendSelectedItems()];
    for(let i=0;i<data.length;i++){
      let temp = data[i];
      articles.push({
        id: temp.id,
        codeArticle: temp.codeArticle,
        quantityart: temp.quantityart || 0,
        puAchat: temp.puAchat,
        total: (temp.puAchat * temp.quantityart) || 0,
        designation: temp.designation,
        quantity: temp.quantity,
        weight: temp.weight,
        volume: temp.volume,
        observation: temp.observation
      })
    }
    setSelectedArticlesData(articles)
  }

  const total = useRef();
  const actOnValidation = async (obj) => {
    let amount= 0;
    
    tableRef.current.getAllData().filter((article)=>{
      return article.id !== obj.id
    }).map((article)=>{
      amount += article.total
    })
    tableRef.current.updateRow({
      id: obj.id,
      codeArticle: obj.codeArticle,
      quantityart: obj.quantityart || 0,
      puAchat: obj.puAchat || 0,
      total: (obj.puAchat * obj.quantityart),
      designation: obj.designation,
      quantity: obj.quantity,
      weight: obj.weight,
      volume: obj.volume,
      observation: obj.observation
    });
    amount += (obj.puAchat * obj.quantityart);
    total.current.setValue(amount)
  }

  const handleDelete = (itemToDeleteId) => {
    tableRef.current.setAllData(tableRef.current.getAllData().filter(item => item.id !== itemToDeleteId));
    articleTable.current.addItem(articlesRef.current.find(item => item.id === itemToDeleteId));
  }

  const handelSubmit = async (e) => {
    e.preventDefault();
    
    try {
      
      const articles = tableRef.current.getAllData().map( article => {
        return {
          id_article: article.id,
          prix_achat: parseInt(article.puAchat), 
          quantite: parseInt(article.quantityart),
          observation: article.observation
        }
      });
      
      await bonCommandeFinder.post("/",
      {
        id_fournisseur : getSelectedFournisseur.current.getSelectedItem().id,
        code :parseInt(document.getElementById('code').value),
        date,
        articles:articles
      });
      
      validateHandler.current.validateHandler([{name: 'validate' , message: 'Bon est bien Valider'}]);
      setTimeout(()=> {
        navigate(-1)
      }, 1000);

    } 
    catch (err) {
      console.log(err)
    }
  }
  const validateHandler = useRef([]);

  const errorsHandler= useRef([]);
  const displayConfirmationDialog = useRef();

  const checkCode = async (value)=>{
    const response = await bonCommandeFinder.get(`/${value}`)
    if(response.data.data[0]?.code == value){
      return true
    }else
      return false
  }

  const checkErrs = async ()=>{
    let alerts = [];
    const date = new Date(document.getElementById("date").value);
    let code = document.getElementById("code").value;
    let fournisseur = document.getElementById("fournisseurs").value;
    let id_fournisseur = fournisseur && getSelectedFournisseur.current.getSelectedItem().id;
    
    if(!code){
      alerts.push({name:'code',message:'Assurez que la code est bien saisez'});
      codeInput.current.setBorderRed();
    }
    if(!id_fournisseur){
      alerts.push({name:'fournisseur' ,message:'Assurez que la fournisseur est bien selectionez'});
      getSelectedFournisseur.current.setBorderRed();
    }
    if( await checkCode(code) === true){
      alerts.push({name:'code',message:'le NÂ° du bon est deja exister'});
      codeInput.current.setBorderRed();
    }
    if(selectedArticlesData.length === 0)
      alerts.push({name:'articles' ,message:'Assurez que la Articles est bien selectionez'});
    
    if(alerts.length > 0) {
      errorsHandler.current.errorsHandler(alerts);
      return
    }
    handelValidate();
  }

  const handelValidate= () => {
    displayConfirmationDialog.current.display();
  }
  const PrintingColumntype1 = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "40%"},
    {name: "Qte",width: "5%"},
    {name: "PU",width: "5%"},
    {name: "Total",width: "10%"},
  ]
  const PrintingColumntype2 = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "80%"},
    {name: "Qte",width: "10%"},
  ]
  const [PrintingColumns, setPrintingColumns] = useState(PrintingColumntype1);
  const printRef = useRef();

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();

  const handlePrint = (type)=>{
    const rowInfo = tableRef.current.getAllData();
    setIsActive(true);
    const articlesAdded = type === "prix" ? rowInfo.map((article)=>{
      return({
            code: article.codeArticle,
            designation: article.designation,
            quantity: article.quantityart,
            PUAchat: article.puAchat,
            total: article.total,
        })
    }):rowInfo.map((article)=>{
      return({
            code: article.codeArticle,
            designation: article.designation,
            quantity: article.quantityart,
        })
    });
    setPrintingColumns(type === "prix" ? PrintingColumntype1: PrintingColumntype2)
    let data = {}
    const ROWS_PER_PAGE = 15; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    data = {
      rowInfo: {
        code: document.getElementById("code").value,
        fournisseur_name: getSelectedFournisseur.current.getSelectedItem().name,
        date: document.getElementById("date").value,
        fournisseur_address: getSelectedFournisseur.current.getSelectedItem().address,
      },
      articlesAdded: pages,
      type: type === "prix" ? true : false,
      saisiPar: authState.user.name
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
    displayDialog2.current.noDisplay()
    
  }
  const displayDialog2 = useRef([]);
  const check = ()=>{
    const rowInfo = tableRef.current.getAllData();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'articles' , message: 'Assurez que la Articles est bien selectionez'}]);
      return
    }
    displayDialog2.current.display()
  }


  return (
    <PageLayout icon={icons.achat} nameLayout={"Bon De Commande"}>
      <form onSubmit={handelSubmit}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['actions-container']}>
                <div className={styles['row-evenly']}>
                  <InputText  
                    id={'fournisseurs'}
                    holderText={"fournisseur"} 
                    labelPosition={true} 
                    type={"text"} 
                    label={'Ajouter fournisseur *'}
                    width={"50%"}
                    searchData={searchData}
                    displaySearchOption={true}
                    path={"/ajouter_fournisseur"}
                    btnName={'Ajouter Nouveau fournisseur'}
                    ref={getSelectedFournisseur}/>

                  <Button 
                  name='Imprimer'
                  width={"200px"} 
                  icon={icons.impr}
                  onclick={()=>check()}
                  />
                </div>
                
                <div className={styles['ajouter-articles-container']}>
                  <Button 
                    name='Ajouter Des Article' 
                    width={"200px"} 
                    onclick={()=>displayDialog.current.display()}
                    />
                </div>

                <div style={{height: "400px"}}>
                  <Table 
                    thead={metaData.table} 
                    tbody={selectedArticlesData} 
                    edit={true}
                    confirmBeforeDeletion={false}
                    remove={true}
                    ref={tableRef}
                    actOnValidation={actOnValidation}
                    handleDelete={handleDelete} />

                  {/* <div style={{display: 'flex',gap: '50px',paddingTop: '10px'}}> 
                    <SubInfo 
                      inline
                      label={"Poids"} 
                      unit={"KG"}
                    />
                    <SubInfo 
                      inline
                      label={"Volume"} 
                      unit={"L"}
                      />
                  </div> */}
                </div>
              </div>
            </div>

          <div className={styles['side-body']}>
          <PageSideBar validateFunc={checkErrs}>
            <div className={styles['col-around']}>
                <div className={styles['col-evenly']}>
                  <InputText 
                    label={"NÂ° du bon *"} 
                    id={'code'} 
                    holderText={"0001"} 
                    width={"100%"} 
                    type={"number"}
                    disabled={true}
                    ref={codeInput}/>

                  <InputText 
                    label={"Date de bon *"} 
                    id={'date'}  
                    width={"100%"} 
                    type={"date"}
                    ref={dateInput}/>
                </div>

                <div className={styles['col-evenly']} style={{marginTop:"170px"}}>
                  <SubInfo 
                    label={"Total"} 
                    unit={"DZD"}
                    ref={total}
                  />
                </div>
            </div>
          </PageSideBar>  
          </div>

          <Dialog width={"80%"} table={true} ref={displayDialog} validateFunc={addSelectedArticles}>
            <AdderTable 
              head={metaData.adderTable} 
              ref={articleTable}/>
          </Dialog>
      </div>
      <ConfirmationButton ref={displayConfirmationDialog}/>
    </form>
    <ErrorContainer ref={errorsHandler}/>
    {isActive && <Printing type={'Bon De Commande'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <Dialog width={"fit-content"} ref={displayDialog2} switchStyle={true}>
      <div style={{display: 'flex', gap: "20px", marginBottom: '10px'}}>
        <Button 
          width={"250px"}
          name={'Imprimer Avec Prix'} 
          onclick={()=>handlePrint('prix')}
          />
        <Button 
          width={"250px"}
          name={'Imprimer Sans Prix'} 
          onclick={()=>handlePrint('sansPrix')}
          />
      </div>
    </Dialog>
    <ValidatedMessages ref={validateHandler}/>
   </PageLayout>
  )
  }
export default BonAvoirVente
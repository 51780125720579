import React, { useEffect, useState, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import Button from '../../components/button/Button'
import styles from './etatAchatFournisseurDetaille.module.scss'
import metaData from './EtatAchatFournisseurDetaille.json'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import fournisseurFinder from '../../api/fournisseurFinder'
import detteFournisseurFinder from '../../api/detteFournisseurFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'

const EtatAchatFournisseurDetaille = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ inputValue , setInputValue ] = useState('');
  const [data,setData] = useState([]);

  const [searchData, setSearchData] = useState([]);

  const tableRef = useRef();
  const tableData = useRef();
  const date1 = useRef();
  const date2 = useRef();
  const reportDate1Change = (val) => {
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!val || itemDate >= new Date(val)) &&
        (!date2.current.getValue() || itemDate <= new Date(date2.current.getValue()))
      );
    }));
  }
  const reportDate2Change = (val) =>{
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!date1.current.getValue() || itemDate >= new Date(date1.current.getValue())) &&
        (!val || itemDate <= new Date(val))
      );
    }))
  }
  const getSelectedFournisseur = useRef();
  const totalAchat = useRef();
  const totalRetour = useRef();
  const totalVersement = useRef();
  const sold = useRef();
  const globalAmount = useRef();
    
  const displayData = async (fournisseur)=>{
    try {
      const articlesAchatRes = await articlesAcheteFinder.get(`/`);
      const articlesAvoirRes = await articlesAvoirFinder.get('/achat');
      const detteFournisseur = await detteFournisseurFinder.get(`/${fournisseur.id}`);

      const {total_verser} = detteFournisseur.data.data;
      const filtredBonsAchat = articlesAchatRes.data.data.articles.filter(article => article.id_fournisseur === fournisseur.id);
      const filtredArticlesAvoir = articlesAvoirRes.data.data.articles.filter(article => article.id_fournisseur === fournisseur.id);

      const { max_id } = articlesAchatRes.data.data;

      let montantAchat = .0;
      let montantRetour = .0;
      
      const articlesAchete = filtredBonsAchat.map(article => {
        // CALCULATE THE TOTAL PRICE OF ARTICLE INCLUDING IT PROMOTION
        let montant = (article.quantity * article.prix) * (1 - (article.remise/100));
        //
        montantAchat += montant;
        //
        return{
          id: article.id,
          achat_av: 'Achat',
          lot:article.lotNbr,
          codeArt: article.code,
          code: article.bon_achat_code,
          date: dateFormatter(article.bon_achat_date,3),
          qtyAchetee: article.quantity, 
          prix: article.prix,
          remise: article.remise+ "%",
          montant,
          disgnation: article.designation
        }

      });

      const articlesAvoir = filtredArticlesAvoir.map(article =>{
        // CALCULATE THE TOTAL PRICE OF ARTICLE INCLUDING IT PROMOTION
        let montant = (article.quantity * article.prix_achat) * (1 - (article.remise/100 || 0));
        // 
        montantRetour += montant;
        // 
        
        let id = parseInt(article.id) + parseInt(max_id);
        
        return{
          id,
          achat_av: 'Avoir',
          lot:article.lotNbr,
          codeArt: article.code ,
          code: article.bon_avoir_code,
          date: dateFormatter(article.bon_avoir_date,3),
          qtyAchetee: article.quantity , 
          prix: article.prix_achat,
          remise: article.remise+ " %",
          montant,
          disgnation: article.designation
        }
      });

      setData([...articlesAvoir,...articlesAchete]);
      tableData.current = [...articlesAvoir,...articlesAchete];
      let ga = (montantAchat - montantRetour);
      totalAchat.current?.setValue(montantAchat.toFixed(2));
      totalRetour.current?.setValue(montantRetour.toFixed(2));
      globalAmount.current?.setValue(ga.toFixed(2));
      totalVersement.current?.setValue(total_verser);

      sold.current?.setValue((ga - total_verser).toFixed(2));
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const getFournisseurs = async () =>{
      try {
        const four = await fournisseurFinder.get("/");
        setSearchData(four.data.data.fournisseurs);
      } catch (err) {
        console.log(err);
      }
    }
    getFournisseurs();
  }, []);

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Achats Par Fournisseur ( Détaillé )'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
              icon={icons.search}
              id={'fournisseurs'}
              holderText={"fournisseur"}
              label={"Ajouter fournisseur"}
              width={"50%"}
              labelPosition={true}
              searchData={searchData}
              onSelectItem={displayData}
              ref={getSelectedFournisseur}
              displaySearchOption={true}
              path={"/ajouter_fournisseur"}
              btnName={'Ajouter Nouveau fournisseur'}
              />
              <div className={styles['row-end']}>
                <Button 
                icon={icons.impr}
                width={"150px"}
                name={'Imprimer'} 
                  />
                <Button 
                  icon={icons.exporter}
                  width={"250px"}
                  name={'Exporter Vers Excel'} 
                    />
              </div>
          </div>
          <div className={styles['row']}>
              <InputText
                label={"Periode du"}
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                ref={date1}
                reportChange={reportDate1Change}
                />
                <InputText label={"Au"} 
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                ref={date2}
                reportChange={reportDate2Change}
                />
          </div>
          <div style={{height: "300px"}}>
            <Table 
              thead={metaData.table} 
              tbody={data} 
              ref={tableRef}
            />
          </div>
          <div className={styles['row-between']} style={{width:'100%'}}>
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Montant des Achats'}
              ref={totalAchat}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Montant des Retours'}
              ref={totalRetour}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Montant Global'}
              ref={globalAmount}
              disabled
              />
          </div>
          <div className={styles['row-end']} style={{width:'100%'}}>
            <InputText  
              holderText={"Total Verser"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Montant Verser'}
              ref={totalVersement}
              disabled
              />
            <InputText  
              holderText={"Solde"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Solde'} 
              ref={sold} 
              disabled
              />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default EtatAchatFournisseurDetaille
import React, { useEffect, useState, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import metaData from './etatVentesDetailleParArticle.json'
import styles from './etatVentesDetailleParArticle.module.scss'
import Button from '../../components/button/Button'
import articleLivreFinder from '../../api/articleLivreFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'

const EtatVentesDetailleParArticle = () => {
    const { state: authState } = useAuth();
    const { user } = authState;

    const [ inputValue , setInputValue ] = useState('')
    const [data,setData] = useState([]);

    const [date1, setDate1] = useState();
    const reportDate1Change = (val) => setDate1(val);

    const [date2, setDate2] = useState();
    const reportDate2Change = (val) => setDate2(val);

    const totalVente = useRef();
    const totalAchat = useRef();
    const totalBenifice = useRef();

  useEffect(() =>{
    let montantAchat = 0.0;
    let montantVente = 0.0;
    let montantRetour = 0.0;

    const fetchArticlesAcheteAvoir = async () =>{
      try {
        const articles_vente = await articleLivreFinder.get('/');
        const articles_avoir = await articlesAvoirFinder.get('/vente');
        const bons_achat = await bonAchatsFinder.get('/');
        

        // GET THE TOTAL AMOUNT OF PURCHASES
        bons_achat.data.data.bons.map(bon =>{
          montantAchat += bon.amount;
        });
        // 

        // GET THE MAX ID OF VENTE RECEIPT 
        const { max_id } = articles_vente.data.data;
        // 

        const articlesVendue = articles_vente.data.data.articles.map((element)=>{ 
          
          // ADD THE REMISE OF THE BON TO THE ARTICLE
          let montant = (element.prix * element.quantity) * (1 - (element.remise / 100));
          montantVente += montant;
          // 
          return{
            id: element.id,
            Vente_av: 'vente',
            code: element.code,
            lot:element.lotNbr,
            date: dateFormatter(element.bl_date,3),
            qtyVendue:element.quantity,
            pu_achat:element.pu_achat,
            pu_vente:element.prix_vente_gros,
            pu_detaille: element.prix,
            benifice: montant - element.pu_achat ,
            designation:element.designation,
            }
          }
        );
        
        const articlesAvoir = articles_avoir.data.data.articles.map((element)=>{

          let montant = (element.prix_achat * element.quantity) * (1 - (element.remise / 100));
          montantRetour += montant;

          // ADD THE MAX ID FROM THE VENTE TABLE TO THE ARTICLE ID
          let id = parseInt(element.id) + parseInt(max_id);
          // 
          return{
            id,
            Vente_av: 'avoir',
            lot:element.lotNbr,
            code: element.code,
            date: dateFormatter(element.bon_avoir_date,3),
            qtyVendue:element.quantity,
            pu_achat:element.pu_achat,
            prix_vente_gros:element.prix_vente_gros,
            pu_detaille:element.prix_achat,
            benifice:montant - element.pu_achat,
            designation:element.designation
          }
        });

        totalVente.current?.setValue(montantVente.toFixed(3));
        totalAchat.current?.setValue(montantAchat.toFixed(3));
        totalBenifice.current?.setValue(((montantVente - montantRetour) - montantAchat).toFixed(3));
        setData([...articlesAvoir,...articlesVendue]);
      } catch (err) {
        console.log(err);
      }
    }
    fetchArticlesAcheteAvoir();
  },[]);


  return (
  <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes Dètaillè par Article'}>
    <div className={styles['body-container']}>
      <div className={styles['main-body']}>
        <div className={styles['row-between']}>
          <InputText 
            icon={icons.search}
            type={"text"}
            holderText={"Article"}
            label={"Article"}
            labelPosition={true}
            width={"50%"}
            />  
          <div className={styles['row-end']}>
            <Button 
              icon={icons.impr}
              width={"250px"}
              name={'Imprimer'}
              />
          </div>
        </div>
        <div className={styles['row']}>
          <InputText label={"Jour 1"} 
            labelPosition={true}
            width={"max-content"} 
            type={"date"}
            reportChange={reportDate1Change}
            />
          <InputText label={"Jour 2"} 
            labelPosition={true}
            width={"max-content"} 
            type={"date"}
            reportChange={reportDate2Change}
            />
        </div>
        <div style={{height: '300px'}}>
          <Table thead={metaData.table} 
            inputValue={inputValue} 
            tbody={data}   
            date1={date1} 
            date2={date2}
            />
        </div>
        <div className={styles['row-between']} style={{width:'100%'}}>
          <InputText  
            holderText={"Montant"} 
            setInputValue={setInputValue} 
            inputValue={inputValue} 
            type={"number"} 
            label={'Total des Ventes'}
            ref={totalVente}
            disabled
            />
          <InputText  
            holderText={"Montant"} 
            setInputValue={setInputValue} 
            inputValue={inputValue} 
            type={"number"} 
            label={'Montant des Achat'}
            ref={totalAchat}
            disabled
            />
          <InputText  
            holderText={"Montant"} 
            setInputValue={setInputValue} 
            inputValue={inputValue} 
            type={"number"} 
            label={'Total du Bénéfice'}
            ref={totalBenifice}
            disabled
            />
        </div>
    </div>
    </div>
  </PageLayout>
  )
}

export default EtatVentesDetailleParArticle;
import React, { useEffect,useState,useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import Button from '../../components/button/Button'
import styles from './etatVentesGlobalParClient.module.scss'
import bonsAvoirFinder from '../../api/bonAvoirFinder'
import bonVentesFinder from '../../api/bonVentesFinder'
import clientsFinder from '../..//api/clientsFinder'
import metaData from './EtatVentesGlobalParClient.json'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'
import Printing from '../../components/printing/Printing'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'

const EtatVentesGlobalParClient = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const tableData = useRef();
  const tableRef = useRef();
  const date1 = useRef();
  const date2 = useRef();
  const reportDate1Change = (val) => {
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!val || itemDate >= new Date(val)) &&
        (!date2.current.getValue() || itemDate <= new Date(date2.current.getValue()))
      );
    }));
  }
  const reportDate2Change = (val) =>{
    tableRef.current.setAllData(tableData.current.filter((item)=>{
      const itemDate = new Date(item.date);
      return (
        (!date1.current.getValue() || itemDate >= new Date(date1.current.getValue())) &&
        (!val || itemDate <= new Date(val))
      );
    }))
  }

  const [ inputValue , setInputValue ] = useState('');
  const [data,setData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const totalVente = useRef();
  const totalRetour = useRef();
  const globalAmount = useRef();
  const getSelectedClient = useRef();

  useEffect(() => {
    const getClient = async () =>{
      try {
        const client = await clientsFinder.get("/");
        setSearchData(client.data.data.clients);
      } catch (err) {
        console.log(err);
      }
    }
    
    getClient();
  }, []);

  const displayData = async (client) =>{

    try {
      let montantVentes = .0;
      let montantRetour = .0;
  
      const bonsVenteRes = await bonVentesFinder.get('/');
      const bonsAvoirRes = await bonsAvoirFinder.get('/vente');
  
      // FILTER THE TABLE BY THE CLIENT ID
      const filtredVentes = bonsVenteRes.data.data.bons.filter( bon => bon.id_client === client.id);
      const filtredAvoirs = bonsAvoirRes.data.data.bons.filter( bon => bon.id_client === client.id);
  
      const { max_id } = bonsVenteRes.data.data;
  
      const articlesAchete = filtredVentes.map((bon)=>{
        montantVentes += bon.amount;
  
        return{
          id: bon.id,
          code: bon.code,
          date: dateFormatter(bon.date,3),
          Vente_av: 'Vente',
          montant:bon.amount
        }
      });
  
      const articlesAvoir = filtredAvoirs.map((bon)=>{
        montantRetour += bon.amount; 
  
        let id = parseInt(bon.id) + parseInt(max_id);
          return{
            id,
            code: bon.code,
            date: dateFormatter(bon.date,3),
            Vente_av: 'Avoir',
            montant:bon.amount
          }
      });
  
      totalVente.current?.setValue(montantVentes.toFixed(3));
      totalRetour.current?.setValue(montantRetour.toFixed(3));  
      globalAmount.current?.setValue((montantVentes - montantRetour).toFixed(3));
      setData([...articlesAvoir,...articlesAchete]);
      tableData.current = [...articlesAvoir,...articlesAchete];
      } catch (err) {
      console.log(err);
    }
  }

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);
  const printRef = useRef()
  const achatAvoir = useRef()

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
  
    setIsActive(true);
    achatAvoir.current = "Etat des Vente Par Client (Global)"
    const articlesAdded = tableRef.current.getSelectedItems().map((etatBon)=>{
      return{
        code: etatBon.code,
        date: etatBon.date,
        venteAvoir: etatBon.Vente_av,
        montant: etatBon.montant,
      }
    });
    const ROWS_PER_PAGE = 25; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages,
      globalInfo: {
        totalDesVente: totalVente.current.getValue(),
        totalAvoirVente: totalRetour.current.getValue(),
        total: globalAmount.current.getValue(),
        periodeDu: date1.current.getValue(),
        au: date2.current.getValue(),
        client: getSelectedClient.current.getSelectedItem().name
      }
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print();
    }, 100);
    
  }
  const PrintingColumns = [
    {name: "N°Bon",width: "5%"},
    {name: "Date",width: "10%"},
    {name: "Vente/Av",width: "10%"},
    {name: "Montant",width:"10%"}
  ]

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes Par Clients ( global )'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
              icon={icons.search}
              id={'client'}
              label={"Code Client"}
              holderText={"Client"}
              type={"text"}
              labelPosition={true}
              width={"50%"}
              searchData={searchData}
              onSelectItem={displayData}
              ref={getSelectedClient}
              displaySearchOption={true}
              path={"/ajouter_client"}
              btnName={'Ajouter Nouveau client'}
              />  

            <div className={styles['row-end']}>
              <Button 
                icon={icons.impr}
                width={"250px"}
                name={'Imprimer'} 
                onclick={handlePrint}
                />
            </div>
          </div>
          <div className={styles['row']}>
            <InputText
              label={"Periode du"}
              labelPosition={true}
              width={"max-content"}
              type={"date"}
              ref={date1}
              reportChange={reportDate1Change}
              />
            <InputText 
              label={"Au"} 
              labelPosition={true}
              width={"max-content"}
              type={"date"}
              ref={date2}
              reportChange={reportDate2Change}
              />
          </div>
          <div style={{height: "300px"}}>
            <Table 
            thead={metaData.table} 
            tbody={data}
            ref={tableRef}
            />
          </div>
          <div className={styles['row-between']} style={{width:'100%'}}>
            <InputText  
              holderText={"Montant"} 
              type={"number"} 
              label={'Montant des Ventes'}
              ref={totalVente}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              type={"number"} 
              label={'Montant des Retours'}
              ref={totalRetour}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              type={"number"} 
              label={'Montant Global'}
              ref={globalAmount}
              disabled
              />
          </div>
        </div>
      </div>
      {isActive && <Printing type={achatAvoir.current} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
      <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  );
}

export default EtatVentesGlobalParClient;
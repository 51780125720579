import React, { useEffect, useState, useRef} from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import Button from '../../components/button/Button'
import styles from './etatVentesParClientetParArticle.module.scss'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import clientsFinder from '../../api/clientsFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import articleLivreFinder from '../../api/articleLivreFinder'
import metaData from './etatVentesParClientParArticle.json'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'

const EtatVentesParClientEtParArticle = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const [ inputValue , setInputValue ] = useState('');
  const [checked , setChecked ] = useState(false);
  const [data,setData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const handelCheckbox = ()=>{
    setChecked(!checked);
  }

  const totalVente = useRef();
  const totalAchat = useRef();
  const totalBenifice = useRef();
  const getSelectedClient = useRef();

  useEffect(() => {
    const getClient = async () =>{
      try {
        const client = await clientsFinder.get("/");
        setSearchData(client.data.data.clients);
      } catch (err) {
        console.log(err);
      }
    }
  
  getClient();
  }, []); 

  const displayData = async (client) =>{
    try {
      
      let montantAchat = .0;
      let montantVente = .0;
      let montantRetour = .0;
  
  
      const articlesVendueRes = await articleLivreFinder.get('/');
      const articlesAvoirRes = await articlesAvoirFinder.get('/vente');
      const bonsAchat = await bonAchatsFinder.get('/');
  
      // FILTER THE TABLE BY THE CLIENT ID
      const filtredVentes = articlesVendueRes.data.data.articles.filter( article => article.id_client === client.id);
      const filtredAvoirs = articlesAvoirRes.data.data.articles.filter( article => article.id_client === client.id);
  
      // GET THE TOTAL AMOUNT OF PURCHASES
      bonsAchat.data.data.bons.map(bon =>{
        montantAchat += bon.amount;
      });
      // CALCULATE THE FREE UNITIES 
      const { max_id } = articlesVendueRes.data.data;
      // 
  
      const articlesVendue = filtredVentes.map((article)=>{ 
        // ADD THE REMISE OF THE BON TO THE ARTICLE
        let montant = (article.prix * article.quantity) * (1 - (article.remise / 100));
        montantVente += montant;
        // 
  
        return{
          vente_avoir:'Vente',
          id: article.id,
          lot: article.lotNbr,
          date:dateFormatter(article.bon_vente_date,3),
          code: article.code,
          quantity:article.quantity,
          montant,
          designation: article.designation
        }
      }
      );
  
      const articlesAvoir = filtredAvoirs.map((article)=>{
        // ADD THE REMISE OF THE BON TO THE ARTICLE
        let montant = (article.prix_achat * article.quantity) * (1 - (article.remise / 100));
        montantRetour += montant;
        // 
  
        let id = parseInt(article.id) + parseInt(max_id);
          return{
            vente_avoir:'Avoir',
            id,
            lot:article.lotNbr,
            date:dateFormatter(article.bon_avoir_date,3),
            code: article.code,
            quantity:article.quantity,
            montant,
            designation: article.designation
          }
      });
  
      totalVente.current?.setValue(montantVente.toFixed(3));
      totalAchat.current?.setValue(montantAchat.toFixed(3));
      totalBenifice.current?.setValue((parseFloat(montantVente - montantRetour) - parseFloat(montantAchat)).toFixed(3));
      setData([...articlesAvoir,...articlesVendue]);
    } catch (err) {
      console.log(err);
    }
  }

    return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes Par Client et Par Article'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']} style={{gap: '3rem'}}>
            <div className={styles['row']} style={{gap: '2rem',width: '70%'}}>
              <InputText 
                icon={icons.search}
                id={'client'}
                label={"Code Client"}
                holderText={"Client"}
                type={"text"}
                labelPosition={true}
                width={"50%"}
                searchData={searchData}
                onSelectItem={displayData}
                ref={getSelectedClient}
                displaySearchOption={true}
                path={"/ajouter_client"}
                btnName={'Ajouter Nouveau client'}
              />
              <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher Article"}
                label={"Article"}
                labelPosition={true}
                width={"50%"}
              />
            </div>
            <div className={styles['row-end']} style={{width: 'max-content'}}>
              <Button 
                icon={icons.impr}
                width={"150px"}
                name={'Imprimer'} 
                />
              <Button 
                icon={icons.exporter}
                width={"250px"}
                name={'Exporter Veres Excel'} 
                  />
            </div>
          </div>
          <div className={styles['row-between']}>
            <div className={styles['row']}>
              <InputText
                label={"Jour 1"}
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                reportChange={reportDate1Change}
                />
              <InputText label={"Jour 2"} 
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                reportChange={reportDate2Change}
                />
              <div className={styles['check-input-container']}>
                <p className={styles['checkbox-name']}>Avec Bénéfice</p>
                <input 
                  type='checkbox' 
                  className={styles['check-input']} 
                  onClick={handelCheckbox}
                  style={checked ? {backgroundImage: `url(${icons.checkboxActive})`}:
                  {backgroundImage: `url(${icons.checkboxInactive})`}} 
                  />
              </div>
            </div>
          </div>
          <div style={{height: "300px"}}>
            <Table thead={metaData.table} 
              inputValue={inputValue} 
              tbody={data} 
              date1={date1} 
              date2={date2}
              />
          </div>
          <div className={styles['row-between']} style={{width:'100%'}}>
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Total des Ventes'}
              ref={totalVente}
              />
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Total des Achats'}
              ref={totalAchat}
              />
            <InputText 
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Total du Bénéfice'}
              ref={totalBenifice}
              />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default EtatVentesParClientEtParArticle;
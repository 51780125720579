import React, { useEffect, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import { useState } from 'react'
import styles from './etatAchatDetaille.module.scss'
import Button from '../../components/button/Button'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import metaData from './etatAchatGlobalDetaille.json';
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'
import PrintingLandscape from '../../components/printing-landscape/PrintingLandscape'

const EtatAchatDetaille = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ inputValue , setInputValue ] = useState('')
  const [data,setData] = useState([]);

  const tableRef = useRef();
  const tableData = useRef();
  const date1 = useRef();
  const date2 = useRef();
  const reportDate1Change = (val) => {
    tableRef.current.setAllData(tableData.current.filter((item) => {
      const itemDate = new Date(item.date);
      return (
        (!val || itemDate >= new Date(val)) &&
        (!date2.current.getValue() || itemDate <= new Date(date2.current.getValue()))
      );
    }));
  }
  const reportDate2Change = (val)  => {
    tableRef.current.setAllData(tableData.current.filter((item) => {
      const itemDate = new Date(item.date);
      return (
        (!date1.current.getValue() || itemDate >= new Date(date1.current.getValue())) &&
        (!val || itemDate <= new Date(val))
      );
    }))
  }
  
  const total = useRef();
  const totalRetour = useRef(); 
  const totalBaAvecRemise = useRef();

  useEffect(() => {
    let montantRetour = .0;
    let totalRemise = .0;
    let totalAchatSansRemise = .0;
    let totalAchatAvecRemise = .0;

    const fetchArticlesAcheteAvoir = async ()  => {
      try {
        
        const articlesAcheteRes = await articlesAcheteFinder.get('/');
        const articlesAvoirRes = await articlesAvoirFinder.get('/achat');
        const { max_id } = articlesAcheteRes.data.data;
  
        const articlesAchete = articlesAcheteRes.data.data.articles.map((article) => {
          
          // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING IT PROMOTION
          let montant = (article.quantity * article.prix) * (1 - (article.remise/100));
          // 
  
          // CALCULATE THE TOTAL AMOUNT OF THE PURCHASES WITHOUT ARTICLE PROMOTION
          totalAchatSansRemise += (article.quantity * article.prix);
          // 
  
          // CALCULATE THE TOTAL AMOUNT OF THE ARTICLE PROMOTIONS
          totalRemise += (article.remise/100) * (article.quantity * article.prix);
          //
          totalAchatAvecRemise += montant;
          // 
          return{
                id: article.id,
                nbr_bon:article.bon_achat_code,
                achat_avoir : 'Achat',
                lot : article.lotNbr,
                codeArt: article.code,
                date: dateFormatter(article.bon_achat_date,3),
                designation: article.designation,
                qty: article.quantity,
                qtyEnStk: article.ds_quantity,
                pu_achat: article.prix,
                prix_vente_gros: article.prix_vente_gros,
                pu_detaille: article.pu_detailer,
                montant,
                codeFournisseur: article.fournisseur_code,
                fournisseur: article.fournisseur_name,
                observation: article.observation
              }
          }
        );
        
        const articlesAvoir = articlesAvoirRes.data.data.articles?.map((article) => {
          // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING THE ARTICLE PROMOTION
          let montant = (article.quantity * article.prix_achat) * (1 - (article.remise/100));
          // 
          montantRetour += montant;
          // 
  
          let id = parseInt(article.id) + parseInt(max_id);
          return{
            id,
            nbr_bon:article.bon_avoir_code,
            achat_avoir : 'Avoir',
            lot : article.lotNbr,
            codeArt: article.code,
            date: dateFormatter(article.bon_avoir_date,3),
            designation: article.designation,
            qtyEnStk: article.ds_quantity,
            qty: article.quantity,
            pu_achat: article.prix_achat,
            prix_vente_gros: article.prix_vente_gros,
            pu_detaille: article.pu_detailer,
            montant,
            codeFournisseur: article.fournisseur_code,
            fournisseur: article.fournisseur_name,
            observation: article.observation
          }
        });
        setData([...articlesAvoir,...articlesAchete]);
        tableData.current = [...articlesAvoir,...articlesAchete];
  
        // setData(articlesAchete);
        totalBaAvecRemise.current?.setValue(totalAchatAvecRemise.toFixed(3));
        totalRetour.current?.setValue(montantRetour.toFixed(3));
        total.current?.setValue((totalAchatAvecRemise - montantRetour).toFixed(3));
      } catch (err) {
        console.log(err);
      }
    }
    fetchArticlesAcheteAvoir();
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const errorsHandler= useRef([]);
  const printRef = useRef()
  const achatAvoir = useRef()

  const handlePrint = () => {
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      // errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
  
    setIsActive(true);
    achatAvoir.current = "Etat des Achat (Detaille)"
    const articlesAdded = tableRef.current.getSelectedItems().map((etatBon) => {
      return{
        code: etatBon.nbr_bon,
        achat_avoir: etatBon.achat_avoir,
        date: etatBon.date,
        codeArt: etatBon.codeArt,
        designation: etatBon.designation,
        qty: etatBon.qty,
        pu_achat: etatBon.pu_achat,
        montant: etatBon.montant,
        codeFournisseur: etatBon.codeFournisseur,
        fournisseur: etatBon.fournisseur
      }
    });
    const ROWS_PER_PAGE = 25; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      articlesAdded: pages,
      globalInfo: {
        totalDesAchat: totalBaAvecRemise.current.getValue(),
        totalAvoirAchat: totalRetour.current.getValue(),
        total: total.current.getValue(),
        periodeDu: date1.current.getValue(),
        au: date2.current.getValue()
      }
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print();
    }, 100);
    
  }
  const PrintingColumns = [
    {name: "N°Bon",width: "5%"},
    {name: "Achat/Av",width: "10%"},
    {name: "Date",width: "10%"},
    {name: "Code Art",width: "5%"},
    {name: "Designation",width: "15%"},
    {name: "Qte",width: "5%"},
    {name: "PU Achat",width: "5%"},
    {name: "Montant",width:"10%"},
    {name: "Code F",width: "5%"},
    {name: "Fournisseur",width: "10%"}
  ]

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Achat détaillé'}>
      <div className={styles['body-container']}>
        <div className={styles['main-body']}>
          <div className={styles['row-between']}>
            <InputText 
              icon={icons.data}
              type={"text"}
              holderText={"Chercher"}
              label={"Chercher"}
              labelPosition={true}
              width={"50%"}
              />  
            <div className={styles['row-end']}>
              <Button 
                icon={icons.impr}
                width={"150px"}
                name={'Imprimer'}
                // onclick={handlePrint}
                />
              <Button 
                icon={icons.exporter}
                width={"250px"}
                name={'Exporter Vers Excel'}
                />
            </div>
          </div>
          <div className={styles['row']}>
            <InputText
              label={"Periode du"}
              labelPosition={true}
              width={"max-content"}
              ref={date1}
              type={"date"}
              reportChange={reportDate1Change}
              />
            <InputText 
              label={"Au"} 
              labelPosition={true}
              width={"max-content"}
              ref={date2}
              type={"date"}
              reportChange={reportDate2Change}
              />
          </div>
          <div style={{height: "350px"}}>
            <Table 
                thead={metaData.table} 
                ref={tableRef}
                tbody={data} />
          </div>
          <div className={styles['row-between']} style={{width:'100%'}}>
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              width={"25%"}
              label={'Total BA'}
              ref={totalBaAvecRemise}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Total Retour'}
              width={"25%"}
              ref={totalRetour}
              disabled
              />
            <InputText  
              holderText={"Montant"} 
              setInputValue={setInputValue} 
              inputValue={inputValue} 
              type={"number"} 
              label={'Total'}
              ref={total}
              width={"25%"}
              disabled
              />
          </div>
        </div>
      </div>
    {/* {isActive && <PrintingLandscape type={achatAvoir.current} thead={PrintingColumns} ref={printRef} dataInfo={info}/>} */}
    </PageLayout>
  )
}

export default EtatAchatDetaille;
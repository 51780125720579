import React, { useEffect, useRef, useState } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import { icons } from '../../media/mediaHolder';
import styles from './bonReceptionTrensfer.module.scss';
import InputText from '../../components/input-text/InputText';
import SubInfo from '../../components/sub-info/SubInfo';
import Button from '../../components/button/Button';
import PageSideBar from '../../components/page-side-bar/PageSideBar';
import Table from '../../components/table/Table';
import articlesFinder from '../../api/articlesFinder';
import Dialog from '../../components/dialog/Dialog';
import AdderTable from '../../components/adder-table/adderTable';
import ConfirmationButton from '../../components/confimation-button/ConfirmationButton';
import ErrorContainer from '../../components/errorContainer/ErrorContainer';
import bonReceptionTransferFinder from '../../api/bonReceptionTransferFinder'
import { useNavigate } from 'react-router-dom';
import metaData from './bonReceptionTransfer.json';
import depotFinder from '../../api/depotFinder';
import depotsStockFinder from '../../api/depotsStockFinder';
import ValidatedMessages from '../../components/validated-messages/ValidatedMessages';

const BonReceptionTransfer = () => {
  const navigate = useNavigate();

  // Side page elements
  const codeInputRef = useRef(null);
  const dateInputRef = useRef(null);
  const truckInputRef = useRef(null);
  const driverInputRef = useRef(null);
  const depDepotInputRef = useRef(null);
  const arrDepotInputRef = useRef(null);
  const totalTextRef = useRef(null);

  useEffect(() => {
    const setCode = async () => {
      try {
        const response = await bonReceptionTransferFinder.get('/');
        const code = response.data.maxCode ? parseInt(response.data.maxCode) + 1 : 1;
        codeInputRef.current.setValue(code);
      }
      catch (err) {
        console.log(err);
      }
    }

    setCode();

    dateInputRef.current.setValue(new Date().toISOString().substring(0, 10));

  }, []);

  // Deal with depots
  const [depotsList, setDepotsList] = useState([]);

  useEffect(() => {
    const fetchDepots = async () => {
      try {
        const response = await depotFinder.get('/');
        setDepotsList(response.data.data.depots);
      }
      catch (err) {
        console.log(err);
      }
    }

    fetchDepots();

  }, []);

  // Dialog
  const displayDialog = useRef(null);

  // Articles adder table
  const articles = useRef(null);
  const articlesTable = useRef([]);
  const articlesRef = useRef();
  const [articlesInfo, setArticlesInfo] = useState([]);

  useEffect(()=>{
    const getArticles = async ()=>{
      const response = await depotsStockFinder.get("/")
      articlesRef.current = response.data.data.articles;
      setArticlesInfo(response.data.data.articles.map((article)=>{
       return {
         id: article.id,
         codeArticle: article.code,
         codeFamille: article.famille_code || "",
         designation: article.designation,
         depotName: article.depot_name,
         lot_nbr: article.ds_lot_nbr,
         quantity: article.ds_quantity || 0,
         ug: article.ug,
         puAchat: article.pu_achat,
         prixVenteGros: article.prix_vente_gros,
         puDetail: article.pu_detailer,
         prixVenteDemiGros: article.prix_vente_semi_gros,
         puSpecial: article.pu_special,
         unitMes: article.unit_mes,
         qtyCart: article.qte_cart,
         nbCart: article.nb_cart,
         puFact: article.pu_fournisseur,
         tva: article.tva,
         stkInit: article.stq_init,
         prixAchatInit: article.prix_achat_init,
         prixVenteInit: article.prix_vente_init,
         stockAlert: article.stock_alert,
         weight: article.weight,
         volume: article.volume,
         blocked: article.blocked ? 'Oui' : 'No',
         observation: article.observation
     }
      }));
      articlesTable.current.setAllData(response.data.data.articles.map((article)=>{
       return {
         id: article.id,
         codeArticle: article.code,
         codeFamille: article.famille_code || "",
         designation: article.designation,
         depotName: article.depot_name,
         lot_nbr: article.ds_lot_nbr,
         quantity: article.ds_quantity || 0,
         ug: article.ug,
         puAchat: article.pu_achat,
         prixVenteGros: article.prix_vente_gros,
         puDetail: article.pu_detailer,
         prixVenteDemiGros: article.prix_vente_semi_gros,
         puSpecial: article.pu_special,
         unitMes: article.unit_mes,
         qtyCart: article.qte_cart,
         nbCart: article.nb_cart,
         puFact: article.pu_fournisseur,
         tva: article.tva,
         stkInit: article.stq_init,
         prixAchatInit: article.prix_achat_init,
         prixVenteInit: article.prix_vente_init,
         stockAlert: article.stock_alert,
         weight: article.weight,
         volume: article.volume,
         blocked: article.blocked ? 'Oui' : 'No',
         observation: article.observation
     }
      }))
   } 
    
    getArticles();

  },[])

  const addSelectedArticles = () => {
    const articlesToAdd = articlesTable.current.sendSelectedItems().map((article)=>{
      return {
        id: article.id,
        codeArticle: article.codeArticle,
        quantityart: 0,
        puAchat: article.puAchat,
        lot_nbr: article.lot_nbr,
        total: 0,
        designation: article.designation,
        quantity: article.quantity,
        observation: article.observation
      };
    });

    tableRef.current.setAllData([...tableRef.current.getAllData(), ...articlesToAdd]);
  }
  const actOnValidation = async (article) => {
    let amount= 0;
    tableRef.current.getAllData().filter((articles)=>{
      return articles.id !== article.id
    }).map((article)=>{
      amount += article.total
    })

    tableRef.current.updateRow({
      id: article.id,
      codeArticle: article.codeArticle, 
      quantityart: article.quantityart || 0,
      puAchat: article.puAchat || 0,
      lot_nbr: article.lot_nbr,
      total: article.puAchat * article.quantityart || 0,
      designation: article.designation,
      quantity: article.quantity,
      observation: article.observation
    });
    const totalPrice = article.puAchat * article.quantityart;
    amount += totalPrice;
    totalTextRef.current.setValue(amount);
  }


  const handleDelete = (itemToDeleteId) => {

    const newArticlesArr = tableRef.current.getAllData().filter(item => item.id !== itemToDeleteId);
    tableRef.current.setAllData(newArticlesArr);
    const article = articlesRef.current.find(item => item.id === itemToDeleteId)
    console.log(article)
    articlesTable.current.addItem({
        id: article.id,
        codeArticle: article.code,
        codeFamille: article.famille_code || "",
        designation: article.designation,
        depotName: article.depot_name,
        lotNbr: article.ds_lot_nbr,
        quantity: article.ds_quantity || 0,
        ug: article.ug,
        puAchat: article.pu_achat,
        prixVenteGros: article.prix_vente_gros,
        puDetail: article.pu_detailer,
        prixVenteDemiGros: article.prix_vente_semi_gros,
        puSpecial: article.pu_special,
        unitMes: article.unit_mes,
        qtyCart: article.qte_cart,
        nbCart: article.nb_cart,
        puFact: article.pu_fournisseur,
        tva: article.tva,
        stkInit: article.stq_init,
        prixAchatInit: article.prix_achat_init,
        prixVenteInit: article.prix_vente_init,
        stockAlert: article.stock_alert,
        weight: article.weight,
        volume: article.volume,
        blocked: article.blocked ? 'Oui' : 'No',
        observation: article.observation
    });
    
    updateTotal(null, newArticlesArr);
  }
  const checkDepot = () =>{
    const depots = document.getElementById('Depot_departure').value
    if(!depots){
      errorsHandler.current.errorsHandler([{name:'depot' ,message:'Assurez que le Depot est bien selectionez'}]);
      depDepotInputRef.current.setBorderRed();
      return
    }
    displayDialog.current.display()
  }

  const handleDepot =(selectedDepot)=>{
    articlesTable.current.setAllData(articlesInfo.filter((article)=>{
      return (selectedDepot.name === article.depotName) /*&& (checkArticle(parseInt(article.id)) === 0)*/
    }))
    if(selectedDepot.id !== depDepotInputRef.current.getSelectedItem().id)
      tableRef.current.setAllData([]);
  }

  const updateTotal = (hotRow = null, articles) => {
    let amount = 0;

    tableRef.current.getAllData().filter((article)=>{
      return articles[0]?.id !== article.id
    }).map((article)=>{
      amount += article.total
      console.log(article)
    })
    totalTextRef.current.setValue(amount);
  }

  // Main table reference
  const tableRef = useRef(null);

  const handleSearch = val => tableRef.current.setFilterInput(val);

  // Validation and error handling related code
  const displayConfirmationDialog = useRef();

  const handelValidate = () => {
    const alerts = checkErrors();

    if(alerts) {
      errorsHandler.current.errorsHandler(alerts);
      return;
    }

    displayConfirmationDialog.current.display();
  }

  const errorsHandler = useRef([]);

  const checkErrors = () => {
    const alerts = [];

    const code = document.getElementById("code").value;
    const camion = document.getElementById('camion').value;
    const chauffeur = document.getElementById('chauffeur').value;
    const Depot_departure = (document.getElementById('Depot_departure').value).trim();
    const Depot_arrive = (document.getElementById('Depot_arrive').value).trim();
    
    if(!code) {
      alerts.push({name:'code', message:'Assurez que la code is bien saisez'});
      codeInputRef.current.setBorderRed();
    }
    if(!camion) {
      alerts.push({name:'camion', message:'Assurez que la Camion is bien saisez'});
      truckInputRef.current.setBorderRed();
    }
    if(!chauffeur) {
      alerts.push({name:'chauffeur', message:'Assurez que la Chauffeur is bien saisez'});
      driverInputRef.current.setBorderRed();
    }
    if(!Depot_departure) {
      alerts.push({name:'Depot_departure', message:'Assurez que le Depot departure est bien saisez'});
      depDepotInputRef.current.setBorderRed();
    }
    if(!Depot_arrive) {
      alerts.push({name:'Depot_arrive', message:'Assurez que le Depot arrive est bien saisez'});
      arrDepotInputRef.current.setBorderRed();
    }
    if(Depot_arrive === Depot_departure) {
      alerts.push({name:'conflict', message:'Assurez que le Depot departure et arrive est bien saisez'});
      depDepotInputRef.current.setBorderRed();
      arrDepotInputRef.current.setBorderRed();
    }
    if(codeExists(code) === true) {
      alerts.push({name:'code', message:'le N° du bon est deja exister'});
      codeInputRef.current.setBorderRed();
    }
    if(tableRef.current.getAllData().length === 0) {
      alerts.push({name:'articles', message:'Assurez que la Articles est bien selectionez'});
    }

    return alerts.length > 0 ? alerts : null;
  }

  const codeExists = async (id) => {
    const response = await bonReceptionTransferFinder.get(`/${id}`)
    return response.data.data.bonTransfer?.id === id;
  }

  const handelSubmit = async (e) => {
    e.preventDefault();
    const depotId = parseInt(arrDepotInputRef.current.getSelectedItem().id)
    
    // Resetting articles table to send to the backend 
    const articles = tableRef.current.getAllData().map( article => {
      return {
        id_depot_stock: article.id,
        id_depot: depotId,
        prixAchat: parseInt(article.puAchat),
        quantity: parseInt(article.quantityart), 
        // id_article: response.data.updatedData.data.rows[0].id_article,
        lot_nbr: article.lot_nbr,
        expire_date: new Date().toISOString().substring(0,10),
        purchase_date: document.getElementById('date').value,
        observation: article.observation
      }
    });

    try {
      bonReceptionTransferFinder.post("/", {
        code: parseInt(document.getElementById('code').value),
        date: document.getElementById('date').value,
        truck: document.getElementById('camion').value,
        driver: document.getElementById('chauffeur').value,
        idDepartureDepot: depDepotInputRef.current.getSelectedItem().id,
        idArrivalDepot: arrDepotInputRef.current.getSelectedItem().id,
        codeBon: document.getElementById('bon').value,
        articles: articles
      });

      validateHandler.current.validateHandler([{name: 'validate' , message: 'Bon est bien Valider'}]);
      setTimeout(()=> {
        navigate(-1)
      }, 1000);
    } 
    catch (err) {
        console.log(err);
    }
  }
  const validateHandler = useRef([]);

  return (
    <PageLayout icon={icons.achat} nameLayout={"Bon De Reception d'un Transfer"}>
      <form onSubmit={handelSubmit}>
        <div className={styles['body-container']}>
          <div className={styles['main-body']}>
            <div className={styles['actions-container']}>
              <div className={styles['row-evenly']}>
                <InputText
                  holderText={"Recharcher articles"} 
                  width={"50%"} 
                  labelPosition={true}
                  type={"text"}
                  label={'Rechercher'}
                  id={'Client'}
                  path={"/ajouter_client"}
                  btnName={'Ajouter Nouveau client'} 
                  icon={icons.search}
                  reportChange={handleSearch}
                />
                <Button 
                  name='Imprimer' 
                  width={"250px"}
                  icon={icons.impr} />
              </div>

              <div className={styles['row-evenly']}>
                  <Button 
                    name='Ajouter Des Article' 
                    width={"200px"}
                    onclick={checkDepot} 
                    />
                    <InputText
                      holderText={"Code Bon Transfer"} 
                      width={"250px"}
                      type={"text"}
                      id={'bon'}
                    />
              </div>

              <div style={{height: "450px"}}>

                <Table thead={metaData.table} 
                       tbody={[]} 
                       ref={tableRef} 
                       edit={true} 
                       confirmBeforeDeletion={false}
                       remove={true}
                       handleDelete={handleDelete}
                       defaultSearchBy={["designation"]}
                       actOnValidation={actOnValidation} />

                <div style={{display: 'flex',gap: '50px'}}> 
                    <SubInfo 
                      inline
                      name={"Poids"} 
                      unit={"KG"}/>
                    <SubInfo 
                      inline 
                      name={"Volume"} 
                      unit={"L"}/>
                </div>
              </div>
            </div>
          </div>

          <div className={styles['side-body']}>
          <PageSideBar validateFunc={handelValidate}>
              <div className={styles['col-around']}>
                <div className={styles['col-evenly']}>
                <InputText 
                  label={"N° du bon"}
                  holderText={"0001"}
                  width={"100%"} type={"number"}
                  id={"code"}
                  ref={codeInputRef}/>
                <InputText 
                  label={"Date de bon"}  
                  width={"100%"}
                  type={"date"}
                  id={"date"}
                  ref={dateInputRef}/>
                <InputText 
                  label={"Camion"}
                  holderText={"Camion"}
                  id={'camion'} 
                  width={"100%"}
                  ref={truckInputRef}/>
                <InputText 
                  label={"Chauffeur"} 
                  holderText={"Chauffeur"} 
                  id={'chauffeur'}
                  width={"100%"}
                  ref={driverInputRef}/>

                <InputText 
                  label={"Depot departure"} 
                  holderText={"Sélectionner un depot"} 
                  width={"100%"}
                  id={'Depot_departure'}
                  onSelectItem={handleDepot}
                  ref={depDepotInputRef}
                  searchData={depotsList} />
                <InputText 
                  label={"Depot arrive"} 
                  holderText={"Sélectionner un depot"} 
                  width={"100%"}
                  id={"Depot_arrive"}
                  ref={arrDepotInputRef}
                  searchData={depotsList} />
                </div>

                <div className={styles['col-evenly']} style={{marginTop: "0px"}}>
                    <SubInfo label={"Montant"} 
                             unit={"DZD"}
                             ref={totalTextRef} />
                </div>
              </div>
          </PageSideBar>  
        </div>

        <Dialog width={"80%"} table={true} ref={displayDialog} validateFunc={addSelectedArticles}>
          <AdderTable head={metaData.adderTable} body={[]} ref={articlesTable} />
        </Dialog>

      </div>

        <ConfirmationButton ref={displayConfirmationDialog}/>

      </form>

      <ErrorContainer ref={errorsHandler}/>
      <ValidatedMessages ref={validateHandler}/>
    </PageLayout>
  )
}

export default BonReceptionTransfer;

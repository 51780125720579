import React, { useEffect, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import metaData from './consultationVentes.json'
import styles from './consultationventes.module.scss'
import { useState } from 'react'
import SubInfo from '../../components/sub-info/SubInfo'
import bonVentesFinder from '../../api/bonVentesFinder'
import articleLivreFinder from '../../api/articleLivreFinder'
import Printing from '../../components/printing/Printing'
import creditClientFinder from '../../api/creditClientFinder'
import bonAvoirFinder from '../../api/bonAvoirFinder'
import fournisseurFinder from '../../api/fournisseurFinder'
import clientsFinder from '../../api/clientsFinder'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { fixDecimalDigits, spacedNumFormat } from '../../utils/numberFormatter'
import depotsStockFinder from '../../api/depotsStockFinder'
import ErrorContainer from '../../components/errorContainer/ErrorContainer'

const ConsultationVentes = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const errorsHandler= useRef([]);
  const [data, setData] = useState([]);
  const [dataSuiv, setDataSuiv] = useState([]);
  const [bonsInfo,setBonsInfo] = useState([]);
  const [refreshTable,setRefreshTable] = useState(false);

  useEffect(()=>{
    metaData.thead_detailleBon.find(el => el.name === "Livre").children 
                        = [{id: 0, name: 'Oui'}, {id: 1, name: 'Non'}];
  },[]);

  const handleDelete = async (key,obj)=> {
    try {

      const alerts = [];
      if(obj.venteAv === 'vente')
      {
        // const selectedBon = bonsInfo.find((bon)=> parseInt(bon.id) === parseInt(obj.id))
        // removing 
        // for(let i=0;i<selectedBon.articlesAdded.length;i++){
        //   const idStockDepot = await articleLivreFinder.get(`/${selectedBon.articlesAdded[i].id}/getDepotStock/id`)
        //   await depotsStockFinder.put(`/${parseInt(idStockDepot.data.data.idStockDepot)}`, {
        //     quantity: parseInt(selectedBon.articlesAdded[i].quantity) + parseInt(selectedBon.articlesAdded[i].depot_stock_quantity)
        //   });
        // }
        // removing 
        bonVentesFinder.delete(`/${obj.id}`);
        const updatedData = data.filter((item) => item['id'] !== obj.id);
        tableRef.current.setAllData(updatedData)
        tableRefSuiv.current.setAllData([])
      }
      else if(obj.venteAv === 'avoir'){
        try {
          const deleteAvoir =  async () =>{
              // const selectedBon = bonsInfo.find((bon)=> parseInt(bon.id) === parseInt(obj.id))
              // for(let i=0;i<selectedBon.articlesAdded.length;i++){
              //   // removing 
              //   if(selectedBon.articlesAdded[i].quantity <= selectedBon.articlesAdded[i].ds_quantity){
              //     const idStockDepot = await articlesAvoirFinder.get(`/${selectedBon.articlesAdded[i].id}/getDepotStock/id`)
              //     await depotsStockFinder.put(`/${parseInt(idStockDepot.data.data.idStockDepot)}`, {
              //       quantity: parseInt(selectedBon.articlesAdded[i].ds_quantity) - parseInt(selectedBon.articlesAdded[i].quantity)
              //     });
              //   // removing 
              //   }else{
              //     alerts.push({name:'quantity',message:'Vous ne pouvez pas effectuer cette operation'});
              //     return;
              //   }
              // }
              const bonVente = await bonAvoirFinder.get(`/${obj.code}/vente`);
              bonAvoirFinder.delete(`/${bonVente.data.data.bons[0].id}`);
              const updatedData = data.filter((item) => item['id'] !== obj.id);
              tableRef.current.setAllData(updatedData)
              tableRefSuiv.current.setAllData([]);
          }
          deleteAvoir();
        } catch (err) {
          console.log(err);
        }
        if(alerts.length > 0) {
          errorsHandler.current.errorsHandler(alerts);
          return;
        }
      }

    } catch (err) {
      console.log(err);
    }
  }

  const actOnValidation = async (obj) => {
    const date = new Date(obj.date);
    const now = Date.now();
    const currentHours = new Date(now).getHours();
    const currentMinutes = new Date(now).getMinutes();
    const currentSeconds = new Date(now).getSeconds();
    date.setHours(currentHours+1);
    date.setMinutes(currentMinutes);
    date.setSeconds(currentSeconds);

    let client = obj.client.split('/');
    let fournisseur = obj.fournisseur.split('/');
    
    const response = await clientsFinder.get(`/${client[0]}`);
    const clientDetail = response.data.data.client;
    if(fournisseur[0]){
    await clientsFinder.put(`/${clientDetail.id}`,{
      id_wilaya:clientDetail.id_wilaya,
      code: clientDetail.code,
      name: clientDetail.name,
      address: clientDetail.address,
      activity: clientDetail.activity,
      fax: clientDetail.fax,
      phone: clientDetail.phone,
      email: clientDetail.email,
      n_register: clientDetail.n_register,
      n_article: clientDetail.n_article,
      n_fiscal: clientDetail.n_fiscal,
      nis: clientDetail.nis,
      nif: clientDetail.nif,
      type_client: clientDetail.type_client,
      blocked: clientDetail.blocked,
      credit_max: clientDetail.credit_max,
      delais_vers_max: clientDetail.delais_vers_max,
      fournisseur:fournisseur[1],
      code_fournisseur:fournisseur[0]
    });}

    if(obj.venteAv === 'vente'){
      const bonVente = await bonVentesFinder.get(`/${obj.code}`);
      const bon = bonVente.data.data.bon;
      const bonUpdated = await bonVentesFinder.put(`/${parseInt(obj.id)}`,{
        typePayment: obj.type_payment || bon.payment_type,
        remise: obj.remise || bon.remise,
        typeRemise: obj.type_remise || bon.type_remise,
        date:date,
        idClient:clientDetail.id
      });
      const updatedData = bonsInfo.filter((item) => item['id'] !== obj.id);
      setBonsInfo(updatedData);
  }else {
    const bonVente = await bonAvoirFinder.get(`/${obj.code}/vente`);
    const bon = bonVente.data.data.bons;
    
    const bonUpdated = await bonAvoirFinder.put(`/${parseInt(bon[0].id)}`,{
      typePayment: obj.type_payment || bon.payment_type,
      remise: obj.remise || bon.remise,
      typeRemise: obj.type_remise || bon.type_remise,
      date:date,
      idClient:clientDetail.id
    });
    const updatedData = bonsInfo.filter((item) => item['id'] !== obj.id);
    setBonsInfo(updatedData);
  }
  // setRefreshTable(!refreshTable);
  }

  const actOnValidationArticle = async (obj) => {
    const date = new Date(obj.date_peremtion);
    const now = Date.now();
    const currentHours = new Date(now).getHours();
    const currentMinutes = new Date(now).getMinutes();
    const currentSeconds = new Date(now).getSeconds();
    date.setHours(currentHours);
    date.setMinutes(currentMinutes);
    date.setSeconds(currentSeconds);

    if(obj.venteAv === 'vente'){
      let temp = await articleLivreFinder.put(`${parseInt(obj.id)}`,{
      quantity: obj.quantity,
      prixVente: parseFloat(obj.PUVente),
      remise: obj.remise,
      remiseType: obj.remiseType || 'parcentage',
      ug: obj.ug,
      delivered: obj.delivered === 'Oui' ? true : false,
      expire_date: date,
      observation: obj.observation,
    });
  }else if(obj.achat_av === 'avoir'){
    const articleAvoir = await articlesAvoirFinder.get(`/${obj.code}/achat`);
    const article = await articleAvoir.data.data.articles;
    
    const articleUpdated = await articlesAvoirFinder.put(`/${parseInt(article[0].id)}`,{
      quantity:obj.quantity,
      prixAchat:obj.PUVente,
      remise:obj.remise,
      remiseType:obj.remiseType,
      ug:obj.ug,
      delivered:obj.delivered,
      expire_date:date,
      observation:obj.observation,
    });
  }

  }

  const handleDeleteArticle = async (key,obj)=> {
    const alerts = [];
    if(obj.venteAv === 'vente'){
      try{
        // removing 
        // const idStockDepot = await articleLivreFinder.get(`/${obj.id}/getDepotStock/id`)
        // await depotsStockFinder.put(`/${(parseInt(idStockDepot.data.data.idStockDepot))}`, {
        //   quantity: parseInt(obj.quantity) + parseInt(obj.quantityStq)
        // });
        // removing 

        await articleLivreFinder.delete(`/${obj.id}`);
        const updatedData = tableRefSuiv.current.getAllData().filter((item) => item['id'] !== obj.id);
        tableRefSuiv.current.setAllData(updatedData)
      }catch (err){
        console.log(err)
      }
        
    }else if(obj.venteAv === 'avoir'){
      const deleteAvoir =  async () =>{
        try {
          if(obj.quantity <= obj.quantityStq){
            // removing 
            const idStockDepot = await articlesAvoirFinder.get(`/${obj.id}/getDepotStock/id`)
            await depotsStockFinder.put(`/${(parseInt(idStockDepot.data.data.idStockDepot))}`, {
              quantity: parseInt(obj.quantityStq) - parseInt(obj.quantity)
            });
            // removing 
            articlesAvoirFinder.delete(`/${obj.id}`);
            const updatedData = data.filter((item) => item['id'] !== obj.id);
            tableRef.current.setAllData(updatedData);
            tableRefSuiv.current.setAllData([]);
          }else{
            alerts.push({name:'quantity',message:'Vous ne pouvez pas effectuer cette operation'});
          }
        } catch (err) {
          console.log(err);
        }
        }
        deleteAvoir();
    }
    if(alerts.length > 0) {
      errorsHandler.current.errorsHandler(alerts);
      return;
    }
  }
  
  useEffect(()=>{
    const getFournisseurs = async ()=>{
      const response = await fournisseurFinder.get("/");
      const fournisseurs = response.data.data.fournisseurs.map(four =>{
        return({
          name:`${four.code}/${four.name}`
        });
      });
      metaData.thead_bonLivraison.find(el => el.name === "Code/Fournisseur").children = fournisseurs;
    }
    const getClients = async ()=>{
      const response = await clientsFinder.get("/");
      const clients = response.data.data.clients.map(client =>{
        return({
          name:`${client.code}/${client.name}`
        });
      });
      metaData.thead_bonLivraison.find(el => el.name === "Code/Client").children = clients;
    }
    getClients();
    getFournisseurs();
  },[]);

  useEffect(() => {
    const fetchBonsVenteAvoir = async () => {
      const bonVenteRes = await bonVentesFinder.get('/');
      const bonAvoirRes = await bonAvoirFinder.get('/vente');
      let data = bonAvoirRes.data.data.bons.map((bon,id) => {
        return {
          ...bon,
          id: String(parseInt(id) + parseInt(bonVenteRes.data.data.max) + 1),
          type: 'avoir'
        }
      })

      for(let i=0;i<bonVenteRes.data.data.bons.length;i++)
        data.push(bonVenteRes.data.data.bons[i]);
      
      data = data.sort((a, b) => {
        return new Date(b.date) - new Date(a.date); // Sort based on date 
      });

      setBonsInfo(data);
      
      let empty = '';
      const bons = [];
      for (let i = 0; i < data.length; i++) {
        const bon = data[i];
        const client = await creditClientFinder.get(`/${bon.id_client}`);
        let montant = bon.amount * (1 -bon.remise/100);
        const { credit } = client.data.data;

        bons.push({
          id: bon.id,
          venteAv: bon?.type === 'avoir' ? 'avoir': 'vente',
          code: bon.code,
          date: bon.date.substring(0, 10),
          heur: bon.date.substring(11, 16),
          montant: spacedNumFormat(fixDecimalDigits(montant)),
          client: `${bon.client_code}/${bon.client_name}`,
          credit: spacedNumFormat(fixDecimalDigits(credit)),
          nbBlAchat: bon.codeBon || '/',
          fournisseur:`${bon.client_code_founisseur || empty}/${bon.client_fournisseur || empty}`,
          blocked: bon.blocked ? 'Oui' : 'Non',
          remise: bon.remise,
          reglementClient: bon.payment_type,
          saisiPar: bon.user_name || empty,
          nbImpression: bon.nbrImpr || empty, 
          servi: bon.user_name || empty
        });
      }
      setData(bons);
    }
    fetchBonsVenteAvoir();
  },[refreshTable]);

  const handleClick = async (rowInfo)=>{
    if(!rowInfo){
      setDataSuiv([]);
      return
    }
    
    bonsInfo.map((element)=>{
      if(element.id === rowInfo.id)
      setDataSuiv(
        element.articlesAdded.map((article,index)=>{
          return {
            id: article.id || index,
            venteAv:element?.type || 'vente',
            code: article.code,
            designation: article.designation,
            lotNbr: article.lotNbr,
            expireDate: String(article.expire_date.substring(5,7)+'/'+article.expire_date.substring(0,4)),
            quantity: article.quantity,
            PUVente: spacedNumFormat(article.prix),
            remise: article.remise,
            PURemise: spacedNumFormat(article.prix * (1 - article.remise/100)),
            ug: article.ug || 0,
            total: article.prix * article.quantity,
            quantityStq: article.depot_stock_quantity || article.ds_quantity || 0,
            delivered: article.delivered ? 'Oui' : 'Non',
            dateLivred: element.date.substring(0,10),
            blocked: article.blocked ? 'Oui' : 'Non',
            qteCart: article.qte_cart,
            nbCart: Math.floor(parseInt(parseInt(article.quantity)+parseInt(article.ug))/parseInt(article.qte_cart)) || 0,
            observation: article.observation
          }
        })
      )
    })
  }

  const filterByArr = ['code','venteAv'];
  const handleSearch = (val) => tableRef.current.setFilterInput(val)

  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "40%"},
    {name: "Lot",width: "5%"},
    {name: "Exp",width: "10%"},
    {name: "Qte",width: "5%"},
    {name: "UG",width: "5%"},
    {name: "PU",width: "10%"},
    {name: "RM%",width: "5%"},
    {name: "Total",width:"10%"}
  ]
  
  const tableRef = useRef();
  const tableRefSuiv = useRef();
  const printRef = useRef ();

  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
    setIsActive(true);
    const selectedBon = bonsInfo.find((element)=> element.id === rowInfo[0].id);

    const articlesAdded = tableRefSuiv.current.getAllData().map((art)=>{
      return{
        code: art.code,
        designation: art.designation,
        lotNbr: art.lotNbr,
        expireDate: art.expireDate,
        quantity: art.quantity,
        ug: art.ug,
        PUVente: art.PUVente,
        remise: art.remise,
        total: art.total
      }
    });
    
    const ROWS_PER_PAGE = 20; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );

    const totalBeforeDiscounts = tableRefSuiv.current.getAllData().reduce((accumulator, article) => {

      return accumulator += parseFloat(article.PUVente) * parseInt(article.quantity);
    }, 0);

    const totalDiscounts = tableRefSuiv.current.getAllData().reduce((accumulator, article) => {
      return accumulator += (parseFloat(article.PUVente) - parseFloat(article.PURemise)) * parseInt(article.quantity);
    }, 0);
    const data = {
      rowInfo: selectedBon,
      articlesAdded: pages,
      validerPar: rowInfo[0].saisiPar,
      globalInfo: {
        totalBeforeDiscounts,
        totalDiscounts,
        totalAfterDiscounts: totalBeforeDiscounts - totalDiscounts
      }
    }
    
    setInfo(data);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      printRef.current.print()
    }, 100);
  }

  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation des Ventes'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <InputText 
                  icon={icons.search}
                  type={"text"}
                  holderText={"Chercher Par N° Bon"}
                  label={"Chercher"}
                  labelPosition={true}
                  width={"50%"}
                  reportChange={(val) => handleSearch(val)}
                  />  
                <div className={styles['row-end']} >
                  <Button 
                    icon={icons.impr}
                    width={"350px"}
                    name={'Imprimer'} 
                    onclick={handlePrint}
                    />
                  <Button 
                    icon={icons.reglementClient}
                    link={true} 
                    path={"../reglement_client"}
                    width={"450px"}
                    name={'Réglement Client'} 
                    />
                </div>
              </div>
              <div style={{height: '300px'}}>
                <Table tableTitle={"Bon de Livraison"}
                        sendSelectedRowInfo={handleClick} 
                        handleDelete={handleDelete}
                        actOnValidation={actOnValidation}
                        thead={metaData.thead_bonLivraison} 
                        singular={true}
                        tbody={data} 
                        ref={tableRef}
                        edit={user?.permissions.includes(permissions.canEditInTable)} 
                        remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                        defaultSearchBy={filterByArr}
                        />
              </div>
            <div className={styles['row-end']} style={{width:'100%',marginTop: "40px"}}>
              <SubInfo inline label={"Sold Client :"}  unit={"DZD"}/>
            </div>
            <div style={{height: '300px'}}>
              <Table 
                tableTitle={"Détail du Bon"} 
                ref={tableRefSuiv} 
                handleDelete={handleDeleteArticle}
                actOnValidation={actOnValidationArticle}
                thead={metaData.thead_detailleBon} 
                tbody={dataSuiv}
                edit={user?.permissions.includes(permissions.canEditInTable)} 
                remove={user?.permissions.includes(permissions.canDeleteFromTable)} 
                />
            </div>
            </div>
        
    </div>
    {isActive && <Printing type={'Bon De Livraison'} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  );
}

export default ConsultationVentes;

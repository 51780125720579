import React, { useEffect, useState, useRef } from 'react'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import metaData from './articlesVendu.json'
import styles from './articlesVendu.module.scss'
import { icons} from '../../media/mediaHolder.js'
import PageLayout from '../../components/page-layout/PageLayout'
import articleLivreFinder from '../../api/articleLivreFinder.js'
import articlesAvoirFinder from '../../api/articlesAvoirFinder.js'
import { dateFormatter } from '../../utils/dateFormatter.js'

const ArticlesNoVendu = () => {

  const [inputValue, setInputValue] = useState('');
  const [data,setData] = useState([])

  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const totalVente = useRef();
  const totalRetour = useRef();
  const totalComptoir = useRef();
  const globalAmount = useRef();


  useEffect(() => {
    

    const fetchBonsVenteAvoir = async () => {
      try {
        let montantVentes = .0;
        let montantRetour = .0;
        const articlesVendueRes = await articleLivreFinder.get('/');
        const articlesAvoirRes = await articlesAvoirFinder.get('/vente');

        const { max_id } = articlesVendueRes.data.data;

        const articlesVendue = articlesVendueRes.data.data.articles.map((article) => {
          // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING IT PROMOTION
          let montant = ( article.quantity * article.prix) * (1 - (article.remise/100));
          // 

          montantVentes += montant;

          return{
                id : article.id,
                Vente_av: 'Vente',
                lot: article.lotNbr,
                date: dateFormatter(article.bon_achat_date,3),
                code_article: article.code,
                code_bon: article.bl_code,
                expire_date: dateFormatter(article.expire_date,3),
                quantity:article.quantity,
                prix:article.prix,
                montant
              }
          });

          const articlesAvoir = articlesAvoirRes.data.data.articles.map((article) => {
            // CALCULATE THE TOTAL PRICE OF THE ARTICLE INCLUDING IT PROMOTION
            let montant = (article.prix_achat * article.quantity) * (1 - (article.remise / 100));
            // 
            montantRetour += montant;
            // 
            
            let id = parseInt(article.id) + parseInt(max_id);

            return{
              id,
              Vente_av: 'Avoir',
              lot: article.lotNbr,
              date: dateFormatter(article.bon_avoir_date,3),
              code_article: article.code,
              code_bon: article.bon_avoir_code,
              expire_date: dateFormatter(article.expire_date,3),
              quantity: article.quantity,
              prix: article.prix_achat,
              montant
            }
          });
          totalVente.current?.setValue(montantVentes.toFixed(3));
          totalRetour.current?.setValue(montantRetour.toFixed(3));  
          totalComptoir.current?.setValue('0');
          globalAmount.current?.setValue((montantVentes - montantRetour).toFixed(3));

          setData([...articlesVendue,...articlesAvoir].sort((a,b) => b.date - a.date));
      } catch (err) {
        console.log(err);
      }
    }
    fetchBonsVenteAvoir();
  }, []);

  return (
    <PageLayout icon={icons.article} nameLayout={'Articles Vendu'}>
      <div style={{width :"100%" , height: "100%" , padding: "2% 10% 2% 10%"}}>
        <div className={styles['main-search-bar']}>
          <div className={styles['periode']}>
            <InputText 
              width={'20%'} 
              type={"date"}  
              label={'Jour 1'}
              margin={'0px 0px 0px 15px'}
              labelPosition={true}
              reportChange={reportDate1Change}
              />
            <InputText 
              width={'20%'} 
              type={"date"}  
              label={'Jour 2'}
              margin={'0px 0px 0px 15px'} 
              labelPosition={true}
              reportChange={reportDate2Change}
              />
          </div>
          <div className={styles['operations-buttons']}>
            <Button name={'Imprimer'} icon={icons.impr} width={"150px"}/>
          </div>
        </div>
        
        <div className={styles['table']}>
          <Table 
            thead={metaData.table} 
            inputValue={inputValue} 
            tbody={data}
            date1={date1} 
            date2={date2}
            />
        </div>

        <div className={styles['mantant-chargers']}>
          <div>
              <InputText 
                width={'100%'}
                type={"number"} 
                holderText={"Montant"} 
                label={'Montant Des Vente'}
                margin={'0px 15px 0px 0px'}
                ref={totalVente}
                disabled
              />
          </div>
          <div>
            <InputText 
              width={'100%'}
              type={"number"}
              holderText={"Montant"} 
              label={'Montant Des Retours'}
              margin={'0px 15px 0px 0px'}
              ref={totalRetour}
              disabled/>
          </div>
          <div>
            <InputText 
              width={'100%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Des Comptoirs'}
              margin={'0px 15px 0px 0px'}
              ref={totalComptoir}
              disabled/>
          </div>
          <div style={{display: "flex"}}>
            <InputText 
              width={'100%'}
              type={"number"} 
              holderText={"Montant"} 
              label={'Montant Global'}
              margin={'0px 15px 0px 0px'}
              ref={globalAmount}
              disabled/>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default ArticlesNoVendu;

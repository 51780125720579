import React, { useEffect, useRef } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Button from '../../components/button/Button'
import Table from '../../components/table/Table'
import metaData from './consultationAchat.json'
import styles from './consultationachat.module.scss'
import { useState } from 'react'
import SubInfo from '../../components/sub-info/SubInfo'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import Printing from '../../components/printing/Printing'
import detteFournisseurFinder from '../../api/detteFournisseurFinder'
import bonAvoirFinder from '../../api/bonAvoirFinder'
import fournisseurFinder from '../../api/fournisseurFinder'
import clientsFinder from '../../api/clientsFinder'
import articlesAcheteFinder from '../../api/articlesAcheteFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import articleLivreFinder from '../../api/articleLivreFinder'
import { useAuth } from '../../contexts/AuthContext';
import permissions from '../../utils/permissions';
import ErrorContainer from '../../components/errorContainer/ErrorContainer'

const ConsultationAchat = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [data, setData] = useState([]);
  const [dataSuiv, setDataSuiv] = useState([]);
  const [bonsInfo,setBonsInfo] = useState([]);
  const printRef = useRef ();
  const [refreshTable,setRefreshTable] = useState(false);

  const articleTableRef = useRef();

  useEffect(()=>{
    metaData.thead_detailleBon.find(el => el.name === "Livre").children 
                        = [{id: 0, name: 'Oui'}, {id: 1, name: 'Non'}];
  },[]);

  const handleDelete =  async (key,obj)=> {
    try {
    if(obj.achat_av === 'achat'){

      bonAchatsFinder.delete(`/${key}`);
      const updatedData = data.filter((item) => item['id'] !== key);
      tableRef.current.setAllData(updatedData); 

    }else if(obj.achat_av === 'avoir'){

      const bonVente = await bonAvoirFinder.get(`/${obj.code}/achat`);

      bonAvoirFinder.delete(`/${bonVente.data.data.bons[0].id}`);
      const updatedData = data.filter((item) => item['id'] !== key);
      tableRef.current.setAllData(updatedData);
      
    }else if(obj.achat_av === 'avoir'){

      const bonVente = await bonAvoirFinder.get(`/${obj.code}/achat`);
      bonAvoirFinder.delete(`/${bonVente.data.data.bons[0].id}`);
      const updatedData = data.filter((item) => item['id'] !== key);
      setData(updatedData); 

    }
  } catch (err) {
    console.log(err);
  }
  }  
  const actOnValidation = async (obj) => {

    try {
      
      const date = new Date(obj.date);
      const now = Date.now();
      const currentHours = new Date(now).getHours();
      const currentMinutes = new Date(now).getMinutes();
      const currentSeconds = new Date(now).getSeconds();
      date.setHours(currentHours);
      date.setMinutes(currentMinutes);
      date.setSeconds(currentSeconds);
  
      let client = obj.client.split('/');
      let fournisseur = obj.fournisseur.split('/');
  
      const response = await fournisseurFinder.get(`/${fournisseur[0]}`);
      const fournisseurDetaile = response.data.data.fournisseur;
  
      if(client[0]){
        await fournisseurFinder.put(`/${fournisseurDetaile.id}`,{
          fournisseur: fournisseurDetaile.name, 
          personne_contact: fournisseurDetaile.personne_contact,
          address: fournisseurDetaile.address,
          wilaya: fournisseurDetaile.wilaya,
          tel: fournisseurDetaile.tel,
          fax: fournisseurDetaile.fax,
          mobile: fournisseurDetaile.mobile,
          email: fournisseurDetaile.email,
          code_client: client[0],
          client: client[1],
          blocked: fournisseurDetaile.blocked === "Non" ? false : true
        });
      }
      if(obj.achat_av === 'achat'){
      const bonAchat = await bonAchatsFinder.get(`/${obj.code}/code`);
      await bonAchatsFinder.put(`${parseInt(obj.id)}`,{
        typePayment: obj.reglement || bonAchat.data.data.bon.payment_type, 
        remise: obj.remise || bonAchat.data.data.bon.remise,
        typeRemise: bonAchat.data.data.bon.remise_type,
        date: date || bonAchat.data.data.bon.date,
        idFourniseeur:fournisseurDetaile.id
      });
    }else if(obj.achat_av === 'avoir'){
      const bonVente = await bonAvoirFinder.get(`/${obj.code}/achat`);
      const bon = bonVente.data.data.bons;
  
      const bonUpdated = await bonAvoirFinder.put(`/${parseInt(bon[0].id)}`,{
        typePayment: obj.type_payment || bon.payment_type,
        remise: obj.remise || bon.remise,
        typeRemise: obj.type_remise || bon.type_remise,
        date:date,
        idFournisseur:fournisseurDetaile.id
      });
    }

    } catch (err) {
      console.log(err);
    }

  // setRefreshTable(!refreshTable);
  }

  const actOnValidationArticle = async (obj) => {
    const date = new Date(obj.date_peremtion);
    const now = Date.now();
    const currentHours = new Date(now).getHours();
    const currentMinutes = new Date(now).getMinutes();
    const currentSeconds = new Date(now).getSeconds();
    date.setHours(currentHours);
    date.setMinutes(currentMinutes);
    date.setSeconds(currentSeconds);

    if(obj.achat_av === 'achat'){

    try{
      await articlesAcheteFinder.put(`${parseInt(obj.id)}`,{
      quantity: obj.quantity,
      prixAchat: obj.PUAchat,
      remise: obj.remise,
      remiseType: obj.remiseType || 'parcentage',
      ug: obj.ug,
      delivered: obj.delivered === 'Oui' ? true : false,
      expire_date: date,
      observation: obj.observation,
    });

      // tableRefSuiv.current.updateRow({
      //   id: obj.id,
      //   achat_av:'achat',
      //   code: obj.code,
      //   quantity: obj.quantity,
      //   remise: obj.remise,
      //   PUAchat: obj.prix,
      //   total: obj.prix * obj.quantity,
      //   designation: obj.designation,
      //   quantityStq: obj.ds_quantity,
      //   delivered: obj.delivered ? 'Oui' : 'Non',
      //   ug: obj.ug,
      //   date_peremtion: date, 
      //   observation: obj.observation
      // });

    }catch(err){
      console.log(err);
    }

  }else if(obj.achat_av === 'avoir'){
    await bonAvoirFinder.put(`/${parseInt(obj.id)}`,{
      quantity:obj.quantity,
      prixAchat:obj.PUAchat,
      remise:obj.remise,
      remiseType:obj.remiseType,
      ug:obj.ug,
      delivered:obj.delivered,
      expire_date:date,
      observation:obj.observation,
    });
  }

  }

  const handleDeleteArticle = async (key, obj) => {
    try {
      
      if(obj.achat_av === 'achat'){
        articlesAcheteFinder.delete(`/${key}`);
        const updatedData = tableRefSuiv.current.getAllData().filter((item) => item['id'] !== key);
        tableRefSuiv.current.setAllData(updatedData); 
      }else if(obj.achat_av === 'avoir'){
        articlesAvoirFinder.delete(`/${key}`);
        const updatedData = tableRefSuiv.current.getAllData().filter((item) => item['id'] !== key);
        tableRefSuiv.current.setAllData(updatedData); 
      }
      
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    const fetchBonsAchat = async () => {
      const response = await bonAchatsFinder.get('/');
      const response2 = await bonAvoirFinder.get('/achat');
      
      let data = response2.data.data.bons.map((bon,id)=>{
        return {...bon, id : String(parseInt(id)+parseInt(response.data.data.max_id)+1),type: 'avoir'}
      });

      for(let i=0;i<response.data.data.bons.length;i++){
        data.push(response.data.data.bons[i]);
      }
      
      data = data.sort((a, b) => {
        return new Date(b.date) - new Date(a.date); // Sort based on date 
      })
      setBonsInfo(data);

      const bons = [];
      for (let i = 0; i < data.length; i++) {
        const bon = data[i];
        const detteFournisseur = await detteFournisseurFinder.get(`/${bon.id_fournisseur}`);
        const { solde } = detteFournisseur.data.data;
        
        let montant = bon.amount * (1 - bon.remise/100);
        bons.push(
          {
            id: bon.id,
            achat_av: bon?.type === 'avoir' ? 'avoir': "achat" ,
            code: bon.code,
            date: bon.date.substring(0, 10) || '',
            heur: bon.date.substring(11, 16) || '',
            montant,
            fournisseur: `${bon.fournisseur_code || '' }/${bon.fournisseur_name || '' }`,
            solde,
            nbrBonVente: bon.code,
            client: `${bon.fournisseur_code_client || ''}/${bon.fournisseur_client || ''}`,
            remise: bon.remise,
            totalRM: (bon.amount * bon.remise)/100,
            reglement: bon.payment_type,
            saisiPar: bon.user_name,
            blocked: bon.blocked ? 'Oui' : 'Non',
            observation: ''
          }
        );
      }
      setData(bons);
    }
    try {
      fetchBonsAchat();
    } catch (err) {
      console.log(err);
    }
  },[refreshTable]);
  useEffect(()=>{
    const getFournisseurs = async ()=>{
      const response = await fournisseurFinder.get("/");
      const fournisseurs = response.data.data.fournisseurs.map(four =>{
        return({
          name:`${four.code}/${four.name}`
        });
      });
      metaData.thead_bonLivraison.find(el => el.name === "Code/Fournisseur").children = fournisseurs;
    }
    const getClients = async ()=>{
      const response = await clientsFinder.get("/");
      const clients = response.data.data.clients.map(client =>{
        return({
          name:`${client.code}/${client.name}`
        });
      });
      metaData.thead_bonLivraison.find(el => el.name === "Code/Client").children = clients;
    }
    getClients();
    getFournisseurs();
  },[]);

  const handleClick = async (rowInfo)=>{
    if(!rowInfo){
      setDataSuiv([]);
      return
    }
    bonsInfo.map((element)=>{
      
      if(element.id === rowInfo.id)
        setDataSuiv(
         element.articlesAdded.map((article)=>{
          return {
            id: article.id,
            achat_av: element?.type || 'achat',
            code: article.code,
            quantity: article.quantity,
            remise: article.remise,
            PUAchat: article.prix,
            total: article.prix * article.quantity,
            designation: article.designation,
            quantityStq: article.ds_quantity,
            delivered: article.delivered ? 'Oui' : 'Non',
            ug: article.ug,
            date_peremtion: article.expire_date?.substring(0,10), 
            observation: article.observation
          }
        })
      );
    });
  }
  const [inputValue, setInputValue] = useState('');
  const achatAvoir= useRef();
  const filterByArr = ['code'];
  const handleSearch = (val) => {
    setInputValue(val);
  }
  
  const [isActive, setIsActive] = useState(false);
  const [info,setInfo] = useState();
  const timeoutRef = useRef();
  const tableRef = useRef();
  const tableRefSuiv = useRef();
  const errorsHandler= useRef([]);

  const handlePrint = ()=>{
    const rowInfo = tableRef.current.getSelectedItems();
    if(!rowInfo[0]?.id) {
      errorsHandler.current.errorsHandler([{name: 'bon' , message: 'Assurez que le bon est bien selectionez'}]);
      return
    }
  
    setIsActive(true);
    const selectedBon = bonsInfo.find((element)=> element.id === rowInfo[0].id)
    selectedBon?.type === "avoir" ? achatAvoir.current = "Bon D'Retour": achatAvoir.current = "Bon D'Achat"
    const articlesAdded = tableRefSuiv.current.getAllData().map((article)=>{
      return{
        code: article.code,
        designation: article.designation,
        quantity: article.quantity,
        PUAchat: article.PUAchat,
        remise: article.remise,
        total: article.total,
      }
    });
    const ROWS_PER_PAGE = 12; // Define the number of rows per page
    // Split data into pages based on ROWS_PER_PAGE
    let totalPages = Math.ceil(articlesAdded.length / ROWS_PER_PAGE);
    let pages = Array.from({ length: totalPages }, (_, pageIndex) =>
      articlesAdded.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE)
    );
    const data = {
      rowInfo: selectedBon,
      articlesAdded: pages,
      validerPar: rowInfo[0].saisiPar
    }
    setInfo(data)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      printRef.current.print();
    }, 100);
    
  }
  const PrintingColumns = [
    {name: "Code",width: "10%"},
    {name: "Designation",width: "40%"},
    {name: "Qte",width: "5%"},
    {name: "PU",width: "10%"},
    {name: "RM%",width: "5%"},
    {name: "Total",width:"10%"}
  ]

  return (
    <PageLayout icon={icons.achat} nameLayout={'Consultation des Achat'}>
        <div className={styles['body-container']}>
            <div className={styles['main-body']}>
              <div className={styles['row-between']}>
                <InputText 
                icon={icons.search}
                type={"text"}
                holderText={"Chercher Par N° Bon"}
                label={"Chercher"}
                labelPosition={true}
                width={"30%"}
                reportChange={(val) => handleSearch(val)}
                />  
                <div className={styles['row-end']} style={{minWidth: 'max-content'}}>
                  <Button 
                  icon={icons.impr}
                  width={"250px"}
                  name={'Imprimer'} 
                  onclick={handlePrint}
                    />
                <Button 
                  icon={icons.reglementClient}
                  link={true} 
                  path={"../reglement_fournisseur"} 
                  width={"max_content"}
                  name={'Réglement Fournisseur'} 
                    />
                </div>
              </div>
              <div style={{height: '300px'}}>
                <Table  tableTitle={"Bon de Livraison"} 
                        thead={metaData.thead_bonLivraison} 
                        tbody={data} 
                        sendSelectedRowInfo={handleClick}
                        handleDelete={handleDelete}
                        actOnValidation={actOnValidation}
                        singular={true}
                        ref={tableRef}
                        edit={user?.permissions.includes(permissions.canEditInTable)} 
                        remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                        filterInput={inputValue}
                        filterByArr={filterByArr}
                        />
              </div>

              <div className={styles['row-end']} style={{width:'100%',paddingTop:'2rem'}}>
                <SubInfo inline name={"Sold fournisseur :"} total={120000} currency={"DZD"} fontSize={"16px"} fontWeight={"600"}/>
              </div>

              <div style={{height: '300px'}}>
                <Table  
                  tableTitle={"Détail de Bon"} 
                  thead={metaData.thead_detailleBon} 
                  tbody={dataSuiv}
                  ref={tableRefSuiv}
                  handleDelete={handleDeleteArticle}
                  actOnValidation={actOnValidationArticle}
                  edit={user?.permissions.includes(permissions.canEditInTable)} 
                  remove={user?.permissions.includes(permissions.canDeleteFromTable)}
                />
              </div>
            </div>
    </div>
    {isActive && <Printing type={achatAvoir.current} thead={PrintingColumns} ref={printRef} dataInfo={info}/>}
    <ErrorContainer ref={errorsHandler}/>
    </PageLayout>
  )
}

export default ConsultationAchat
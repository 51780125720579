import React, { useEffect, useRef, useState } from 'react'
import PageLayout from '../../components/page-layout/PageLayout'
import { icons } from '../../media/mediaHolder'
import InputText from '../../components/input-text/InputText'
import Table from '../../components/table/Table'
import Button from '../../components/button/Button'
import styles from './etatVenteDetaille.module.scss'
import articleLivreFinder from '../../api/articleLivreFinder'
import articlesAvoirFinder from '../../api/articlesAvoirFinder'
import bonAchatsFinder from '../../api/bonAchatsFinder'
import metaData from './EtatVenteDetaille.json'
import { useAuth } from '../../contexts/AuthContext'
import permissions from '../../utils/permissions'
import { dateFormatter } from '../../utils/dateFormatter'

const EtatVenteDetaille = () => {
  const { state: authState } = useAuth();
  const { user } = authState;

  const [ inputValue , setInputValue ] = useState('');
  
  const [data,setData] = useState([]);
  const [date1, setDate1] = useState();
  const reportDate1Change = (val) => setDate1(val);

  const [date2, setDate2] = useState();
  const reportDate2Change = (val) => setDate2(val);

  const totalVente = useRef();
  const totalAchat = useRef();
  const totalBenifice = useRef();
    
  useEffect(() => {
    let montantAchat = .0;
    let montantVente = .0;
    let montantRetour = .0;

    const fetchArticlesAcheteAvoir = async () => {
      try {
        const articlesVenteRes = await articleLivreFinder.get('/');
        const articlesAvoirRes = await articlesAvoirFinder.get('/vente');
        const bonsAchatRes = await bonAchatsFinder.get('/');
        
        // GET THE TOTAL AMOUNT OF PURCHASES
        bonsAchatRes.data.data.bons.map(bon => {
          montantAchat += bon.amount;
        });
        // GET THE MAX ID OF VENTE RECEIPT
        const { max_id } = articlesVenteRes.data.data;
        // 

        const articlesLivre = articlesVenteRes.data.data.articles.map((article)=> {
        // ADD THE REMISE OF THE BON TO THE ARTICLE
        let montant = (article.prix * article.quantity) * (1 - (article.remise / 100));
        //
        montantVente += montant;
        // 

        return{
          id: article.id,
          lot:article.lotNbr,
          code: article.code,
          num_bon: article.bl_code,
          date: dateFormatter(article.bl_date,3),
          Vente_av: 'Vente',
          designation:article.designation,
          qtyVendue:article.quantity,
          pu_vente:article.prix,
          montant,
          pu_achat:article.pu_achat,
          benifice: montant - article.pu_achat ,
          codeClient: article.client_code,
          client: article.client_name
          }
        });

        const articlesAvoir = articlesAvoirRes.data.data.articles.map((element)=> {

        let montant = (element.prix_achat * element.quantity) * (1 - (element.remise / 100));
        montantRetour += montant;

        // ADD THE MAX ID FROM THE VENTE TABLE TO THE ARTICLE ID
        let id = parseInt(element.id) + parseInt(max_id);
        // 

        return{
          id,
          lot:element.lotNbr,
          code: element.code,
          codeBon:element.bon_avoir_code,
          date: dateFormatter(element.bon_avoir_date,3),
          Vente_av: 'Avoir',
          designation:element.designation,
          qtyVendue:element.quantity,
          pu_vente:element.prix_achat,
          montant,
          pu_achat:element.pu_achat,
          benifice:montant - element.pu_achat,
          codeClient: element.client_code,
          client: element.client_name
        }
        });
        
        totalVente.current?.setValue(montantVente.toFixed(3));
        totalAchat.current?.setValue(montantAchat.toFixed(3));
        totalBenifice.current?.setValue((parseFloat(montantVente - montantRetour) - parseFloat(montantAchat)).toFixed(3));
        setData([...articlesAvoir,...articlesLivre]);
      } catch (err) {
        console.log(err);
      }
    }
    fetchArticlesAcheteAvoir();
  },[]);

  return (
    <PageLayout icon={icons.reglementClient.secondary} nameLayout={'Etat des Ventes détaillé'}>
        <div className={styles['body-container']}>
          <div className={styles['main-body']}>
            <div className={styles['row-between']}>
              <InputText 
              icon={icons.search}
              type={"text"}
              holderText={"Chercher"}
              label={"Chercher"}
              labelPosition={true}
              width={"50%"}
              />  
              <div className={styles['row-end']} style={{width: 'max-content'}}>
                <Button 
                  icon={icons.impr}
                  width={"150px"}
                  name={'Imprimer'} 
                />
                <Button 
                  icon={icons.exporter}
                  width={"max-content"}
                  name={'Exporter Vers Excel'} 
                />
              </div>
            </div>
            <div className={styles['row']}>
              <InputText
                label={"Jour 1"}
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                reportChange={reportDate1Change}
                />
                <InputText label={"Jour 2"} 
                labelPosition={true}
                width={"max-content"}
                type={"date"}
                reportChange={reportDate2Change}
                />
            </div>
            <div style={{height:'300px'}}>
              <Table 
                thead={metaData.table} 
                inputValue={inputValue} 
                tbody={data} 
                date1={date1} 
                date2={date2}
              />
            </div>
            <div className={styles['row-between']} style={{width:'100%'}}>
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Total de Ventes'}
                ref={totalVente}
                disabled
              />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Total de Achat'}
                ref={totalAchat}
                disabled
              />
              <InputText  
                holderText={"Montant"} 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                type={"number"} 
                label={'Total des Bénéfice'}
                ref={totalBenifice}
                disabled
              />
            </div>
          </div>
        </div>
    </PageLayout>
    );
}
export default EtatVenteDetaille;
